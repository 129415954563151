import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import ApproveManager from "./pages/approve_manager";
import { PATH_ADMIN } from "../../constants/routes";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
});

const Admin = () => {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.container}>
        <Switch>
          <Route exact path={PATH_ADMIN.approveManager}>
            <ApproveManager />
          </Route>
          <Route exact path={PATH_ADMIN}>
            <ApproveManager />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Admin;