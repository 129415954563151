import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Geonames from 'geonames.js';

import {
  savePlayingExperience,
  getPlayingExperiences,
  removePlayingExperience,
  updateRecruitmentInfo,
  getRecruitmentInfo,
} from "../../../api/users";
import { getTeams } from "../../../api/teams";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import { COACHING_POSITIONS, LEVELS, YEARS } from  "../../../constants/constants";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import downArrow from "../../../assets/images/down_arrow.png";
import upArrow from "../../../assets/images/up_arrow.png";
import Menu from "../components/menu";

const geonames = new Geonames({
  username: 'alwaysaugust',
  lan: 'en',
  encoding: 'JSON'
});

const CustomSwitch = withStyles(components.commonCustomSwitch)(Switch);

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: "0px !important",
    marginTop: spacing.xSmall,
  },

  profileContainerWrapper:{
    display: "flex",
    flexDirection: "column",
    width: "70%",
    padding: "10px 20px",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      padding: "0px 10px",
    }
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 10px 30px 10px",
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  button: {
    margin: "0 auto",
    marginRight: spacing.xSmall,
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    color: colors.blueDark,
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: fonts.headingFontWeight,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
    cursor: "pointer",
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  form: {
    '& > *': {
      margin: theme.spacing(1),
    },

    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    },
  },

  inputsWrapper: {
    marginTop: "10px",
    width: "570px",
    display: "inline-block",
    overflow: "hidden",
    "& input:nth-child(1)": {
      marginRight: spacing.medium,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    "& textarea": {
      width: "90%",
      height: "70px",
      resize: "none",
      paddingLeft: spacing.xSmall,
      "&::placeholder":{
        paddingLeft: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    "& .MuiInput-underline:before":{
      content: "none",
    },
    "& .MuiInput-underline:after":{
      content: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%",
        marginBottom: spacing.small,
      },
    },
  },

  input: {
    ...components.commonInput,
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: "16px",
    },
  },

  autoCompleteInput: {
    backgroundColor: "white",
    width: "250px",
    height: "35px",
  },

  buttonsInput: components.commonButtonInput,

  select: {
    margin: 0,
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  buttonsInputWrapper: {
    display: "inline-block",
    position: "relative",
    "& input":{
      backgroundColor:"white",
    },
    "& div": {
      display: "block",
      position: "absolute",
      width: "20px",
      height: "10px",
      paddingLeft: "5px",
      textAlign: "center",
      color: "white",
      lineHeight: "10px",
      cursor: "pointer",
    },
    "& #weightUp, #heightUp":{
      right: "0px",
      top: "5px",
      background: `url(${upArrow})`,
      backgroundRepeat: "no-repeat",
    },
    "& #weightDown, #heightDown": {
      right: "0px",
      top: "20px",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
    }
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    width: "100%",
    marginTop: spacing.medium,
    marginBottom: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.xSmall,
    },
  },

  addButton: {
    padding: "10px 15px",
    width: "150px",
    borderRadius: "25px",
    textAlign: "center",
    fontSize: "14px",
    color: colors.blueDark,
    cursor: "pointer",
    backgroundColor: colors.greyDark,
    "& span": {
      color: colors.blue,
      fontSize: "24px",
      float: "left",
      lineHeight: "0.6",
    },
  },

  playingExperiencesSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.greyDark,
    paddingBottom: spacing.medium,
    "& select": {
      marginBottom: "5px",
    },
    "& label": {
      marginTop: "15px",
      paddingBottom: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  inputError: {
    border: "1px solid red",
  },

  teamsSection: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    backgroundColor: colors.greyLight,
    "& div":{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      backgroundColor: "white",
      borderRadius: "10px",
      margin: spacing.xSmall,
      border: `1px solid ${colors.greyDark}`,
      webkitBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    mozBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    boxShadow: `0 8px 6px -6px ${colors.grey}`,
      "& a":{
        textDecoration: "none",
        color: colors.blue,
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span":{
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span:nth-child(2)":{
        textAlign: "center",
      },
      "& span:last-child":{
        textAlign: "right",
      },
    },
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

  notesError: {
    width: "fit-content",
    backgroundColor: colors.errorMessage,
    color: "white",
    padding: "10px",
    marginBottom: spacing.small,
  },
  
}));

let countries = [];

const CoachInformation = () => {
  const classes = useStyles();
  const history = useHistory();

  const [locations, setLocations] = useState([]);
  const [position, setPosition] = useState('');
  const [level, setLevel] = useState('');
  const [onTeam, setOnTeam] = useState(false);
  const [lookingForTeam, setLookingForTeam] = useState(false);
  const [passportReady, setPassportReady] = useState(false);
  const [citizenship, setCitizenship] = useState('');
  const [salaryFrom, setSalaryFrom] = useState('');
  const [salaryTo, setSalaryTo] = useState('');
  const [salaryNegotiable, setSalaryNegotiable] = useState(false);
  const [notes, setNotes] = useState('');

  const [playingExperiencesSection, setPlayingExperiencesSection] = useState(false);

  const [team, setTeam] = useState('');
  const [fromYear, setFromYear] = useState('');
  const [toYear, setToYear] = useState('');
  const [playingPosition, setPlayingPosition] = useState('');
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();

  const [teamError, setTeamError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [playingPositionError, setPlayingPositionError] = useState(false);
  const [selectedTeamError, setSelectedTeamError] = useState(false);
  const [notesError, setNotesError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);

  const updateRecruitment = () => {

    if(notes.length > 200){
      setNotesError("There's a 200 character limit on this field")
      return;
    }

    let recruitmentData = {
      position: position,
      level: level,
      currentlyOnTeam: onTeam,
      lookingForTeam: lookingForTeam,
      citizenship: citizenship,
      passportReady: passportReady,
      expectedSalaryUpper: salaryFrom,
      expectedSalaryLower: salaryTo,
      salaryNegotiable: salaryNegotiable,
      notes: notes,
    };

    updateRecruitmentInfo(recruitmentData).then((response) => {
      if(response.data.status === "SUCCESS"){
        setSuccessMessage("Recruitment information updated");
      }
    }).catch(e => {
      console.log(e);
    });
  };

  const getAllTeams = () => {
    getTeams().then((response) => {
      setTeamList(response.data.teams);
    }).catch(e => {
      console.log(e);
    });
  }

  const saveTeam = () => {

    let hasError=false;
    
    if(!selectedTeam){
      setSelectedTeamError(true);
      hasError=true;
    }

    if(!fromYear || !toYear || (fromYear > toYear)){
      setYearError(true);
      hasError=true;
    }

    if(!playingPosition){
      setPlayingPositionError(true);
      hasError=true;
    }

    if(hasError) return;

    let playingExperience = {
      team: selectedTeam,
      from: fromYear,
      to: toYear,
      position: playingPosition,
      currentTeam: currentTeam,
    };

    savePlayingExperience(playingExperience).then((response) => {
      getPlayerTeams();
      closePlayingExperiences();
    }).catch(e => {
      console.log(e);
    });
  }

  const removeTeam = (team_id) => {
    removePlayingExperience(team_id).then((response) => {
      getPlayerTeams();
    }).catch(e => {
      console.log(e);
    });
  }

  const getPlayerTeams = () => {
    getPlayingExperiences().then((response) => {
      setTeams(response.data.playingExperiences);
    }).catch(e => {
      console.log(e);
    });
  }
  
  const toggleOnTeam = (event, newValue) => {
    setOnTeam(newValue);
  }

  const toggleCurrentTeam = (event, newValue) => {
    setCurrentTeam(newValue);
  }

  const toggleLookingForTeam = (event, newValue) => {
    setLookingForTeam(newValue);
  }

  const togglePassportReady = (event, newValue) => {
    setPassportReady(newValue);
  }

  const toggleSalaryNegotiable = (event, newValue) => {
    setSalaryNegotiable(newValue);
  }

  const getLocationInfo = async () => {
    if(countries.length === 0){
      countries = await geonames.countryInfo({});
    }
    return countries;
  };

  const openPlayingExperiences = () => {
    setPlayingExperiencesSection(true);
  }

  const closePlayingExperiences = () => {
    setPlayingExperiencesSection(false);
  }

  useEffect(() => {
    getRecruitmentInfo().then((response) => {
      setPosition(response.data.user.playing_position);
      setLevel(response.data.user.playing_level);
      setOnTeam(response.data.user.currently_on_team);
      setLookingForTeam(response.data.user.looking_for_team);
      setCitizenship(response.data.user.citizenship);
      setPassportReady(response.data.user.passport_ready);
      setNotes(response.data.user.notes);
      setSalaryFrom(response.data.user.salary_range_lower_limit);
      setSalaryTo(response.data.user.salary_range_upper_limit);
      setSalaryNegotiable(response.data.user.salary_is_negotiable);
    }).catch(e => {
      console.log(e);
    });

    getLocationInfo().then(countries => {
      setLocations(countries.geonames);
    });

    getPlayerTeams();
    getAllTeams();
  }, [history]);

  return (
    <>
      <div className={classes.content}>
        <Menu />
        <div className={classes.profileContainerWrapper}>
          <div className={classes.profileContainer}>
          {(successMessage)?<div className={classes.successMessage}>{successMessage}</div>:null}
            <form className={classes.form} noValidate autoComplete="off">
              <Typography className={classes.subTitle}>EXPERIENCE</Typography>
              <FormControl className={classes.label}>
                <span>What's your coaching position</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <select className={classes.input + " " + classes.select} onChange={e => setPosition(e.target.value)} value={position}>
                  <option value=""></option>;
                  {
                    COACHING_POSITIONS.map(position => {
                      return <option value={position}>{position}</option>;
                    })
                  }
                </select>
              </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <FormControl className={classes.label}>
                <span>What is your coaching level?</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <select className={classes.input + " " + classes.select} onChange={(e) => setLevel(e.target.value)} value={level}>
                  <option value=""></option>
                  {
                    LEVELS.map(level => {
                      return <option value={level}>{level}</option>;
                    })
                  }
                </select>
              </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <FormControl className={classes.label}>
                <span>Past coaching experiences</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <div className={classes.addButton} onClick={openPlayingExperiences}>
                  <span>+</span>
                  ADD
                </div>
              </div>
            </form>
            </div>
            {
              (teams.length > 0) ?
              <div className={classes.teamsSection}>
                {
                  teams.map(team => {
                  return <div><span>{team.team}</span><span>{team.from_year + '-' + team.to_year}</span><span>{team.position}</span><span className={classes.siteLink} onClick={() => removeTeam(team.id)}>Remove</span></div>;
                  })
                }
              </div>
              :null
            }

            {
              (playingExperiencesSection) ?
                <div className={classes.playingExperiencesSection}>
                  <form className={classes.form} noValidate autoComplete="off">
                    <FormControl>
                      <label>Team</label>
                      <Autocomplete
                        freeSolo
                        className={(!selectedTeamError) ? classes.autoCompleteInput : classes.autoCompleteInput + " " + classes.inputError}
                        onChange={(event, newValue) => {
                          if(newValue){
                            setSelectedTeam(newValue.id);
                          }
                        }}
                        options={teamList}
                        getOptionLabel={(option) => option.name}
                        value={selectedTeam}
                        renderInput={(params) => <TextField  {...params} />}
                      />
                      <label>Years</label>
                      <select type="text" className={(!yearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setFromYear(e.target.value)}>
                        <option value="">From</option>
                        {
                          YEARS.map(year => {
                            return <option value={year}>{year}</option>;
                          })
                        }
                      </select>
                      <select type="text" className={(!yearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setToYear(e.target.value)}>
                        <option value="">To</option>
                        {
                          YEARS.map(year => {
                            return <option value={year}>{year}</option>;
                          })
                        }
                      </select>
                      <label>Position</label>
                      <select className={(!playingPositionError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setPlayingPosition(e.target.value)}>
                        <option value=""></option>;
                        {
                          COACHING_POSITIONS.map(position => {
                            return <option value={position}>{position}</option>;
                          })
                        }
                      </select>
                      <label>Current team?</label>
                      <FormControlLabel
                      control={<CustomSwitch checked={currentTeam} value={currentTeam} onChange={toggleCurrentTeam} />}
                      label={(currentTeam ? "Yes" : "No")}
                      />
                    </FormControl>
                  </form>
                  <div className={classes.buttons}>
                    <span onClick={closePlayingExperiences} className={classes.cancelButton}>Cancel</span>
                    <span className={classes.button} onClick={saveTeam}>Add Team</span>
                  </div>
                </div>
                :null
            }
            <div className={classes.profileContainer}>
              <div className={classes.dashedLine}></div>
              <form className={classes.form} noValidate autoComplete="off">
                <Typography className={classes.subTitle}>STATUS</Typography>

                <FormControl className={classes.label}>
                  <span>Are you currently on a team?</span>
                </FormControl>

                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={onTeam} value={onTeam} onChange={toggleOnTeam} />}
                  label={(onTeam ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Are you looking for a team?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={lookingForTeam} value={lookingForTeam} onChange={toggleLookingForTeam} />}
                  label={(lookingForTeam ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>You are a citizen of which country?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <select className={classes.input + " " + classes.select} onChange={e => setCitizenship(e.target.value)} value={citizenship}>
                    <option value=""></option>
                    {
                      locations.map(location => {
                        return <option id={location.geonameId} value={location.countryName}>{location.countryName}</option>
                      })
                    }
                    
                  </select>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Are you passport ready?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={passportReady} value={passportReady} onChange={togglePassportReady} />}
                  label={(passportReady ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>What is your expected salary?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <input type="number"
                  className={classes.input}
                  placeholder="From (USD)"
                  value={salaryFrom}
                  maxLength="10"
                  onChange={e => {
                    let { value, maxLength } = e.target;
                    let prev = value.slice(0,-1);
                    value = (value.length > Number(maxLength)) ? prev : value;
                    setSalaryFrom(value)
                  }}
                />
                </div>
                <div className={classes.inputsWrapper}>
                <input type="number"
                  className={classes.input}
                  placeholder="To (USD)"
                  value={salaryTo}
                  maxLength="10"
                  onChange={e => {
                    let { value, maxLength } = e.target;
                    let prev = value.slice(0,-1);
                    value = (value.length > Number(maxLength)) ? prev : value;
                    setSalaryTo(value)
                  }}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Is salary negotiable?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={salaryNegotiable} value={salaryNegotiable} onChange={toggleSalaryNegotiable} />}
                  label={(salaryNegotiable ? "Yes" : "No")}
                />
                </div>
              </form>
              <div className={classes.dashedLine}></div>
              <form className={classes.form} noValidate autoComplete="off">
                <Typography className={classes.subTitle}>OTHER</Typography>
                <FormControl className={classes.label}>
                  <span>Is there anything else?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                  {(notesError != '')?<div className={classes.notesError}>{notesError}</div>:null}
                  <textarea rows="4" placeholder="Is there anything else?..." className={classes.input} onChange={e => setNotes(e.target.value)} value={notes}>
                  </textarea>
                </div>
              </form>
              <div className={classes.dashedLine}></div>
              <div className={classes.buttons}>
                <a href="/" className={classes.cancelButton}>Cancel</a>
                <div className={classes.button} onClick={updateRecruitment}>
                  <span>SAVE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

export default CoachInformation;