import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Geonames from 'geonames.js';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import 'material-icons/iconfont/material-icons.scss';

import { getUserInfo, updateProfile } from "../../../api/users";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import downArrow from "../../../assets/images/down_arrow.png";
import twitter_logo from "../../../assets/images/twitter_logo.png";
import facebook_logo from "../../../assets/images/facebook_logo.png";
import instagram_logo from "../../../assets/images/instagram_logo.svg";
import linkedin_logo from "../../../assets/images/linkedin_logo.png";
import { toBase64, checkExtension } from "../../../utils/helpers";
import { LANGUAGES, INPUT_MAX_LENGTH } from  "../../../constants/constants";
import Menu from "../components/menu";

const geonames = new Geonames({
  username: 'alwaysaugust',
  lan: 'en',
  encoding: 'JSON'
});

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    padding: "10px 20px 30px 20px",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "95%",
      padding: "10px 10px 20px 10px",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: "0px !important",
    marginTop: spacing.xSmall,
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  button: {
    margin: "0 auto",
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    color: colors.blueDark,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
      width: "auto",
    },
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  form: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    },
  },

  socialForm: {
    marginBottom: spacing.small,
    width: "100%",
    margin: "24px auto 0px auto",
    "& div":{
      display: "inline-flex !important",
      flexDirection: "column !important",
    },
    "& > *": {
      margin: 0,
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      "& input": {
        width: "300px",
      },
    },
  },

  inputsWrapper: {
    display: "inline-block",
    overflow: "hidden",
    "& input:nth-child(1)": {
      marginRight: spacing.medium,
      paddingBottom: 0,
      paddingTop: 0,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        paddingBottom: "0px",
      },
    },
    "& .MuiInput-underline:before":{
      content: "none",
    },
    "& .MuiInput-underline:after":{
      content: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%",
        marginBottom: spacing.small,
      },
    },
  },

  input: {
    backgroundColor: "white",
    height: "35px",
    width: "250px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "auto",
    },
  },

  select: {
    margin: 0,
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% center",
    paddingLeft: spacing.xSmall,
    height: "37px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  dateInput: {
    "& > div:nth-child(1)": {
      boxShadow: "inset 0 0 2px 0px #888",
      paddingLeft: "0px !important",
      "& > .MuiInput-formControl": {
        height: "100%",
      },
    },
    "& input": {
      padding: 0,
      paddingLeft: spacing.xSmall,
      fontSize: "13px",
      marginRight: "0px !important",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% center",
      height: "37px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    width: "100%",
    marginTop: spacing.medium,
    marginBottom: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.xSmall,
    },
  },

  uploadPic: {
    display: "block",
    fontSize: "12px",
    border: "1px dashed",
    borderColor: colors.orange,
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    "& span": {
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "14px",
    }
  },

  fileInput: {
    width: "0.1px",
	  height: "0.1px",
	  opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: "1",
  },

  inputError: {
    border: "1px solid red",
  },

  socialLogo: {
    color: colors.blueDeep,
    fontSize: "14px",
    lineHeight: 4,
    "& img":{
      width: "30px",
      marginTop: spacing.xSmall,
      marginRight: spacing.xSmall,
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        marginRight: spacing.small,
      },
    },
    "& span":{
      fontSize: "30px",
      marginTop: spacing.xSmall,
      marginRight: spacing.xSmall,
      [theme.breakpoints.down("xs")]: {
        marginRight: spacing.small,

      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px !important",
      lineHeight: 1,
      marginTop: "0px",
    },
  },

  successMessage: {
    width: "50%",
    backgroundColor: colors.successMessage,
    color: "white",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  errorMessage: {
    width: "50%",
    backgroundColor: colors.errorMessage,
    color: "white",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  imageErrorMessage: {
    color: colors.errorMessage,
    marginBottom: 0,
  },

  uploadWrapper: {
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

}));

let countries = [];

const BasicInformation = () => {
  const classes = useStyles();

  const [countriesList, setCountriesList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [gender, setGender] = useState('');
  const [language, setLanguage] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [picName, setPicName] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [website, setWebsite] = useState('');
  const [loadingRegions, setLoadingRegions] = useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleCountryChange = (event) => {
    setLoadingRegions(true);
    setCountry(event.target.value);
    getRegions(event.target[event.target.selectedIndex].id).then(regions => {
      setRegionsList(regions.geonames);
      setLoadingRegions(false);
    }).catch(e=>{
      setRegionsList([]);
      setLoadingRegions(false);
    });
  }

  const getRegions = async (id) => {
    return await geonames.children({geonameId: id});
  }

  const getCountries = async () => {
    if(countries.length === 0){
      countries = await geonames.countryInfo({});
    }
    return countries;
  };

  const handleUploadPic = async (event) => {
    let file = event.target.files[0];
    let pic =  await toBase64(file);

    if(!checkExtension(file.name)){
      setImageError("Only jpg and png allowed");
      return;
    }

    if(file.size > 500000){
      setImageError("Image too big");
      return;
    }

    setProfilePic(pic);
    setPicName(file.name);
  };

  const updateUser = () => {

    let hasError=false;

    if(!firstName || firstName.length > INPUT_MAX_LENGTH){
      setFirstNameError(true);
      hasError=true;
    } else { setFirstNameError(false); }

    if(!lastName || lastName.length > INPUT_MAX_LENGTH){
      setLastNameError(true);
      hasError=true;
    } else { setLastNameError(false); }

    if(!birthdate){
      setBirthdateError(true);
      hasError=true;
    } else { setBirthdateError(false); }

    if(!gender){
      setGenderError(true);
      hasError=true;
    } else { setGenderError(false); }

    if(!language){
      setLanguageError(true);
      hasError=true;
    } else { setLanguageError(false); }

    if(!country){
      setCountryError(true);
      hasError=true;
    } else { setCountryError(false); }
    
    if(hasError) return;
    
    let data = {
      firstName: firstName,
      lastName: lastName,
      birthdate: moment(birthdate).format("YYYY-MM-DD"),
      gender: gender,
      language: language,
      country: country,
      region: region,
      profilePicture: profilePic,
      instagram: instagram,
      twitter: twitter,
      facebook: facebook,
      linkedin: linkedin,
      website: website,
    };

    updateProfile(data).then((response) => {
      window.scrollTo(0,0);
      if(response.data.status === "SUCCESS"){
        setSuccessMessage("Profile updated!");
      }else{
        setErrorMessage(response.data.error);
      }
    }).catch(e => {
      console.log(e);
    });
  };

  useEffect(() => {
    setTimeout(() => setSuccessMessage(false), 3000);
  },[successMessage]);

  useEffect(() => {
    getUserInfo().then((response) => {
      getCountries().then(countries => {
        setCountriesList(countries.geonames);
      });
      
      setFirstName(response.data.user.first_name);
      setLastName(response.data.user.last_name);
      setGender(response.data.user.gender);
      setLanguage(response.data.user.language);
      setCountry(response.data.user.country);
      setRegion(response.data.user.region);
      setTwitter(response.data.user.twitter);
      setFacebook(response.data.user.facebook);
      setInstagram(response.data.user.instagram);
      setLinkedin(response.data.user.linkedin);
      setWebsite(response.data.user.website);

      //date formatting
      if(response.data.user.birthdate){
        let birthDate = new Date(response.data.user.birthdate);
        setBirthdate(birthDate.toISOString().split('T')[0]);
      }
    });
  }, []);

  return (
    <>
      <div className={classes.content}>
        <Menu />
        <div className={classes.profileContainer}>
        {(successMessage)?<div className={classes.successMessage}>{successMessage}</div>:null}
        {(errorMessage)?<div className={classes.errorMessage}>{errorMessage}</div>:null}
          <form className={classes.form} noValidate autoComplete="off">
            <Typography className={classes.subTitle}>BASIC INFORMATION</Typography>
            <FormControl className={classes.label}>
              <span>Name</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="First" value={firstName} className={(!firstNameError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setFirstName(e.target.value)} />
              </FormControl>
              <FormControl>
                <input type="text" placeholder="Last" value={lastName} className={(!lastNameError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setLastName(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your birthdate?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl className={classes.dateInput}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker disableFuture="true" invalidLabel="" invalidDateMessage="" format="yyyy-MM-DD" openTo="year" placeholder="1988-03-12" value={birthdate} className={(!birthdateError)?classes.input:classes.input + " " + classes.inputError} onChange={date => setBirthdate(date)} />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your gender?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <select className={(!genderError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} value={gender} onChange={e => setGender(e.target.value)}>
                  <option value=""></option>
                  <option value="m">Male</option>
                  <option value="f">Female</option>
                </select>
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What language do you speak?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                  <select className={(!languageError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} value={language} onChange={e => setLanguage(e.target.value)}>
                    <option value=""></option>
                    {
                      LANGUAGES.map(language => {
                        return <option value={language.value}>{language.label}</option>
                      })
                    }
                  </select>
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your location?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl style={{marginRight:spacing.large}}>
                <select className={(!countryError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} value={country} onChange={e => handleCountryChange(e)}>
                  {
                    (country) ?
                      <option value={country}>{country}</option>
                      :<option value=""></option>
                  }
                  {
                    (countriesList) ?
                      countriesList.map(country => {
                        return <option id={country.geonameId} value={country.countryName}>{country.countryName}</option>
                      })
                    :null
                  }
                </select>
              </FormControl>
              <FormControl>
                {
                  (!loadingRegions) ?
                    <select className={classes.input + " " + classes.select} value={region} onChange={e => setRegion(e.target.value)}>
                      {
                        (region) ?
                          <option value={region}>{region}</option>
                          :<option value=""></option>
                      }
                      {
                        regionsList.map(region => {
                          return <option value={region.name}>{region.name}</option>
                        })
                      }
                    </select>
                    :<CircularProgress />
                }
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Upload profile pic (max file size 500KB. Ideal ratio 190x195)</span>
            </FormControl>
            {(imageError)?<div className={classes.imageErrorMessage}>{imageError}</div>:null}
            <div className={classes.inputsWrapper + ' ' + classes.uploadWrapper}>
              <label className={classes.uploadPic}>
                <input type="file" className={classes.fileInput} onChange={e => handleUploadPic(e)}/>
                <span>Upload profile pic</span>
                <br/>
                {picName}
              </label>
            </div>
          </form>
          <div className={classes.dashedLine}></div>
          <Typography className={classes.subTitle}>SOCIAL MEDIA</Typography>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
              <img src={twitter_logo} alt="twitter logo" />
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://twitter.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} value={twitter} onChange={e => setTwitter(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
              <img src={instagram_logo} alt="instagram logo"/>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://instagram.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} value={instagram} onChange={e => setInstagram(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
          <FormControl className={classes.socialLogo}>
            <img src={facebook_logo} alt="facebook logo"/>
          </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://facebook.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} value={facebook} onChange={e => setFacebook(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
              <img src={linkedin_logo} alt="linkedin logo"/>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://linkedin.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} value={linkedin} onChange={e => setLinkedin(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
              <span className="material-icons">language</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="Website" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} value={website} onChange={e => setWebsite(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <div className={classes.dashedLine}></div>
          <div className={classes.buttons}>
            <a href="/" className={classes.cancelButton}>Cancel</a>
            <div className={classes.button} onClick={updateUser}>
              <span>SAVE & CONTINUE</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicInformation;