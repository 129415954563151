import React, {useEffect}  from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga';
import history from "../history";

import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

import { HOME_ROUTES } from "../../constants/routes";
import Home from "./pages/home";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import { colors } from  "../../constants/theme";

ReactGA.initialize(process.env.REACT_APP_GA);

const Dashboard = () => {

  useEffect(() =>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <>
      <Router history={history}>
        <>
          <Switch>
            <Route path={HOME_ROUTES.login}>
              <Login />
            </Route>
            <Route path={HOME_ROUTES.forgotPassword}>
              <ForgotPassword />
            </Route>
            <Route path={HOME_ROUTES.resetPassword}>
              <ResetPassword />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </>
      </Router>
      
      <Feedback
        projectId={process.env.REACT_APP_FEEDER}
        email={true}
        primaryColor={colors.yellow}
        textColor={colors.blueDark}
        hoverBorderColor={colors.yellow}
      />
    </>
  );
}

export default Dashboard;