import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";

import { activateUserAccount } from "../../../api/authentication";
import { spacing, colors } from  "../../../constants/theme";
import { PROFILE_ROUTES, } from  "../../../constants/routes";

const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    marginTop: spacing.large,
    justifyContent: "center",
    textAlign: "center",
    "& > div": {
      backgroundColor: colors.greyLight,
      padding: "20px",
      borderRadius: "10px",
      height: "fit-content",
      "& > h2":{
        marginTop: 0,
      },
      "& > a": {
        textDecoration: "none",
        color: colors.blue,
        fontWeight: "bold",
      },
    }
  },
}));

const ActivateAccount = () => {
  const classes = useStyles();

  const location = useLocation();

  let param = queryString.parse(location.search);

  const [activated, setActivated] = useState(false);

  useEffect(() => {
    
    activateUserAccount(param.token).then((response) => {
      if(response.data.status === "SUCCESS"){
        setActivated(true);
      }
    }).catch(e => {
      console.log(e);
    });
  }, []);

  return (
    <>
      <div className={classes.content}>
        {
          (activated) ?
            <div>
              <h2>Your account has been activated</h2>
              {
                (localStorage.getItem("loggedIn")) ?
                <a href={PROFILE_ROUTES.basicInformation}>Go to profile</a>
                :
                <a href="/login">Login</a>

              }
              
            </div>
          : null
        }
      </div>
    </>
  );
}

export default ActivateAccount;