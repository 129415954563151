import React, {useEffect} from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga';
import history from "../history";

import { makeStyles } from "@material-ui/core/styles";
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

import SearchPlayers from "./pages/search_players";
import SearchCoaches from "./pages/search_coaches";
import SearchTeams from "./pages/search_teams";
import Games from "./pages/games";
import { PATH_DASHBOARD, DASHBOARD_ROUTES } from  "../../constants/routes";
import { spacing, colors } from  "../../constants/theme";
import { Header } from  "../../components/header";

ReactGA.initialize(process.env.REACT_APP_GA);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
    maxWidth: "1024px",
    margin: "0 auto",
    marginTop: spacing.xxLarge,
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      flex: "1 0 auto",
      justifyContent: "center",
      margin: "20px auto",
      height: "auto",
    }
  },
}));

const Dashboard = () => {
  
  const classes = useStyles();

  useEffect(() =>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  
  return (
    <>
      <Header />
      <Router history={history}>
        <div className={classes.container}>
          <Switch>
            <Route exact path={DASHBOARD_ROUTES.searchPlayers}>
              <SearchPlayers />
            </Route>
            <Route exact path={DASHBOARD_ROUTES.searchCoaches}>
              <SearchCoaches />
            </Route>
            <Route exact path={DASHBOARD_ROUTES.searchTeams}>
              <SearchTeams />
            </Route>
            <Route exact path={DASHBOARD_ROUTES.games}>
              <Games />
            </Route>
            <Route exact path={PATH_DASHBOARD}>
              <SearchPlayers />
            </Route>
          </Switch>
        </div>
      </Router>
      <Feedback
        projectId={process.env.REACT_APP_FEEDER}
        email={true}
        primaryColor={colors.yellow}
        textColor={colors.blueDark}
        hoverBorderColor={colors.yellow}
      />
    </>
  );
}

export default Dashboard;