import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';
import { spacing, colors } from "../../../constants/theme";

JavascriptTimeAgo.addLocale(en);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      display: "flex",
      flex: "0 48%",
      marginRight: "15px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        flex: "0 100%",
        marginBottom: "15px",
        width: "100%",
      }
    },
  },

  featuredImage: {
    display: "flex",
    width: "200px",
    height: "120px",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "40%",
    },
  },

  postData: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "15px",
    fontSize: "12px",
    color: colors.blueDeep,
    paddingLeft: spacing.small,
    borderBottom: `1px dashed ${colors.orange}`,
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
    "& div:nth-child(1)":{
      marginBottom: spacing.large,
      [theme.breakpoints.down("xs")]: {
        marginBottom: spacing.small,
      },
    },
    "& div:nth-child(2)":{
      fontWeight: "bold",
      fontSize: "11px",
    },
    "& div:nth-child(3)":{
      fontSize: "10px",
      fontWeight: "bold",
      color: colors.grey,
    }
  },

  noData: {
    fontSize: "18px",
    color: colors.grey,
    marginBottom: spacing.medium,
  },

  newsLink: {
    color: colors.blueGrey,
    margin: `${spacing.large}px 0px ${spacing.large}px 0px`,
  },

  loader: {
    flex: "none !important",
  },
}));

export const Posts = ({search}) => {
  const classes = useStyles();

  const [posts, setPosts] = useState([]);

  const [loading, setLoading] = useState(true);

  //wrap search in quotes to get exact match
  const [postSearch, setPostSearch] = useState('"'+search+'"');

  const getPosts = async () => {
    
    let url = `${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/posts?_fields=author,id,date,type,title,link,featured_media&per_page=8&search=${postSearch}`;

    let response = await fetch(url);

    if (response.ok) {

      let wpPosts = await response.json();

      for(let i=0; i<wpPosts.length; i++){
        wpPosts[i].image_url = await getFeaturedImage(wpPosts[i].featured_media);
      }

      for(let i=0; i<wpPosts.length; i++){
        wpPosts[i].author_name = await getAuthorName(wpPosts[i].author);
      }

      setLoading(false);
      return wpPosts;

    } else {
      console.log("HTTP-Error: " + response.status);
    }

  };

  const getFeaturedImage = async (image_id) => {
    let url = `${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/media/${image_id}`;
    let response = await fetch(url);

    if (response.ok) {
      let image = await response.json();
      return image.media_details.sizes.medium.source_url;
    } else {
      return 'profile.jpeg';
    }
  };

  const getAuthorName = async (author_id) => {
    let url = `${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/users/${author_id}`;
    let response = await fetch(url);

    if (response.ok) {
      let author = await response.json();
      return author.name;
    }
  };

  const goToPost = (link) => {
    window.location.href = link;
  };

  useEffect(() => {

    async function fetchData() {
      let wpPosts = await getPosts();
      setPosts(wpPosts);
    }
    fetchData();

  }, []);

  return (
    <div className={classes.container}>
      
      {
        (posts.length >= 1) ?
        <>
        {
          posts.map((post,index) => {
            let elipsis='';
            if(post.title.rendered.length > 65 )elipsis='...';
            return (
              <div key={index} onClick={e => goToPost(post.link)}>
                <div className={classes.featuredImage}>
                  <img src={post.image_url} alt={post.title.rendered}/>
                </div>
                <div className={classes.postData}>
                  <div dangerouslySetInnerHTML={{__html: post.title.rendered.slice(0,65)+elipsis}}></div>  
                  <div>{post.author_name}</div>
                  <div>{post.type} | <ReactTimeAgo date={new Date(post.date)}/></div>
                </div>
              </div>
            )
          })
        }
        <a href={`${process.env.REACT_APP_BLOG_URL}/?s=${postSearch}`} className={classes.newsLink}>Go to news</a>
        </>
        :(posts.length < 1 && !loading) ? <div className={classes.noData}>No news articles</div>:<CircularProgress className={classes.loader} />
      }
    </div>
  );
};