import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import 'material-icons/iconfont/material-icons.scss';

import { getUserInfoBySlug } from "../../../api/users";
import { Posts } from "../components/posts";
import { spacing, colors, components, fonts } from "../../../constants/theme";
import { LANGUAGES } from "../../../constants/constants";
import { calculateAge } from "../../../utils/helpers";
import { Videos } from "../components/videos";

import summaryIcon from "../../../assets/images/summary.svg";
import mediaIcon from "../../../assets/images/media.svg";
import newsIcon from "../../../assets/images/news.svg";
import profileBannerBackground from "../../../assets/images/profile_banner_background.png";
import defaultPlayerLogo from "../../../assets/images/player.png";
import downArrow from "../../../assets/images/down_arrow.png";
import upArrow from "../../../assets/images/up_arrow.png";
import facebook_logo from "../../../assets/images/facebook_small.svg";
import twitter_logo from "../../../assets/images/twitter_small.svg";
import linkedin_logo from "../../../assets/images/linkedin_small.svg";
import instagram_logo from "../../../assets/images/instagram_small.svg";

const queryString = require("query-string");


const useStyles = makeStyles((theme) => ({

  mainWrapper:{
    display: "block",
    width: "100%",
    position: "absolute",
    left: 0,
    marginBottom: "20px",
  },

  container: {
    display: "block",
    width: "80%",
    margin: "0 auto",
    '& svg': {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  topBanner: {
    width: "100%",
    height: "275px",
    display: "flex",
    alignItems: "center",
    backgroundImage: `url(${profileBannerBackground})`,
    backgroundSize: "cover",
    "& > div":{
      display: "flex",
      width: "50%",
      maxWidth: "1024px",
      height: "70%",
      margin: "0 auto",
      borderRadius: "10px",
      backgroundColor: "white",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        height: "75%",
      },
      "& > div:nth-child(1)":{
        display: "flex",
        justifyContent: "center",
        width: "29%",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        overflow: "hidden",
        [theme.breakpoints.up("xl")]: {
          width: "20%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "135px",
          height: "90px",
          borderRadius: "50%",
          margin: "10px",
        },
        "& > img": {
          maxWidth: "100%",
          maxHeight: "100%",
        },
      },
      "& > div:nth-child(2)":{
        width: "55%",
        paddingLeft: spacing.medium,
        paddingTop: spacing.medium,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingLeft: 0,
          paddingTop: spacing.small,
        },
        "& > span":{
          display: "block",
          padding: "2px",
        },
        "& > span:nth-child(1)":{
          fontSize: "14px",
          fontWeight: fonts.subHeadingFontWeight,
        },
        "& > span:nth-child(2)":{
          color: colors.blueDeep,
          fontSize: "18px",
          textTransform: "uppercase",
          fontWeight: fonts.headingFontWeight,
          height: "30px",
          width: "215px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& > span:nth-child(3)":{
          color: colors.blue,
          fontSize: "14px",
          fontWeight: fonts.headingFontWeight,
          marginBottom: spacing.xxLarge,
          [theme.breakpoints.down("sm")]: {
            marginBottom: spacing.xlarge,
          },
        },
        "& > div": {
          "& > span": {
          display: "block",
          color:colors.blueGrey,
          fontSize: "12px",
          fontWeight: fonts.headingFontWeight,
          },
          [theme.breakpoints.down("sm")]: {
            marginLeft: "-90px",
            "& > span": {
              fontSize: "13px",
              fontWeight: "bold",
              color: colors.blueGrey,
              lineHeight: 1.5,
            },
          },
        },
      },
      "& > div:nth-child(3)":{
        width: "25%",
        borderLeft: `1px solid ${colors.greyLight}`
      },
    },
  },

  menu: {
    width: "fit-content",
    backgroundColor: colors.greyLight,
    borderRadius: "20px",
    margin: "0 auto",
    textAlign: "center",
    display: "flex",
    fontSize: "12px",
    fontWeight: "bold",
    marginTop: spacing.xxLarge,
    marginBottom: spacing.xxLarge,
    "& > span":{
      padding: "10px 25px 10px 25px",
      color: colors.blue,
      "&:hover": {
        cursor: "pointer"
      },
    },
  },

  menuSelect: {
    width: "100%",
    margin: "15px auto",
    "& > select": {
      backgroundColor: "white",
      height: "35px",
      borderRadius: "2px",
      boxShadow: "inset 0 0 1px 0px #888",
      color: colors.grey,
      "&::placeholder":{
        color: colors.grey,
        paddingLeft: spacing.xSmall,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: "16px",
      },
      paddingLeft: spacing.xSmall,
      margin: "0",
      "-webkitBoxSizing": "border-box",
      "-moz-box-sizing": "border-box",
      "box-sizing": "border-box",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      backgroundColor: "white",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "98% 50%",
      paddingLeft: spacing.xSmall,
      color: colors.grey,
    },
  },

  title: {
    ...components.commonTitle,
    marginBottom: spacing.xLarge,
    display: "flex",
    "& > img":{
      marginRight: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "30px",
        height: "30px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    paddingBottom: "10px",
    "& > img": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "initial",
      },
    },
  },

  playerInfo: {
    display: "flex",
    marginBottom: spacing.xxLarge,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& > div:nth-child(1)":{
      width: "25%",
      borderRight: `1px dashed ${colors.orange}`,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        borderRight: "none",
      },
      "& > div > div": {
          display: "flex",
          marginBottom: spacing.medium,
          marginTop: spacing.small,
          fontSize: "12px",
        "& > span":{
          display: "flex",
          alignItems: "center",
          "& img":{
            marginRight: "8px",
          },
        },
        "& > span:nth-child(1)":{
          color: colors.blueGrey,
          fontWeight: fonts.headingFontWeight,
          width: "100px",
        },
        "& > span:nth-child(2)":{
          float:"right",
          marginLeft: "30px",
          [theme.breakpoints.down("xs")]: {
            width: "50%",
          },
        },
      },
    },
    "& > div:nth-child(2)":{
      width: "75%",
      marginLeft: spacing.small,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginLeft: 0,
      },
      "& > div:nth-child(1)":{
        display: "flex",
        "& > div":{
          width: "50%",
        },
      },
    },
},

awardsAndExperience: {
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  marginBottom: spacing.large,
  "& > div": {
    display: "flex",
    flex: "0 35%",
    flexDirection: "column",
    fontSize: "12px",
    marginBottom: spacing.medium,
    "& > span":{
      display: "block",
    },
    "& > span:nth-child(1)":{
      fontSize: "11px",
    },
    "& > span:nth-child(3)":{
      color: colors.grey,
      fontSize: "11px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
},

recruitment: {
  width: "100%",
  backgroundColor: colors.greyLight,
  borderRadius: "15px",
  padding: "15px",
  fontSize: "12px",
},

dashedLine: {
  position: "absolute",
  width: "80%",
  borderTop: "1px dashed",
  borderColor: colors.orange,
  marginTop: spacing.medium,
  marginBottom: spacing.medium,
  right: "38px",
  [theme.breakpoints.down("sm")]: {
    marginTop: spacing.xSmall,
  },
},

noData: {
  fontSize: "18px",
  color: colors.grey,
  marginBottom: spacing.medium,
},

}));

const UserProfile = (props) => {
  const classes = useStyles();

  let params = queryString.parse(window.location.search);

  const [profilePic, setProfilePic] = useState('profilepic.jpeg'); 
  const [position, setPosition] = useState('');
  const [name, setName] = useState('');
  const [team, setTeam] = useState('');
  const [division, setDivision] = useState('');
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [language, setLanguage] = useState('');
  const [passport, setPassport] = useState();
  const [awards, setAwards] = useState([]);
  const [previousTeams, setPreviousTeams] = useState([]);
  const [notes, setNotes] = useState('');
  const [type, setType] = useState();
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [linkedin, setLinkedin] = useState();
  const [instagram, setInstagram] = useState();
  const [website, setWebsite] = useState();

  const [reelsCount, setReelsCount] = useState(0);
  const [highlightsCount, setHighlightsCount] = useState(0);

  const [playerDetailsToggle, setPlayerDetailsToggle] = useState(true);
  const [awardsToggle, setAwardsToggle] = useState(true);
  const [previousTeamsToggle, setPreviousTeamsToggle] = useState(true);

  const summaryRef = useRef();
  const newsRef = useRef();
  const mediaRef = useRef();
  const SECTIONS = [{name:"SUMMARY", link:"#summary", ref:summaryRef},{name:"MEDIA", link:"#media", ref:mediaRef},{name:"NEWS", link:"#news", ref:newsRef}]

  const handleAnchorClick = (anchor) =>{
    anchor.current.scrollIntoView({ behavior: 'smooth' });
  }

  const getUserInfo = async (slug) => {
    getUserInfoBySlug(slug).then(response => {
      setProfilePic(response.data.response.user.profile_picture);
      setPosition(response.data.response.user.playing_position);
      setName(`${response.data.response.user.first_name} ${response.data.response.user.last_name}`);
      setTeam(response.data.response.user.team);
      setDivision(response.data.response.user.division_name);
      setAge(calculateAge(response.data.response.user.birthdate));
      setHeight(response.data.response.user.height);
      setWeight(response.data.response.user.weight);
      setCitizenship(response.data.response.user.citizenship);
      let lan = LANGUAGES.find(o => o.value === response.data.response.user.language);
      if(lan)setLanguage(lan.label);
      setPassport(response.data.response.user.passport_ready);
      setAwards(response.data.response.awards);
      setPreviousTeams(response.data.response.playing_experience);
      setNotes(response.data.response.user.notes);
      setType(response.data.response.user.type);
      setFacebook(response.data.response.user.facebook);
      setInstagram(response.data.response.user.instagram);
      setTwitter(response.data.response.user.twitter);
      setLinkedin(response.data.response.user.linkedin);
      setWebsite(response.data.response.user.website);
    }).catch(e => {
      window.location.href="/dashboard";
      console.log(e);
    });
  };

  useEffect(() => {
    getUserInfo(params.user);
  }, [params.user]);

  const isMobile = isWidthDown('sm', props.width);
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.topBanner}>
        <div>
          <div>
            <img src={(profilePic)?`${process.env.REACT_APP_API_ENDPOINT}/${profilePic}`:defaultPlayerLogo} alt="user" />
          </div>
          <div>
            <span>{position}</span>
            <span>{name}</span>
            <span>{team ?? "Free Agent"} {division ? "| "+division:null}</span>
            <div>
            <span>{citizenship}</span>
            {
              (type === 1 || type === 2) ?
                <span>{(age)?age+ 'years old |':null} {(height)?height+"|":null} {weight}</span>
              : null
            }
            {isMobile?
            <div className={classes.dashedLine}></div>
            :null}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.container}>
      {isMobile?
          <div className={classes.menuSelect}>
            <select className={classes.menuSelect} onChange={e => {
              let section = SECTIONS.find(o => o.link === e.target.value);
              handleAnchorClick(section.ref);
              return false;
            }}>
              <option value="">Go To</option>
              {
                SECTIONS.map(section => {
                  return <option key={section.link} value={section.link}>{section.name}</option>
                })
              }
            </select>
          </div>
          :
          <div className={classes.menu}>
            {
              SECTIONS.map(section => {
                return <span key={section.name} onClick={()=>handleAnchorClick(section.ref)}>{section.name}</span>
              })
            }
          </div>
        }
        <Typography ref={summaryRef} className={classes.title} name="summary" id="summary"><img src={summaryIcon} alt="summary icon"/>SUMMARY</Typography>
        <div className={classes.playerInfo}>
          <div>
            <Typography className={classes.subTitle}>PLAYER DETAILS <img src={(playerDetailsToggle)?upArrow:downArrow} onClick={e => setPlayerDetailsToggle(!playerDetailsToggle)} /></Typography>
            {
            (!isMobile || (isMobile && playerDetailsToggle)) ?
            <div>
              <div><span>Position:</span><span>{position}</span></div>
              <div><span>Citizenship:</span><span>{citizenship}</span></div>
              <div><span>Language:</span><span>{language}</span></div>
              {
                (type === 1 || type === 2) ?
                <>
                  <div><span>Age:</span><span>{age}</span></div>
                  <div><span>Height:</span><span>{height}</span></div>
                  <div><span>Weight:</span><span>{weight}</span></div>
                </>: null
              }
              <div><span>Current Team:</span><span>{team ?? "Free Agent"}</span></div>
              <div><span>Passport:</span><span>{(passport === 1)?"Yes":"No"}</span></div>
              <div><span>Website:</span>
              {
                (website)?
                <span><a href={website} target="_blank" style={{color:colors.blue}} ><span className="material-icons">language</span></a></span>
                :null
              }
            </div>
              <div><span>Social:</span>
              <span>
                {(facebook)?<a href={facebook}><img src={facebook_logo} alt="facebook" /></a>:null}
                {(twitter)?<a href={twitter}><img src={twitter_logo} alt="twitter" /></a>:null}
                {(linkedin)?<a href={linkedin}><img src={linkedin_logo} alt="linkedin" /></a>:null}
                {(instagram)?<a href={instagram}><img src={instagram_logo} alt="instagram" /></a>:null}
              </span>
            </div>
            </div>
            : null
            }

          </div>
          <div>
            <div>
            <div>
            <Typography className={classes.subTitle}>PREVIOUS TEAMS <img src={(previousTeamsToggle)?upArrow:downArrow} onClick={e => setPreviousTeamsToggle(!previousTeamsToggle)} /></Typography>
            {
              (previousTeams.length >= 1) ?
                <div>
                  {
                    (!isMobile || (isMobile && previousTeamsToggle)) ?
                      <div className={classes.awardsAndExperience}>
                      {
                        previousTeams.map((team, index) => {
                          return (
                            <div key={index}>
                              <span>{team.position}</span>
                              <span>{team.team}</span>
                              <span>{team.country} | {team.from_year}-{team.to_year}</span>
                            </div>
                          )
                        })
                      }
                      </div>
                      :null
                  }
                </div>

              : <div className={classes.noData}>No previous teams</div>
            }
            </div>
            <div>
            <Typography className={classes.subTitle}>AWARDS <img src={(awardsToggle)?upArrow:downArrow} onClick={e => setAwardsToggle(!awardsToggle)} /></Typography>
            {
              (awards.length >= 1) ?
                <div>
                  {
                    (!isMobile || (isMobile && awardsToggle)) ?
                      <div className={classes.awardsAndExperience}>
                      {
                        awards.map((award, index) => {
                          return (
                            <div key={index}>
                              <span>{award.league}</span>
                              <span>{award.name}</span>
                              <span>{award.year}</span>
                            </div>
                          )
                        })
                      }
                      </div>
                  :null
                  }
                </div>
              : <div className={classes.noData}>No awards</div>
            }
            </div>
            </div>
            <div className={classes.recruitment}>
              <Typography className={classes.subTitle}>RECRUITMENT DETAILS</Typography>
              {notes}
            </div>
          </div>
        </div>
        <Typography ref={mediaRef} className={classes.title} name="media" id="media"><img src={mediaIcon} alt="media icon" />MEDIA</Typography>
        <Typography className={classes.subTitle}>PLAYER'S  REEL ({reelsCount})</Typography>
        <Videos slug={params.user} type={1} getCount={e=>setReelsCount(e)}/>
        <Typography className={classes.subTitle}>GAME HIGHLIGHTS ({highlightsCount})</Typography>
        <Videos slug={params.user} type={2} getCount={e=>setHighlightsCount(e)}/>
        <Typography ref={newsRef} className={classes.title} name="news" id="news"><img src={newsIcon} alt="news icon" />IN THE NEWS</Typography>
        {
          (name) ?
            <Posts search={name}/>
          :null
        }        
      </div>
    </div>
  );
};

export default withWidth()(UserProfile);