export const colors = {
  blue: "#4968FF",
  blueDark: "#2A43BF",
  blueDeep: "#241A51",
  blueLight: "#2D8EFF",
  blueGrey: "#4C4F78",
  bluePale: "#CED6E9",
  green: "#59A017",
  grey: "#ABB1C0",
  greyLight: "#F2F2F4",
  greyDark: "#E5E5E9",
  orange: "#F29730",
  purple: "#9841C5",
  red: "#D0021B",
  turquoise: "#42BBCF",
  yellow: "#FFFC20",
  successMessage: "#94cc64",
  errorMessage: "#fe8785",
  toggle:"linear-gradient(45deg, #294283 0%, #2C68E4 100%)"
};

export const spacing = {
  xSmall: 8,
  small: 16,
  medium: 24,
  large: 32,
  xLarge: 40,
  xxLarge: 48,
  xxxLarge: 56,
  xxxxLarge: 64,
};

export const fonts = {
  headingFontFamily: "GothamSSm",
  headingFontWeight: 800,
  subHeadingFontFamily: "GothamSSm",
  subHeadingFontWeight: 500,
  bodyFontFamily: "GothamSSm",
  bodyFontWeight: 400,
};

export const components = {
  commonInput: {
    backgroundColor: "white",
    height: "37px",
    width: "250px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    padding: 0,
    margin: 0,
    paddingLeft: spacing.xSmall,
  },
  commonInputWithPlaceholder : {
    backgroundColor: "white",
    height: "37px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    color: colors.grey,
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
      color: colors.grey,
    },
    paddingLeft: spacing.xSmall,
  },
  commonButtonInput: {
    fontSize: "12px",
    backgroundColor: "white",
    height: "37px",
    width: "125px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    paddingLeft: spacing.xSmall,
  },
  commonCustomSwitch: {
    root: {
      width: "100px",
      height: "44px",
      paddingTop: "0px",
    },
    
    switchBase: {
      paddingTop: "0px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      '&$checked': {
        color: colors.grey,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      '&$checked + $track': {
        background: colors.toggle,
        opacity: 1,
      },
    },
    thumb: {
      height: "26px",
      width: "26px",
      margin: "3px 25px 0px 7px",
      backgroundColor: "white",
    },
    checked: {
      width: "100px",
    },
    track: {
      borderRadius: "50px",
    },
  },
  commonTitle: {
    fontFamily: "GothamSSm",
    fontWeight: 400,
    fontSize: "25px",
    letterSpacing: "-0.5px",
    color: colors.blueDeep,
  },
  commonSubtitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: colors.blueGrey,
    letterSpacing:" -0.5px"
  },
  commonLeftArrow: {
    fontSize: "24px",
    fontWeight: "bold",
    color: colors.blue,
    marginRight: spacing.xSmall,
  }
}