import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    fof: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "inherit",
      "& > h1": {
        fontSize: "50px",
        paddingRight: "12px",
        color: "#888",
      },
      "& > div > a": {
        fontSize: "16px",
        color: "#241A51",
      },
      "& > div > a:visited": {
        color: "#241A51",
      },
    }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.fof}>
      <h1>Page Not Found</h1>
      <div><a href="/">Return home</a></div>
    </div>
  );
};

export default NotFound;