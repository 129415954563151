import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Geonames from 'geonames.js';

import {
  savePlayingExperience,
  getPlayingExperiences,
  removePlayingExperience,
  updateRecruitmentInfo } from "../../../api/users";
  import { getTeams } from "../../../api/teams";
import { COACHING_POSITIONS, LEVELS, YEARS } from  "../../../constants/constants";
import step2 from "../../../assets/images/step_2.png";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import downArrow from "../../../assets/images/down_arrow.png";
import upArrow from "../../../assets/images/up_arrow.png";

const geonames = new Geonames({
  username: 'alwaysaugust',
  lan: 'en',
  encoding: 'JSON'
});

const CustomSwitch = withStyles(components.commonCustomSwitch)(Switch);

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      overflowX: "hidden",
    },
  },

  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  step: {
    width: "100px",
    margin: "0 auto",
    marginBottom: spacing.medium,
  },

  title: {
    ...components.commonTitle,
    margin: "0 auto",
    marginBottom: spacing.large,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    }
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: `${spacing.small}px !important`,
    marginTop: spacing.xSmall,
    marginLeft: spacing.small,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      fontSize: "26px",
    },
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    padding: "10px 10px 30px 10px",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    }
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  button: {
    margin: "0 auto",
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: fonts.headingFontWeight,
    color: colors.blueDark,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
      color: colors.blueDark,
    },
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
    cursor: "pointer",
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  form: {
    "& > *": {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        margin: 0,
      },
      margin: "0 auto",
      width: "100%",
    },
  },

  inputsWrapper: {
    marginTop: "0px",
    width: "570px",
    display: "inline-block",
    overflow: "hidden",
    "& input:nth-child(2)": {
      marginLeft: spacing.medium,
    },
    "& textarea": {
      width: "90%",
      height: "70px",
      resize: "none",
      paddingLeft: spacing.xSmall,
      "&::placeholder":{
        paddingLeft: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0px",
      float: "none",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
  },

  input: {
    ...components.commonButtonInput,
    backgroundColor: "white",
    height: "35px",
    width: "250px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "100%",
    },
  },

  buttonsInput: {
    ...components.commonButtonInput,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  autoCompleteInput: {
    backgroundColor: "white",
    width: "250px",
    height: "35px",
  },

  select: {
    margin: "0",
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  buttonsInputWrapper: {
    display: "inline-block",
    position: "relative",
    "& input":{
      backgroundColor:"white",
    },
    "& div": {
      display: "block",
      position: "absolute",
      width: "20px",
      height: "10px",
      paddingLeft: "5px",
      textAlign: "center",
      color: "white",
      lineHeight: "10px",
      cursor: "pointer",
    },
    "& #weightUp, #heightUp":{
      right: "0px",
      top: "5px",
      background: `url(${upArrow})`,
      backgroundRepeat: "no-repeat",
    },
    "& #weightDown, #heightDown": {
      right: "0px",
      top: "20px",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
    }
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    width: "100%",
    marginBottom: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.xSmall,
    },
  },

  addButton: {
    padding: "10px 15px",
    width: "150px",
    borderRadius: "25px",
    textAlign: "center",
    fontSize: "14px",
    color: colors.blueDark,
    cursor: "pointer",
    backgroundColor: colors.greyDark,
    "& span": {
      color: colors.blue,
      fontSize: "24px",
      float: "left",
      lineHeight: "0.6",
    },
  },

  coachingExperiencesSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "10px",
    backgroundColor: colors.greyDark,
    "& select": {
      marginBottom: "5px",
    },
    "& label": {
      marginTop: "15px",
      paddingBottom: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  inputError: {
    border: "1px solid red",
  },

  teamsSection: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    width: "100%",
    backgroundColor: colors.greyLight,
    "& div":{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      backgroundColor: "white",
      borderRadius: "10px",
      margin: spacing.xSmall,
      border: `1px solid ${colors.greyDark}`,
      webkitBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    mozBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    boxShadow: `0 8px 6px -6px ${colors.grey}`,
      "& a":{
        textDecoration: "none",
        color: colors.blue,
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span":{
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span:nth-child(2)":{
        textAlign: "center",
      },
      "& span:last-child":{
        textAlign: "right",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

}));

let countries = [];

const CoachInformation = () => {
  const classes = useStyles();
  const history = useHistory();

  const [locations, setLocations] = useState([]);

  const [position, setPosition] = useState('');
  const [level, setLevel] = useState('');
  const [onTeam, setOnTeam] = useState(false);
  const [lookingForTeam, setLookingForTeam] = useState(false);
  const [passportReady, setPassportReady] = useState(false);
  const [citizenship, setCitizenship] = useState('');
  const [salaryFrom, setSalaryFrom] = useState('');
  const [salaryTo, setSalaryTo] = useState('');
  const [salaryNegotiable, setSalaryNegotiable] = useState(false);
  const [notes, setNotes] = useState('');

  const [coachingExperiencesSection, setCoachingExperiencesSection] = useState(false);
  const [fromYear, setFromYear] = useState('');
  const [toYear, setToYear] = useState('');
  const [coachingPosition, setCoachingPosition] = useState('');
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [teamList, setTeamList] = useState([]);

  const [teamError, setTeamError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [coachingPositionError, setCoachingPositionError] = useState(false);
  const [selectedTeamError, setSelectedTeamError] = useState(false);
  
  const toggleCurrentTeam = (event, newValue) => {
    setCurrentTeam(newValue);
  }

  const toggleOnTeam = (event, newValue) => {
    setOnTeam(newValue);
  }

  const toggleLookingForTeam = (event, newValue) => {
    setLookingForTeam(newValue);
  }

  const togglePassportReady = (event, newValue) => {
    setPassportReady(newValue);
  }

  const toggleSalaryNegotiable = (event, newValue) => {
    setSalaryNegotiable(newValue);
  }

  const getLocationInfo = async () => {
    if(countries.length === 0){
      countries = await geonames.countryInfo({});
    }
    return countries;
  };

  const openCoachingExperiences = () => {
    setCoachingExperiencesSection(true);
  }

  const closeCoachingExperiences = () => {
    setCoachingExperiencesSection(false);
  }

  const updateRecruitment = () => {
    let recruitmentData = {
      position: position,
      level: level,
      currentlyOnTeam: onTeam,
      lookingForTeam: lookingForTeam,
      citizenship: citizenship,
      passportReady: passportReady,
      expectedSalaryUpper: salaryFrom,
      expectedSalaryLower: salaryTo,
      salaryNegotiable: salaryNegotiable,
      notes: notes,
    };

    updateRecruitmentInfo(recruitmentData).then((response) => {
      window.location.href = '/profile/basic_information';
    }).catch(e => {
      console.log(e);
    });
  };

  const getAllTeams = () => {
    getTeams().then((response) => {
      setTeamList(response.data.teams);
    }).catch(e => {
      console.log(e);
    });
  }

  const saveTeam = () => {

    let hasError=false;

    if(!selectedTeam){
      setSelectedTeamError(true);
      hasError=true;
    }

    if(!fromYear || !toYear || (fromYear > toYear)){
      setYearError(true);
      hasError=true;
    }

    if(!coachingPosition){
      setCoachingPositionError(true);
      hasError=true;
    }

    if(hasError) return;

    let coachingExperience = {
      team: selectedTeam,
      from: fromYear,
      to: toYear,
      position: coachingPosition,
      currentTeam: currentTeam,
    };

    savePlayingExperience(coachingExperience).then((response) => {
      getPlayerTeams();
      closeCoachingExperiences();
    }).catch(e => {
      console.log(e);
    });
  }

  const removeTeam = (team_id) => {
    removePlayingExperience(team_id).then((response) => {
      getPlayerTeams();
    }).catch(e => {
      console.log(e);
    });
  }

  const getPlayerTeams = () => {
    getPlayingExperiences().then((response) => {
      setTeams(response.data.playingExperiences);
    }).catch(e => {
      console.log(e);
    });
  }

  useEffect(() => {
    getPlayerTeams();
    getAllTeams();

    getLocationInfo().then(countries => {
      setLocations(countries.geonames);
    });

  }, [history]);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <img src={step2} className={classes.step} alt="step 2"/>
          <Typography className={classes.title}>SETUP YOUR COACHING INFORMATION</Typography>
        </div>
        <div className={classes.profileContainer}>          
          <div className={classes.buttons}>
            <a href="/" className={classes.cancelButton}>Cancel</a>
            <div className={classes.button} onClick={updateRecruitment}>
                <span>SAVE</span>
            </div>
          </div>
          <div className={classes.dashedLine}></div>
          <Typography className={classes.subTitle}>EXPERIENCE</Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your coaching position?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <select className={classes.input + " " + classes.select} onChange={e => setPosition(e.target.value)}>
                <option value=""></option>;
                {
                  COACHING_POSITIONS.map(position => {
                    return <option value={position}>{position}</option>;
                  })
                }
              </select>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your coaching level?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <select className={classes.input + " " + classes.select} onChange={e => setLevel(e.target.value)}>
                <option value=""></option>;
                {
                  LEVELS.map(level => {
                    return <option value={level}>{level}</option>;
                  })
                }
              </select>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Past coaching experiences</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <div className={classes.addButton} onClick={openCoachingExperiences}>
                <span>+</span>
                ADD
              </div>
            </div>
          </form>
          </div>
          {
            (teams.length > 0) ?
            <div className={classes.teamsSection}>
              {
                teams.map(team => {
                return <div><span>{team.team}</span><span>{team.from_year + '-' + team.to_year}</span><span>{team.position}</span><span className={classes.siteLink} onClick={() => removeTeam(team.id)}>Remove</span></div>;
                })
              }
            </div>
            :null
          }

          {
            (coachingExperiencesSection) ?
              <div className={classes.coachingExperiencesSection}>
                <form className={classes.form} noValidate autoComplete="off">
                  <FormControl>
                    <label>Team</label>
                    <Autocomplete
                      freeSolo
                      className={(!selectedTeamError) ? classes.autoCompleteInput : classes.autoCompleteInput + " " + classes.inputError}
                      onChange={(event, newValue) => {
                        if(newValue){
                          setSelectedTeam(newValue.id);
                        }
                      }}
                      options={teamList}
                      getOptionLabel={(option) => option.name}
                      value={selectedTeam}
                      renderInput={(params) => <TextField  {...params} />}
                    />
                    <label>Years</label>
                    <select type="text" className={(!yearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setFromYear(e.target.value)}>
                      <option value="">From</option>
                      {
                        YEARS.map(year => {
                          return <option value={year}>{year}</option>;
                        })
                      }
                    </select>
                    <select type="text" className={(!yearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setToYear(e.target.value)}>
                      <option value="">To</option>
                      {
                        YEARS.map(year => {
                          return <option value={year}>{year}</option>;
                        })
                      }
                    </select>
                    <label>Position</label>
                    <select className={(!coachingPositionError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setCoachingPosition(e.target.value)}>
                      <option value=""></option>;
                      {
                        COACHING_POSITIONS.map(position => {
                          return <option value={position}>{position}</option>;
                        })
                      }
                    </select>
                    <label>Current team?</label>
                    <FormControlLabel
                    control={<CustomSwitch checked={currentTeam} value={currentTeam} onChange={toggleCurrentTeam} />}
                    label={(currentTeam ? "Yes" : "No")}
                    />
                  </FormControl>
                </form>
                <div className={classes.buttons}>
                  <span className={classes.cancelButton} onClick={closeCoachingExperiences}>Cancel</span>
                  <span className={classes.button} onClick={saveTeam}>Add Team</span>
                </div>
              </div>
              :null
          }
          
          <div className={classes.profileContainer}>
          <div className={classes.dashedLine}></div>
          <Typography className={classes.subTitle}>STATUS</Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Are you currently on a team?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
            <FormControlLabel
              control={<CustomSwitch checked={onTeam} value={onTeam} onChange={toggleOnTeam} />}
              label={(onTeam ? "Yes" : "No")}
            />
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Are you looking for a team?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
            <FormControlLabel
              control={<CustomSwitch checked={lookingForTeam} value={lookingForTeam} onChange={toggleLookingForTeam} />}
              label={(lookingForTeam ? "Yes" : "No")}
            />
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>You are a citizen of which country?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
            <select className={classes.input + " " + classes.select} onChange={e => setCitizenship(e.target.value)}>
              <option value=""></option>
              {
                locations.map(location => {
                  return <option id={location.geonameId} value={location.countryName}>{location.countryName}</option>
                })
              }
            </select>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Are you passport ready?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
            <FormControlLabel
              control={<CustomSwitch checked={passportReady} value={passportReady} onChange={togglePassportReady} />}
              label={(passportReady ? "Yes" : "No")}
            />
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your expected salary?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <input type="number"
              className={classes.input}
              placeholder="From (USD)"
              value={salaryFrom}
              maxLength="10"
              onChange={e => {
                let { value, maxLength } = e.target;
                let prev = value.slice(0,-1);
                value = (value.length > Number(maxLength)) ? prev : value;
                setSalaryFrom(value)
              }}
              />
            </div>
            <div className={classes.inputsWrapper}>
            <input type="number"
              className={classes.input}
              placeholder="To (USD)"
              value={salaryTo}
              maxLength="10"
              onChange={e => {
                let { value, maxLength } = e.target;
                let prev = value.slice(0,-1);
                value = (value.length > Number(maxLength)) ? prev : value;
                setSalaryTo(value)
              }}
              />
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Is salary negotiable?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
            <FormControlLabel
              control={<CustomSwitch checked={salaryNegotiable} value={salaryNegotiable} onChange={toggleSalaryNegotiable} />}
              label={(salaryNegotiable ? "Yes" : "No")}
            />
            </div>
          </form>
          <div className={classes.dashedLine}></div>
          <Typography className={classes.subTitle}>OTHER</Typography>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Is there anything else?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <textarea rows="4" placeholder="Is there anything else?..." className={classes.input} onChange={e => setNotes(e.target.value)}>
              </textarea>
            </div>
          </form>
          <div className={classes.dashedLine}></div>
          <div className={classes.buttons}>
            <a href="/" className={classes.cancelButton}>Cancel</a>
            <div className={classes.button} onClick={updateRecruitment}>
                <span>SAVE</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoachInformation;