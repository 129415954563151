import React, { useState } from "react";
import { useLocation } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { fonts, colors, spacing, components } from "../../../constants/theme";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import { updatePassword } from "../../../api/authentication";
import { hasUpperCase } from "../../../utils/helpers";
import { Header } from "../../../components/header";
import userIcon from "../../../assets/images/onboarding_icon.svg";

const queryString = require("query-string");

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: colors.greyLight,
    display: "block",
    width: "50%",
    margin: "0 auto",
    outline: 0,
    position: 'relative',
    padding: '20px 40px 80px 40px',
    borderRadius: 10,
    backgroundSize: '100% auto',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      maxHeight: '90%',
      backgroundImage: 'none',
      width: "90%",
    }
  },

  title: {
    ...components.commonTitle,
    width: "55%",
    margin: "0 auto",
    marginTop: spacing.xLarge,
    marginBottom: spacing.medium,
    display: "block",
    "& > img":{
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
    },
  },

  subTitle: {
    fontFamily: fonts.subHeadingFontFamily,
    fontWeight: fonts.subHeadingFontWeight,
    color: colors.blueGrey,
    fontSize: '16px',
    lineHeight: 1.375,
    letterSpacing: -0.3,
    marginBottom: 40,
    marginTop: 32,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  form: {
    paddingTop: 20,
  },
  formControl: {
    width: '100%',
    marginBottom: '2em',
    backgroundColor: "white",
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px',
      color: colors.blueGrey,
      fontSize: '14px',
      lineHeight: 1.42,
      fontFamily: fonts.bodyFontFamily,
      fontWeight: fonts.bodyFontWeight,
      [theme.breakpoints.down('xs')]: {
        fontSize: "16px",
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.bluePale,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.bluePale,
      top: 0,
      '& legend': {
        display: 'none !important',
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.bluePale,
        borderWidth: 1,
      }
    },
    '& .MuiInputLabel-root': {
      top: '-22px',
      fontFamily: fonts.subHeadingFontFamily,
      fontWeight: fonts.subHeadingFontWeight,
      fontSize: '14px',
      lineHeight: 1.43,
      color: colors.blueGrey,
      transform: 'none',
      '&.shrink': {
        transform: 'none'
      }
    }
  },

  inputError: {
    border: "1px solid red",
  },

  forgotPassword: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: 1.46,
    color: colors.blue,
    textDecoration: 'none',
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      lineHeight: 2,
    },
  },

  hr: {
    border: 'none',
    height: 1,
    marginTop: 28,
    marginBottom: 32,
    backgroundImage: 'linear-gradient(to right, #DEDEDE 33%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '3px 1px',
    backgroundRepeat: 'repeat-x',
  },

  button: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: "auto",
    width: "330px",
    height: "45px",
    lineHeight: "45px",
    color: colors.blueDark,
    fontWeight: fonts.headingFontWeight,
    backgroundImage: `url(${primaryButton})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: "center",
    cursor: "pointer",
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
    '&:hover': {
      backgroundImage: `url(${primaryButton})`,
      backgroundColor: "transparent",
      boxShadow: "none",
      '& span': {
        color: colors.blue
      }
    },
  },

  leftArrow: components.commonLeftArrow,

  successMessage: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: colors.successMessage,
    color: "white",
    textAlign: "left",
    padding: "15px",
  },

  errorMessage: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: colors.errorMessage,
    color: "white",
    textAlign: "left",
    padding: "15px",
  },

  goBack: {
    margin: "20px 22%",
    [theme.breakpoints.down('xs')]: {
      margin: "20px 5%",
      alignItems: "end",
      maxWidth: "85%",
    },
  },
}));

const ResetPassword = () => {
  const classes = useStyles();

  const location = useLocation();

  let param = queryString.parse(location.search);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passToken, setPassToken] = useState(param.passToken);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = () => {
    if(passwordUpdated) return;

    let hasError=false;

    if(!hasUpperCase(password) || password.length < 8){
      setPasswordError(true);
      hasError=true;
    }

    if(password !== confirmPassword){
      setPasswordError(true);
      hasError=true;
    }

    if(hasError) return;

    let data = {
      password: password,
      confirmPassword: confirmPassword,
      passToken: passToken,
    };

    updatePassword(data).then((response) => {
      if(response.data.status === "ERROR"){
        setErrorMessage(response.data.error);
        return;
      }

      setSuccessMessage("Password updated!");
      setPasswordUpdated(true);
    }).catch(e => {
      setErrorMessage("ERROR: Something went wrong");
    });
  };

  return (
      <>
        <Header />
        <Typography className={classes.title} id="media"><img src={userIcon} alt="media icon" />New password</Typography>
        <div className={classes.container}>
          <Grid container spacing={0}>
            {(successMessage != '')?<div className={classes.successMessage}>{successMessage}</div>:null}
            {(errorMessage != '')?<div className={classes.errorMessage}>{errorMessage}</div>:null}
            <Grid item sm={12}>
              <h6 className={classes.subTitle}>Enter your new password below</h6>
              <form noValidate className={classes.form}>
                <TextField id="password" label="New Password" type="password" variant="outlined" className={(!passwordError)?classes.formControl:classes.formControl + " " + classes.inputError} onChange={e => setPassword(e.target.value)} />
                <TextField id="confirmPassword" label="Confirm Password" type="password" variant="outlined" className={(!passwordError)?classes.formControl:classes.formControl + " " + classes.inputError} onChange={e => setConfirmPassword(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <span>
                  - 8 characters minimum
                  <br/>
                  - at least 1 uppercase letter
                </span>
                <hr className={classes.hr} />
                <Grid container justify="center">
                  <Button variant="contained" color="primary" className={(passwordUpdated) ? classes.button + ' ' + classes.greyButton : classes.button} onClick={onSubmit}>
                    Submit
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
        <Grid item xs={6} alignItems="center" container className={classes.goBack}>
            <span className={classes.leftArrow}> {'<'} </span><a href="/login" className={classes.forgotPassword}>Return to login page</a>
        </Grid>
      </>
  );
}

export default ResetPassword;
