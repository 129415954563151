import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import { fonts, colors, spacing } from  "../../../constants/theme";

const useStyles = makeStyles(theme => ({
  card: {
    display: "inline-block",
    width: "440px",
    height: "350px",
    boxShadow: "0px 3px 5px 3px rgba(239,240,241,1)",
    borderRadius: "5px",
    marginBottom: spacing.medium,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
  },

  image: {
    height: "100%",
    width: "auto",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    float: "right",
    width: "60%",
    padding: "20px 10px",
    height: "90%",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },

  title: {
    fontSize: "20px",
    paddingBottom: "10px",
  },

  description: {
    fontSize: "14px",
  },

  button: {
    marginTop: "auto",
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    textAlign: "center",
    cursor: "pointer",
    color: colors.blueDark,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
      marginBottom: "15px",
    },
  }

}));

export const PlayerCard = ({image, title, description, link, type, customClickEvent}) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <img src={image} className={classes.image} alt="user type"/>
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
        <div className={classes.button} onClick={e=>customClickEvent()}>
          <span>SELECT</span>
        </div>
      </div>
    </div>
  );
};