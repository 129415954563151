import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactPlayer from 'react-player'

import { spacing, colors } from "../../../constants/theme";
import { getVideosBySlug } from "../../../api/videos";

JavascriptTimeAgo.addLocale(en);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    marginBottom: "40px",
  },

  video: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginRight: spacing.small,
    },
    "& img":{
      width: "220px",
      height: "140px",
      marginBottom: spacing.xSmall,
    },
    "& > div": {
      fontSize: "14px",
      color: colors.blueDeep,
      width: "220px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& div:nth-child(3)": {
      fontSize: "11px",
      color: colors.grey,
    },
  },

  videoPopUp: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: `1px solid ${colors.greyDark}`,
    backgroundColor: "white",
    height: "auto",
    padding: "20px",
    "& > span":{
      alignSelf: "flex-end",
      cursor: "pointer",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },

  noMediaFound: {
    fontSize: "18px",
    color: colors.grey,
  },
}));

export const Videos = ({slug, getCount, type, videoList=[]}) => {
  const classes = useStyles();

  const [videos, setVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState();
  const [videoPopUpOpen, setVideoPopUpOpen] = useState(false);
  const [videoPlatform, setVideoPlatform] = useState();

  const getVideoData = async (url) => {
    let id;

    if (url.indexOf('youtube.com') > -1) {
        id = url.split('v=')[1].split('&')[0];
        setVideoPlatform('youtube');
        return processYouTube(id);
    } else if (url.indexOf('youtu.be') > -1) {
        id = url.split('/')[1];
        setVideoPlatform('youtube');
        return processYouTube(id);
    } else if (url.indexOf('vimeo.com') > -1) {
        if (url.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
            id = url.split('/')[3];
        } else if (url.match(/^vimeo.com\/channels\/[\d\w]+#[0-9]+/)) {
            id = url.split('#')[1];
        } else if (url.match(/vimeo.com\/groups\/[\d\w]+\/videos\/[0-9]+/)) {
            id = url.split('/')[4];
        } else {
            throw new Error('Unsupported Vimeo URL');
        }

        let data = fetch(`http://vimeo.com/api/v2/video/${id}.json`);
        let response = await data.json();
        let minutes = Math.floor(response[0].duration / 60);
        let seconds = response[0].duration - minutes * 60;
        let time = `${minutes}:${seconds}`;
        setVideoPlatform('vimeo');
        return {"thumbnail":response[0].thumbnail_large, "time":time};

    } else if (url.indexOf('hudl.com') > -1) {
      setVideoPlatform('hudl');
      return processHudl(url);
    } else {
        throw new Error('Unrecognised URL');
    }

    async function processYouTube(id) {
        if (!id) {
            throw new Error('Unsupported YouTube URL');
        }
        let response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${id}&key=AIzaSyBdhzaE3kQ0XW6P9Nnh1kAeg1V08N4xITs&part=snippet,contentDetails`);
        let data = await response.json();
        let time = covtime(data.items[0].contentDetails.duration)
        return {"thumbnail":`http://i2.ytimg.com/vi/${id}/hqdefault.jpg`, "time":time};
    }

    async function processHudl(url) {
      let response = await fetch(url);
      let data = await response.text();

      let el = document.createElement( 'html' );
      el.innerHTML = data;

      let time = secsToMinsAndSecs(el.querySelectorAll('meta[property="video:duration"]')[0].getAttribute('content'));

      return {"thumbnail":el.querySelectorAll('meta[property="og:image"]')[0].getAttribute('content'), "time": time};
    }

    function covtime(youtube_time){
      let array = youtube_time.match(/(\d+)(?=[MHS])/ig)||[]; 
        var formatted = array.map(function(item){
            if(item.length<2) return '0'+item;
            return item;
        }).join(':');
        return formatted;
    }
  }

  const processVideos = async (vids, type) => {
    let userVideos = [];

    for(let v=0; v < vids.length; v++){
      if(vids[v].type === type){
        let video = await getVideoData(vids[v].url);
        video.title = vids[v].name;
        video.url = vids[v].url;
        userVideos.push(video);
      }
    }
    getCount(userVideos.length);

    return userVideos;
  };

  const openPlayer = (url) => {
    if(videoPlatform === 'hudl'){
      url = url.replace("video", "embed/video");
    }
    setCurrentVideo(url);
    setVideoPopUpOpen(true);
  };

  const secsToMinsAndSecs = (duration) =>{   
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  useEffect(() => {
    if(videoList.length > 0){
      async function fetchData(videoList) {
        let vids = await processVideos(videoList, type);
        setVideos(vids);
      };
      fetchData(videoList)
    }else{
      async function fetchData(slug) {
        let response = await getVideosBySlug(slug);
        let vids = await processVideos(response.data.videos, type);
        setVideos(vids);
      };

      fetchData(slug);
    }

  }, []);

  return (
    <div className={classes.container}>
      {
        (videos.length >= 1) ?
          <>
          {
            videos.map((video, index) => {
              return (
                <div key={index} className={classes.video} onClick={e => openPlayer(video.url)}>
                  <img src={video.thumbnail} alt="video thumbnail" />
                  <div>{video.title}</div>
                  <div>{video.time} mins</div>
                </div>
              )
            })
          }
          </>: <div className={classes.noMediaFound}>No media found</div>
      }
      
      {
        (videoPopUpOpen)?
          <div className={classes.videoPopUp}>
            <span onClick={e=>setVideoPopUpOpen(false)}>X</span>
            {
              (videoPlatform === 'hudl') ?
              <iframe src={currentVideo} style={{margin:"0 auto"}} width='640' height='360' frameborder='0' allowfullscreen></iframe>
              :
              <ReactPlayer url={currentVideo} width="100%" />
            }
          </div>
          : null
      }
    </div>
  );
};