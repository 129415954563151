import API from "./api";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getUserInfo = async () => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getUserInfo?token=${token}`);
};

const getUserInfoBySlug = async (slug) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getUserInfoBySlug?token=${token}&slug=${slug}`);
};

const getRecruitmentInfo = async () => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getRecruitmentInfo?token=${token}`);
};

const updateProfile = async (data) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  data.token = token;
  return await API.post("/updateProfile", data);
};

const updateRecruitmentInfo = async (data) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  data.token = token;
  return await API.post("/updateRecruitmentInfo", data);
};

const savePlayingExperience = async (data) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  data.token = token;
  return await API.post("/savePlayingExperience", data);
};

const getPlayingExperiences = async () => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getPlayingExperiences?token=${token}`);
};

const removePlayingExperience = async (team_id) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  let data = {team_id:team_id, token:token};
  return await API.post("/removePlayingExperience", data);
};

const sendApprovalEmail = async (application_id) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  let data = {application_id:application_id, token:token};
  return await API.post("/sendApprovalEmail", data);
};

const getUsers = async (data) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getUsers?token=${token}&search=${data.search}&level=${data.level}&position=${data.position}&citizenship=${data.citizenship}&types=${data.types}&maxResults=${data.maxResults}&offset=${data.offset}`);
};

const getProfileImage = async () => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getProfileImage?token=${token}`);
};

const getCountries = async (data) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getCountries?token=${token}`);
};

const getCitizenship = async (data) => {
  let token = localStorage.getItem("token");
  if(!token)token=cookies.get("token");
  return await API.get(`/getCitizenship?token=${token}`);
};

export {
  getUserInfo,
  getUserInfoBySlug,
  getRecruitmentInfo,
  updateProfile,
  savePlayingExperience,
  getPlayingExperiences,
  removePlayingExperience,
  updateRecruitmentInfo,
  sendApprovalEmail,
  getUsers,
  getProfileImage,
  getCountries,
  getCitizenship,
};