import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import { fonts, spacing, colors, components } from  "../../../constants/theme";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import downArrow from "../../../assets/images/down_arrow.png";
import {hasUpperCase} from "../../../utils/helpers";
import {changePassword} from "../../../api/authentication";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        width: "100%",
        marginTop: spacing.large,
    },

    profileContainer: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        padding: "10px 20px",
        backgroundColor: colors.greyLight,
        borderRadius: "5px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },

    subTitle: {
        ...components.commonSubtitle
    },

    buttons: {
        display: "flex",
        marginLeft: "auto",
    },

    button: {
        margin: "0 auto",
        width: "200px",
        height: "45px",
        lineHeight: "45px",
        color: colors.blueDark,
        backgroundImage: `url(${primaryButton})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundImage: `url(${primaryButtonHover})`,
            color: colors.blue,
        },
        '& span': {
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: "normal",
            fontSize: "14px",
            fontWeight: fonts.headingFontWeight,
        },
    },

    cancelButton: {
        textDecoration: "none",
        fontSize: "13px",
        fontWeight: "bold",
        color: colors.blue,
        marginTop: spacing.small,
        marginRight: spacing.small,
    },

    dashedLine: {
        width: "100%",
        borderTop: "1px dashed",
        borderColor: colors.orange,
        marginTop: spacing.large,
        marginBottom: spacing.large,
    },

    form: {
        '& > *': {
            margin: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            margin: "0 auto",
        }
    },

    inputsWrapper: {
        display: "inline-block",
        "& input:nth-child(1)": {
            marginRight: spacing.medium,
        },
    },

    input: {
        backgroundColor: "white",
        height: "35px",
        width: "250px",
        borderRadius: "2px",
        boxShadow: "inset 0 0 1px 0px #888",
        paddingLeft: spacing.xSmall,
        "&::placeholder":{
            paddingLeft: spacing.xSmall,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "16px",
        },
    },

    label: {
        color: colors.blueDeep,
        fontSize: "14px",
        marginTop: spacing.medium,
        marginBottom: 0,
        width: "100%",
    },

    passwordRequirements: {
        paddingTop: "5px",
        fontSize: "12px"
    },

    inputError: {
        border: "1px solid red",
    },


    successMessage: {
        backgroundColor: colors.successMessage,
        color: "white",
        padding: "15px",
        marginLeft: "15px",
    },

    errorMessage: {
        backgroundColor: colors.errorMessage,
        color: "white",
        padding: "15px",
        marginLeft: "15px"
    }

}));


const ChangePassword = () => {
    const classes = useStyles();

    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    const passwordChangeHandler = () => {
        setSuccessMessage(false);
        setErrorMessage(false);
        // First perform check to make sure that all password rules are met
        // TODO add styling to show input boxes that have problems
        if (newPassword !== newPasswordConfirmation) {
            setErrorMessage("New Password and New Password Confirmation don't match");
            return;
        }
        if (currentPassword === newPassword) {
            setErrorMessage('New Password must be different from current password');
            return;
        }

        if (!hasUpperCase(newPassword)) {
            setErrorMessage('New password must have an uppercase character');
            return;
        }

        if(newPassword.length < 8) {
            setErrorMessage('New password must have a minimum of 8 characters');
            return;
        }

        // If all password rules are met, update password for users
        const data = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirmation
        };
        changePassword(data).then(response => {
            if (response.data.status === "ERROR") {
                setErrorMessage(response.data.error);
                return;
            } else {
                console.log(response.data)
                setCurrentPassword('');
                setNewPassword('');
                setNewPasswordConfirmation('');
                setSuccessMessage("Your password has been changed");
                setErrorMessage('');
            }
            return;
        });
    };

    return (
        <>
            <div className={classes.content}>
                <div className={classes.profileContainer}>
                    {(successMessage)?<div className={classes.successMessage}>{successMessage}</div>:null}
                    {(errorMessage)?<div className={classes.errorMessage}>{errorMessage}</div>:null}
                    <form className={classes.form} noValidate autoComplete="off">
                        <Typography className={classes.subTitle}>Change Password</Typography>
                        <FormControl className={classes.label}>
                            <span>Current Password</span>
                        </FormControl>
                        <div className={classes.inputsWrapper}>
                            <FormControl>
                                <input type="password" placeholder="Enter your current password"
                                       className={classes.input} value={currentPassword}  onChange={e => setCurrentPassword(e.target.value)}/>
                            </FormControl>
                        </div>
                    </form>
                    <form className={classes.form} noValidate autoComplete="off">
                        <FormControl className={classes.label}>
                            <span>New Password</span>
                        </FormControl>
                        <div className={classes.inputsWrapper}>
                            <FormControl>
                                <input type="password" placeholder="Enter your new password"
                                       className={classes.input} value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                                <span className={classes.passwordRequirements}>
                                  - 8 characters minimum
                                  <br/>
                                  - at least 1 uppercase letter
                                </span>
                            </FormControl>
                        </div>
                    </form>
                    <form className={classes.form} noValidate autoComplete="off">
                        <FormControl className={classes.label}>
                            <span>Confirm Password</span>
                        </FormControl>
                        <div className={classes.inputsWrapper}>
                            <FormControl>
                                <input type="password" placeholder="Confirm your new password"
                                       className={classes.input} value={newPasswordConfirmation} onChange={e => setNewPasswordConfirmation(e.target.value)}/>
                            </FormControl>
                        </div>
                    </form>
                    <div className={classes.dashedLine}></div>
                    <div className={classes.buttons}>
                        <div className={classes.button} onClick={passwordChangeHandler}>
                            <span>SAVE</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;