import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import 'material-icons/iconfont/material-icons.scss';

import { sendApprovalEmail } from "../../../api/users";
import {
  getTeams,
  getTeamManagerApplication,
  removeTeamApplication,
  updateTeamApplication,
  saveTeamApplication,
  getLeagues,} from "../../../api/teams";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import downArrow from "../../../assets/images/down_arrow.png";
import twitter_logo from "../../../assets/images/twitter_logo.png";
import facebook_logo from "../../../assets/images/facebook_logo.png";
import instagram_logo from "../../../assets/images/instagram_logo.svg";
import linkedin_logo from "../../../assets/images/linkedin_logo.png";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import { toBase64, checkExtension } from "../../../utils/helpers";
import { MONTHS, LEVELS, MANAGING_POSITIONS } from  "../../../constants/constants";
import Menu from "../components/menu";

const CustomSwitch = withStyles(components.commonCustomSwitch)(Switch);

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    padding: "10px 20px",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      padding: "10px 10px",
    },
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  button: {
    margin: "0 auto",
    marginRight: spacing.xSmall,
    width: "250px",
    height: "45px",
    lineHeight: "45px",
    color: colors.blueDark,
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
    cursor: "pointer",
  },

  updateTeamContent: {
    display: "flex",
    flexDirection: "column !important",
    width: "auto",
  },

  titleWrapper: {
    display: "flex",
    width: "100%",
  },

  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "black",
    paddingLeft: spacing.xSmall,
    marginBottom: spacing.small,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    }
  },

  mainContainer: {
    display: "flex",
    height: "fit-content",
    width: "70%",
    flexDirection: "column",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  updateTeamContainer: {
    display: "flex",
    margin: "0 !important",
    padding: "0 !important",
    flexDirection: "column",
    "& label":{
      fontSize: "12px",
      marginBottom: "5px",
    },
    "& input":{
      width: "100%",
    },
    "& div:nth-child(2)": {
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    "& div:nth-child(3)": {
      marginLeft: spacing.medium,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    "& textarea": {
      width: "100%",
      height: "70px",
      resize: "none",
      paddingLeft: spacing.xSmall,
      "&::placeholder":{
        paddingLeft: "0px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  benefitsSection: {
    "& select":{
      width: "150px !important",
      [theme.breakpoints.down("xs")]: {
        width: "auto !important",
      },
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: "0px !important",
    marginTop: spacing.xSmall,
  },

  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    },
  },

  socialForm: {
    width: "100%",
    margin: "0px",
    "& div":{
      display: "inline-flex !important",
      flexDirection: "column !important",
    },
    "& > *": {
      margin: 0,
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      "& input": {
        width: "auto",
      },
    },
  },

  inputsWrapper: {
    display: "inline-block",
    overflow: "auto",
    "& input:nth-child(1)": {
      marginRight: spacing.medium,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        paddingBottom: "5px",
      },
    },
    "& .MuiInput-underline:before":{
      content: "none",
    },
    "& .MuiInput-underline:after":{
      content: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%",
        marginBottom: spacing.small,
      },
    },
    "& .MuiFormControl-root": {
      padding: 0,
    },
  },

  input: {
    backgroundColor: "white",
    height: "35px",
    width: "250px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "auto",
    },
  },

  select: {
    margin: 0,
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% center",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  autoCompleteInput: {
    backgroundColor: "white",
    width: "250px",
    height: "35px",
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    width: "100%",
    marginTop: spacing.medium,
    marginBottom: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.xSmall,
    },
  },

  uploadPic: {
    width: "100%",
    display: "block",
    fontSize: "12px",
    border: "1px dashed",
    borderColor: colors.orange,
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    "& span": {
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },

  addButton: {
    padding: "10px 15px",
    width: "150px",
    borderRadius: "25px",
    textAlign: "center",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: colors.greyDark,
    color: colors.blueDark,
    "&:hover": {
      backgroundColor: colors.grey,
      color: colors.blue,
    },
    "& span": {
      fontSize: "24px",
      float: "left",
      lineHeight: "0.6",
    },
  },

  addTeamSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingBottom: spacing.small,
    "& select": {
      marginBottom: "5px",
    },
    "& label": {
      marginTop: "15px",
      paddingBottom: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  teamsSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: "20px",
    backgroundColor: colors.greyDark,
    "& div":{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  selectedTeamSection: {
    display: "flex",
    backgroundColor: "white",
    padding: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

  updateTeam: {
    display: "flex",
    position: "relative",
    flexDirection: "column !important",
    height: "auto",
    backgroundColor: colors.greyDark,
    padding: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  fileInput: {
    width: "0.1px !important",
	  height: "0.1px",
	  opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: "1",
  },

  inputError: {
    border: "1px solid red",
  },

  socialLogo: {
    color: colors.blueDeep,
    fontSize: "14px",
    lineHeight: 4,
    padding: "5px 0px 0px 10px !important",
    "& img":{
      width: "30px",
      marginTop: spacing.xSmall,
      marginRight: spacing.xSmall,
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        marginRight: spacing.small,
      },
    },
    "& span":{
      fontSize: "30px",
      marginTop: spacing.xSmall,
      marginRight: spacing.xSmall,
      [theme.breakpoints.down("xs")]: {
        marginRight: spacing.small,

      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px !important",
      lineHeight: 1,
      marginTop: "0px",
    },
  },

  dashedLine: {
    width: "auto",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    margin: "24px 10px !important",
    padding: "0px !important",
  },
  
}));

const EditTeam = () => {
  const classes = useStyles();
  const history = useHistory();

  let months = [ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ];

  const [leaguesList, setLeaguesList] = useState([]);
  const [addTeamSection, setAddTeamSection] = useState(false);
  const [team, setTeam] = useState();
  const [teamId, setTeamId] = useState();
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedTeamName, setSelectedTeamName] = useState();
  const [selectedTeamLocation, setSelectedTeamLocation] = useState();
  const [selectedTeamDivision, setSelectedTeamDivision] = useState();
  const [managingPosition, setManagingPosition] = useState();

  const [updateTeamLeague, setUpdateTeamLeague] = useState();
  const [updateTeamLevel, setUpdateTeamLevel] = useState();
  const [updateTeamGender, setUpdateTeamGender] = useState();
  const [updateManagingPosition, setUpdateManagingPosition] = useState();
  const [updateTeamSeasonFrom, setUpdateTeamSeasonFrom] = useState();
  const [updateTeamSeasonTo, setUpdateTeamSeasonTo] = useState();
  const [lookingForGames, setLookingForGames] = useState(false);
  const [salaryFrom, setSalaryFrom] = useState();
  const [salaryTo, setSalaryTo] = useState();
  const [salaryNegotiable, setSalaryNegotiable] = useState(false);
  const [updateTeamCar, setUpdateTeamCar] = useState();
  const [updateTeamPhone, setUpdateTeamPhone] = useState();
  const [updateTeamReturnFlight, setUpdateTeamReturnFlight] = useState();
  const [updateTeamMeal, setUpdateTeamMeal] = useState();
  const [updateTeamGym, setUpdateTeamGym] = useState();
  const [updateTeamInsurance, setUpdateTeamInsurance] = useState();
  const [updateTeamAccommodation, setUpdateTeamAccommodation] = useState();
  const [updateTeamOtherBenefits, setUpdateTeamOtherBenefits] = useState();
  const [twitter, setTwitter] = useState();
  const [instagram, setInstagram] = useState();
  const [facebook, setFacebook] = useState();
  const [linkedin, setLinkedin] = useState();
  const [website, setWebsite] = useState();
  const [updateTeamReference, setUpdateTeamReference] = useState();
  const [updateTeamAbout, setUpdateTeamAbout] = useState();
  const [teamPic, setTeamPic] = useState();
  const [picName, setPicName] = useState();

  const [selectedTeamError, setSelectedTeamError] = useState(false);
  const [managingPositionError, setManagingPositionError] = useState(false);
  const [updateManagingPositionError, setUpdateManagingPositionError] = useState(false);
  const [updateTeamLeagueError, setUpdateTeamLeagueError] = useState();

  const toggleLookingForGames = (event, newValue) => {
    setLookingForGames(newValue);
  }

  const toggleSalaryNegotiable = (event, newValue) => {
    setSalaryNegotiable(newValue);
  }

  const openAddTeamSection = () => {
    setAddTeamSection(true);
  }

  const getUserTeams = () => {
    getTeams().then((response) => {
      setTeamList(response.data.teams);
    }).catch(e => {
      console.log(e);
    });
  }

  const getApplication = () => {
    getTeamManagerApplication().then((response) => {
      if(response.data.status === "ERROR"){
        console.log(response.data.message);
      }

      setTeam(response.data.team[0]);
      setTeamId(response.data.team[0].team_id);
      setUpdateTeamLevel(response.data.team[0].level);
      setUpdateManagingPosition(response.data.team[0].position);
      setUpdateTeamGender(response.data.team[0].gender);
      setUpdateTeamSeasonFrom(months[response.data.team[0].season_period_start_month - 1]);
      setUpdateTeamSeasonTo(months[response.data.team[0].season_period_end_month - 1]);
      setLookingForGames(response.data.team[0].looking_for_games);
      setUpdateTeamCar(response.data.team[0].car);
      setUpdateTeamPhone(response.data.team[0].phone);
      setUpdateTeamReturnFlight(response.data.team[0].return_flight);
      setUpdateTeamMeal(response.data.team[0].daily_meal);
      setUpdateTeamGym(response.data.team[0].gym_membership);
      setUpdateTeamInsurance(response.data.team[0].insurance);
      setUpdateTeamAccommodation(response.data.team[0].accomodation);
      setUpdateTeamOtherBenefits(response.data.team[0].other_benefits);
      setTwitter(response.data.team[0].twitter);
      setFacebook(response.data.team[0].facebook);
      setInstagram(response.data.team[0].instagram);
      setLinkedin(response.data.team[0].linkedin);
      setWebsite(response.data.team[0].website);
      setUpdateTeamReference(response.data.team[0].reference);
      setUpdateTeamAbout(response.data.team[0].about);
      setUpdateTeamLeague(response.data.team[0].league_id);
      setSalaryFrom(response.data.team[0].salary_range_lower_limit);
      setSalaryTo(response.data.team[0].salary_range_upper_limit);
      setSalaryNegotiable(response.data.team[0].salary_is_negotiable);
    }).catch(e => {
      console.log(e);
    });
  };

  const removeUserTeamApplication = (id) => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      removeTeamApplication(id).then((response) => {
        window.location.reload()
      }).catch(e => {
        console.log(e);
      });
    }
  };

  const resendInvitation = (application_id) => {
    sendApprovalEmail(application_id).then((response) => {
      if(response.data.status === "SUCCESS") alert("Email sent!")
    }).catch(e => {
      console.log(e);
    });
  };

  const closeAddTeamSection = () => {
    setAddTeamSection(false);
  };

  const handleUploadPic = async (event) => {
    let file = event.target.files[0];
    let pic =  await toBase64(file);

    if(!checkExtension(file.name)){
      alert("Only jpg and png allowed");
      return;
    }
    
    if(file.size > 500000){
      alert("Image too big");
      return;
    }

    setTeamPic(pic);
    setPicName(file.name);
  };

  const sendTeamApplication = () => {
    let hasError=false;
    
    if(!selectedTeam){
      setSelectedTeamError(true);
      hasError=true;
    }

    if(!managingPosition){
      setManagingPositionError(true);
      hasError=true;
    }

    if(hasError) return;

    let teamApplication = {
      team: selectedTeam,
      position: managingPosition,
    };

    saveTeamApplication(teamApplication).then((response) => {
      if(response.data.status === "ERROR"){
        alert(response.data.message);
      }
      closeAddTeamSection(false);
      getApplication();
      
    }).catch(e => {
      console.log(e);
    });
  };

  const updateTeam = () => {
    let hasError = false;

    if(!updateManagingPosition){
      setUpdateManagingPositionError(true);
      hasError = true;
    }

    if(!updateTeamLeague){
      setUpdateTeamLeagueError(true);
      hasError = true;
    }

    if(hasError) return;

    let updateTeamData = {
      league: updateTeamLeague,
      team_id: teamId,
      level: updateTeamLevel,
      position: updateManagingPosition,
      gender: updateTeamGender,
      seasonFrom: updateTeamSeasonFrom,
      seasonTo: updateTeamSeasonTo,
      lookingForGames: lookingForGames,
      car: updateTeamCar,
      phone: updateTeamPhone,
      returnFlight: updateTeamReturnFlight,
      meal: updateTeamMeal,
      gym: updateTeamGym,
      insurance: updateTeamInsurance,
      accommodation: updateTeamAccommodation,
      otherBenefits: updateTeamOtherBenefits,
      twitter: twitter,
      instagram: instagram,
      facebook:facebook,
      linkedin: linkedin,
      website: website,
      logo: teamPic,
      reference: updateTeamReference,
      about: updateTeamAbout,
      expectedSalaryUpper: salaryFrom,
      expectedSalaryLower: salaryTo,
      salaryNegotiable: salaryNegotiable,
    };

    updateTeamApplication(updateTeamData).then((response) => {
      if(response.data.status === "ERROR"){
        alert(response.data.message);
      }else{
        alert("Team application updated");
      }
      
      getApplication();

    },[getApplication]).catch(e => {
      console.log(e);
    });
  };

  useEffect(() => {
    getUserTeams();
    getApplication();

    getLeagues().then((response) => {
      setLeaguesList(response.data.leagues)
    }).catch(e => {
      console.log(e);
    });
  }, [history]);

  return (
    <>
      <div className={classes.content}>
        <Menu />
        <div className={classes.mainContainer}>
          <div className={classes.profileContainer}>

            <form className={classes.form} noValidate autoComplete="off">
              <FormControl className={classes.label}>
                <span>Join an existing team</span>
              </FormControl>

              <div className={classes.inputsWrapper}>
                <div className={classes.addButton} onClick={openAddTeamSection}>
                  <span>+</span>
                  ADD
                </div>
              </div>
            </form>
          </div>

          {
          (team) ?
            <>
            <div className={classes.teamsSection}>
              <div style={{justifyContent: "space-between"}}>
                <span>{team.name}</span>
                <span>{team.league}</span>
                <span>{team.position}</span>
                {
                  (team.status === 0) ?
                  <>
                    <span>Pending Approval</span>
                    <span className={classes.siteLink} onClick={() => resendInvitation(team.id)}>Resend Invitation</span>
                  </>
                  : null
                }  
                <span className={classes.siteLink} onClick={() => removeUserTeamApplication(team.id)}>Remove</span>
              </div>
              {
              (team.status === 1) ?
              <div className={classes.updateTeamContent}>
                <form className={classes.form} noValidate autoComplete="off">
                  <div className={classes.updateTeamContainer}>
                    <FormControl style={{flexDirection: "column"}}>
                      <label>What is your managing position for this team?</label>
                      <select className={(!updateManagingPositionError)?classes.input + " " + classes.select:classes.input + " " +classes.select + " " + classes.inputError} value={updateManagingPosition} onChange={e => setUpdateManagingPosition(e.target.value)}>
                      <option value=""></option>;
                        {
                          MANAGING_POSITIONS.map(position => {
                            return <option value={position}>{position}</option>;
                          })
                        }
                      </select>
                      </FormControl>
                  </div>
                </form>
                <div className={classes.dashedLine}></div>
                <Typography className={classes.title}>Edit your Team's Profile</Typography>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>League</label>
                        <select className={(!updateTeamLeagueError) ? classes.input + " " +classes.select :classes.input + " " +classes.select +" " + classes.inputError } value={updateTeamLeague} onChange={e => setUpdateTeamLeague(e.target.value)}>
                          <option value=""></option>
                          {
                          leaguesList.map(league => {
                            return <option key={league.id} value={league.id}>{league.full_name}</option>
                          })
                        }
                        </select>
                        </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Team Level</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamLevel} onChange={e => setUpdateTeamLevel(e.target.value)}>
                        <option value=""></option>
                        {
                          LEVELS.map(level => {
                            return <option key={level} value={level}>{level}</option>
                          })
                        }
                        </select>
                        </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Team Gender</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamGender} onChange={e => setUpdateTeamGender(e.target.value)}>
                          <option value=""></option>
                          <option value="m">Male</option>
                          <option value="f">Female</option>
                        </select>
                        </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer} style={{justifyContent: "normal"}}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Season From</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamSeasonFrom} onChange={e => setUpdateTeamSeasonFrom(e.target.value)}>
                          <option value=""></option>
                          {
                            MONTHS.map(month => {
                              return <option key={month} value={month}>{month}</option>
                            })
                          }
                        </select>
                      </FormControl>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Season To</label>
                          <select className={classes.input + " " +classes.select} value={updateTeamSeasonTo} onChange={e => setUpdateTeamSeasonTo(e.target.value)}>
                            <option value=""></option>
                            {
                            MONTHS.map(month => {
                              return <option key={month} value={month}>{month}</option>
                            })
                          }
                          </select>
                      </FormControl>
                      </div>
                    </form>
                    <form className={classes.form} noValidate autoComplete="off">
                      <div className={classes.updateTeamContainer}>
                      <FormControl>
                        <label style={{lineHeight:"3",paddingRight: "5px"}}>Is the team looking for games?</label>
                        <FormControlLabel
                        control={<CustomSwitch checked={lookingForGames} value={lookingForGames} onChange={toggleLookingForGames} />}
                        label={(lookingForGames ? "Yes" : "No")}
                        />
                      </FormControl>
                    </div>
                    </form>
                    <form className={classes.form} noValidate autoComplete="off">
                      <div className={classes.updateTeamContainer}>
                        <FormControl  style={{flexDirection: "column"}}>
                          <label>Salary from</label>
                          <input type="number"
                          className={classes.input}
                          placeholder="From (USD)"
                          value={salaryFrom}
                          maxLength="10"
                          onChange={e => {
                            let { value, maxLength } = e.target;
                            let prev = value.slice(0,-1);
                            value = (value.length > Number(maxLength)) ? prev : value;
                            setSalaryFrom(value)
                          }}
                          />
                          </FormControl>
                          <FormControl  style={{flexDirection: "column"}}>
                            <label>Salary to</label>
                            <input type="number"
                            className={classes.input}
                            placeholder="To (USD)"
                            value={salaryTo}
                            maxLength="10"
                            onChange={e => {
                              let { value, maxLength } = e.target;
                              let prev = value.slice(0,-1);
                              value = (value.length > Number(maxLength)) ? prev : value;
                              setSalaryTo(value)
                            }}
                            />
                          </FormControl>
                        </div>
                    </form>
                    <form className={classes.form} noValidate autoComplete="off">
                      <div className={classes.updateTeamContainer}>
                      <FormControl>
                        <label style={{lineHeight:"3",paddingRight: "5px"}}>Is salary negotiable?</label>
                      <FormControlLabel
                        control={<CustomSwitch checked={salaryNegotiable} value={salaryNegotiable} onChange={toggleSalaryNegotiable} />}
                        label={(salaryNegotiable ? "Yes" : "No")}
                      />
                      </FormControl>
                      </div>
                    </form>
                  <div className={classes.dashedLine}></div>
                  <Typography className={classes.title}>Benefits offered</Typography>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer + " " + classes.benefitsSection}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Car</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamCar} onChange={e => setUpdateTeamCar(e.target.value)}>
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </FormControl>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Phone</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamPhone} onChange={e => setUpdateTeamPhone(e.target.value)}>
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer + " " + classes.benefitsSection}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Return flight</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamReturnFlight} onChange={e =>setUpdateTeamReturnFlight(e.target.value)}>
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </FormControl>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Daily meal</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamMeal} onChange={e =>setUpdateTeamMeal(e.target.value)}>
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </FormControl>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Gym membership</label>
                          <select className={classes.input + " " +classes.select} value={updateTeamGym} onChange={e =>setUpdateTeamGym(e.target.value)}>
                            <option value=""></option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer + " " + classes.benefitsSection}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Insurance</label>
                        <select className={classes.input + " " +classes.select} value={updateTeamInsurance} onChange={e =>setUpdateTeamInsurance(e.target.value)}>
                          <option value=""></option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </FormControl>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Accommodation</label>
                          <select className={classes.input + " " +classes.select} value={updateTeamAccommodation} onChange={e =>setUpdateTeamAccommodation(e.target.value)}>
                            <option value=""></option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer}  style={{flexDirection: "column", width: "90%"}}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Other benefits</label>
                        <input type="text" className={classes.input} placeholder="Dental plan, Medical Coverage" value={updateTeamOtherBenefits} onChange={e =>setUpdateTeamOtherBenefits(e.target.value)} />
                      </FormControl>
                    </div>
                  </form>
                  <div className={classes.dashedLine}></div>
                  <Typography className={classes.title}>Team's Social media</Typography>
                  <form className={classes.socialForm} noValidate autoComplete="off">
                    <FormControl className={classes.socialLogo}>
                      <img src={twitter_logo} alt="twitter logo"/>
                    </FormControl>
                    <div className={classes.inputsWrapper}>
                      <FormControl>
                        <input type="text" placeholder="https://twitter.com/username" className={classes.input} value={twitter} onChange={e => setTwitter(e.target.value)} />
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.socialForm} noValidate autoComplete="off">
                    <FormControl className={classes.socialLogo}>
                      <img src={instagram_logo} alt="instagram logo"/>
                    </FormControl>
                    <div className={classes.inputsWrapper}>
                      <FormControl>
                        <input type="text" placeholder="https://instagram.com/username" className={classes.input} value={instagram} onChange={e => setInstagram(e.target.value)} />
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.socialForm} noValidate autoComplete="off">
                    <FormControl className={classes.socialLogo}>
                      <img src={facebook_logo} alt="facebook logo"/>
                    </FormControl>
                    <div className={classes.inputsWrapper}>
                      <FormControl>
                        <input type="text" placeholder="https://facebook.com/username" className={classes.input} value={facebook} onChange={e => setFacebook(e.target.value)} />
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.socialForm} noValidate autoComplete="off">
                    <FormControl className={classes.socialLogo}>
                      <img src={linkedin_logo} alt="linkedin logo"/>
                    </FormControl>
                    <div className={classes.inputsWrapper}>
                      <FormControl>
                        <input type="text" placeholder="https://linkedin.com/username" className={classes.input} value={linkedin} onChange={e => setLinkedin(e.target.value)} />
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.socialForm} noValidate autoComplete="off">
                    <FormControl className={classes.socialLogo}>
                    <span className="material-icons">language</span>
                    </FormControl>
                    <div className={classes.inputsWrapper}>
                      <FormControl>
                        <input type="text" placeholder="Website" className={classes.input} value={website} onChange={e => setWebsite(e.target.value)} />
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer}  style={{flexDirection: "column"}}>
                      <FormControl style={{flexDirection:"column"}}>
                        <label>Upload team logo</label>
                        <div className={classes.inputsWrapper}>
                          <label className={classes.uploadPic}>
                            <input type="file" className={classes.fileInput} onChange={e => handleUploadPic(e)}/>
                            <span>Upload</span>
                            <br/>
                            {picName}
                          </label>
                        </div>
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer} style={{flexDirection: "column", width: "90%"}}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>Team Reference</label>
                        <input type="text" className={classes.input} placeholder="URL" value={updateTeamReference}  onChange={e => setUpdateTeamReference(e.target.value)}/>
                      </FormControl>
                    </div>
                  </form>
                  <form className={classes.form} noValidate autoComplete="off">
                    <div className={classes.updateTeamContainer} style={{flexDirection: "column", width: "90%"}}>
                      <FormControl style={{flexDirection: "column"}}>
                        <label>About</label>
                        <textarea rows="4" placeholder="Lorem ipsum..." value={updateTeamAbout} className={classes.input} onChange={e => setUpdateTeamAbout(e.target.value)}></textarea>
                      </FormControl>
                    </div>
                  </form>
                  <div className={classes.buttons}>
                    <span className={classes.button} onClick={updateTeam}>Save changes</span>
                  </div>
                </div>
                : null
            }
            </div>
            </>
          : null
          }

          {
            (addTeamSection) ?
              <div className={classes.addTeamSection}>
                <form className={classes.form} noValidate autoComplete="off">
                  <FormControl>
                    <label>Find Team</label>
                    <Autocomplete
                      freeSolo
                      className={(!selectedTeamError) ? classes.autoCompleteInput : classes.autoCompleteInput + " " + classes.inputError}
                      onChange={(event, newValue) => {
                        if(newValue){
                          setSelectedTeam(newValue.id);
                          setSelectedTeamName(newValue.name);
                          setSelectedTeamLocation(newValue.location);
                          setSelectedTeamDivision(newValue.division_name);
                        }
                      }}
                      options={teamList}
                      getOptionLabel={(option) => option.name}
                      value={selectedTeam}
                      renderInput={(params) => <TextField  {...params} />}
                    />
                  </FormControl>
                    
                  {
                    (selectedTeam) ?
                    <>
                      <div className={classes.selectedTeamSection}>
                        <span>{selectedTeamName}</span>
                        <span>{selectedTeamLocation}</span>
                        <span>{selectedTeamDivision}</span>
                        <span className={classes.siteLink} onClick={() => setSelectedTeam()}>Remove</span>
                      </div>
                      <FormControl>
                        <label>Position</label>
                        <select className={(!managingPositionError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setManagingPosition(e.target.value)}>
                          <option value=""></option>;
                          {
                            MANAGING_POSITIONS.map(position => {
                              return <option value={position}>{position}</option>;
                            })
                          }
                        </select>
                      </FormControl>
                    </>
                    : null
                  }
                </form>
                <div className={classes.buttons}>
                  <span onClick={closeAddTeamSection} className={classes.cancelButton}>Cancel</span>
                  <span className={classes.button} onClick={sendTeamApplication}>Submit for Confirmation</span>
                </div>
              </div>
              :null
          }
      </div>
      </div>
    </>
  );
}

export default EditTeam;