import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import 'material-icons/iconfont/material-icons.scss';

import { getTeam } from "../../../api/teams";
import { Posts } from "../components/posts";
import { spacing, colors, components, fonts } from "../../../constants/theme";
import { Videos } from "../components/videos";

import summaryIcon from "../../../assets/images/summary.svg";
import helmetIcon from "../../../assets/images/helmet.svg";
import mediaIcon from "../../../assets/images/media.svg";
import newsIcon from "../../../assets/images/news.svg";
import profileBannerBackground from "../../../assets/images/profile_banner_background.png";
import facebook_logo from "../../../assets/images/facebook_small.svg";
import twitter_logo from "../../../assets/images/twitter_small.svg";
import linkedin_logo from "../../../assets/images/linkedin_small.svg";
import instagram_logo from "../../../assets/images/instagram_small.svg";
import defaultTeamLogo from "../../../assets/images/team.png";
import defaultPlayerLogo from "../../../assets/images/player.png";

import downArrow from "../../../assets/images/down_arrow.png";
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';

const queryString = require("query-string");

const useStyles = makeStyles((theme)=>(
  {

    mainWrapper:{
      display: "block",
      width: "100%",
      position: "absolute",
      left: 0,
      marginBottom: "20px",
    },

    summarySection: {
      width: "80%",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  
    container: {
      display: "block",
      width: "80%",
      margin: "0 auto",
      '& svg': {
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        padding: `0 0 0 0`,
        margin: "0 0",
        width: "100%",
      },
    },
  
    topBanner: {
      width: "100%",
      height: "275px",
      display: "flex",
      alignItems: "center",
      backgroundImage: `url(${profileBannerBackground})`,
      backgroundSize: "cover",
      [theme.breakpoints.down("sm")]: {
        backgroundSize: "100% 50%",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      },
      "& > div":{
        display: "flex",
        width: "50%",
        maxWidth: "1024px",
        height: "70%",
        margin: "0 auto",
        borderRadius: "10px",
        backgroundColor: "white",
        "& > div:nth-child(1)":{
          display: "flex",
          justifyContent: "center",
          width: "29%",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          overflow: "hidden",
          [theme.breakpoints.up("xl")]: {
            width: "20%",
          },
          [theme.breakpoints.down("sm")]: {
            width: "135px",
            height: "90px",
            borderRadius: "50%"
          },
          "& > img": {
            maxWidth: "100%",
            maxHeight: "100%",
          },
        },
        "& > div:nth-child(2)":{
          width: "55%",
          paddingLeft: "15px",
          paddingTop: "15px",
          "& > span":{
            display: "block",
            padding: "2px",
          },
          "& > span:nth-child(1)":{
            fontSize: "14px",
            fontWeight: fonts.subHeadingFontWeight,
          },
          "& > span:nth-child(2)":{
            color: colors.blueDeep,
            fontSize: "22px",
            fontWeight: fonts.headingFontWeight,
            textTransform: "uppercase",
          },
          "& > span:nth-child(3)":{
            color: colors.blue,
            fontSize: "14px",
            fontWeight: fonts.headingFontWeight,
            marginBottom: spacing.xxLarge,
            [theme.breakpoints.down("sm")]: {
              marginBottom: 'initial',
            },
          },
          "& > span:nth-child(4),span:nth-child(5)":{
            color:colors.greyDark,
            fontSize: "11px",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        },
        "& > div:nth-child(3)":{
          width: "25%",
          borderLeft: `1px solid ${colors.greyLight}`,
          [theme.breakpoints.down("sm")]: {
            width: "initial",
            borderLeft: "none"
          },
        },
        [theme.breakpoints.down("sm")]: {
          width: "90%",
          height: "initial",
          padding: spacing.small,
          margin: `130px ${spacing.small}px 20px`,
          border: `1px solid ${colors.greyLight}`,
          borderRadius: "10px",
          boxShadow: "0px 8px 12px rgba(68,68,68,0.1);",
        },
      },
    },
  
    menu: {
      width: "fit-content",
      backgroundColor: colors.greyLight,
      borderRadius: "20px",
      margin: "0 auto",
      textAlign: "center",
      display: "flex",
      fontSize: "12px",
      fontWeight: "bold",
      marginTop: spacing.xxLarge,
      marginBottom: spacing.xxLarge,
      "& > span":{
        padding: "10px 25px 10px 25px",
        color: colors.blue,
        "&:hover": {
          cursor: "pointer"
        },
      },
    },

    menuSelect: {
      width: "96%",
      marginRight: spacing.large,
      "& > select": {
        backgroundColor: "white",
        height: "35px",
        borderRadius: "2px",
        boxShadow: "inset 0 0 1px 0px #888",
        color: colors.grey,
        "&::placeholder":{
          color: colors.grey,
          paddingLeft: spacing.xSmall,
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: "16px",
        },
        paddingLeft: spacing.xSmall,
        margin: "0",
        "-webkitBoxSizing": "border-box",
        "-moz-box-sizing": "border-box",
        "box-sizing": "border-box",
        "-webkit-appearance": "none",
        "-moz-appearance": "none",
        backgroundColor: "white",
        background: `url(${downArrow})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "98% 50%",
        paddingLeft: spacing.xSmall,
        color: colors.grey,
      },
    },
  
    title: {
      ...components.commonTitle,
      marginBottom: spacing.xLarge,
      marginTop: spacing.large,
      display: "flex",
      "& > img":{
        marginRight: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      }
    },
  
    subTitle: {
      ...components.commonSubtitle,
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      }
    },
  
    smallTitle: {
      fontSize: "18px",
      paddingBottom: "10px",
      color: colors.blueGrey,
    },
  
    teamInfo: {
      display: "flex",
      marginBottom: spacing.xxLarge,
      width: "100%",
      "& a": {
        color:colors.blue
      },
      "& > div:nth-child(1)":{
        width: "30%",
        borderRight: `1px dashed ${colors.orange}`,
        paddingRight: "5px",
        "& > div": {
            display: "flex",
            marginBottom: spacing.medium,
            marginTop: spacing.small,
            fontSize: "12px",
            [theme.breakpoints.down("sm")]: {
              overflowWrap:"anywhere"
            },
          "& > span":{
            display: "flex",
            alignItems: "center",
            "& img":{
              marginRight: "8px",
            },
          },
          "& > span:nth-child(1)":{
            color: colors.blueGrey,
            fontWeight: fonts.headingFontWeight,
            width: "125px",
          },
          "& > span:nth-child(2)":{
            float:"right",
            marginLeft: "30px",
            display: "block",
            lineBreak: "auto",
            [theme.breakpoints.down("xs")]: {
              width: "50%"
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          width: "initial",
          borderRight: `none`,
        }
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      "& > div:nth-child(2)":{
        width: "70%",
        marginLeft: "20px",
        [theme.breakpoints.down("sm")]: {
          width: "initial",
          marginLeft: 0,
        },
    },
  },
  
  awardsAndExperience: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: spacing.xSmall,
    marginBottom: spacing.large,
    "& > div": {
      display: "flex",
      flex: "0 30%",
      flexDirection: "column",
      fontSize: "12px",
      marginBottom: spacing.medium,
      "& > span":{
        display: "block",
      },
      "& > span:nth-child(1)":{
        fontSize: "11px",
      },
      "& > span:nth-child(3)":{
        color: colors.grey,
        fontSize: "11px",
      },
    },
  },
  
  roster: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: spacing.small,
    marginBottom: spacing.large,
    "& > div": {
      cursor: "pointer",
      display: "flex",
      flex: "0 31.5%",
      maxWidth: "330px",
      fontSize: "12px",
      marginBottom: spacing.medium,
      marginRight: spacing.small,
      border: `1px solid ${colors.greyLight}`,
      borderRadius: "10px",
      "& > div:nth-child(1)": {
        "& > img": {
          width: "50px",
          height: "50px",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
          marginBottom: "-3px",
        },
      },
      "& > div:nth-child(2)": {
        width: "60%",
        position: "relative",
        "&::before":{
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: "auto",
            left: "-3px",
            zIndex: 0,
            width: "10px",
            height: "52px",
            backgroundColor: "rgb(255, 255, 255)",
            transform: "skew(-6deg)",
            [theme.breakpoints.down("sm")]: {
              right: "188px",
            },
        },
        "& > span":{
          display: "block",
          paddingLeft: spacing.xSmall,
        },
        "& > span:nth-child(1)":{
          fontSize: "13px",
          paddingTop: spacing.xSmall,
          color: colors.blueDeep,
        },
        "& > span:nth-child(3)":{
          color: colors.grey,
          fontSize: "9px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flex: "0 100%",
      },
    },
  },
  
  benefits: {
    backgroundColor: colors.greyLight,
    borderRadius: "15px",
    padding: "15px",
    fontSize: "12px",
    color: colors.blueGrey,
    "& > div:nth-child(2)":{
      display: "flex",
      width: "100%",
      marginBottom: spacing.medium,
      "& div":{
        "& > div":{
          marginBottom: spacing.small,
          "& > span:nth-child(1)":{
            display: "inline-block",
            width: "120px",
            marginRight: spacing.xSmall,
          },
          "& > span:nth-child(2)":{
            color: colors.blueDeep,
          },
        },
      },
      "& > div:nth-child(1)":{
        marginRight: spacing.xxxxLarge,
      },
      "& > div:nth-child(2)": {
        "& > div:last-child":{
          display: "flex",
          flexDirection: "column",
          "& > span:nth-child(1)":{
            fontWeight: "bold",
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "initial",
      marginRight: "initial"
    },
  },
  
  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    margin: "5px 0",
  },
  
  additionalDetails: {
    display: "block !important",
    width: "100%",
  },
  
  noMediaFound: {
    fontSize: "18px",
    color: colors.grey,
    margin: "10px 0px 15px 0px",
  },

  noData: {
    fontSize: "18px",
    color: colors.grey,
    marginBottom: spacing.medium,
  },
  }
));

const TeamProfile = (props) => {
  const classes = useStyles();

  let params = queryString.parse(window.location.search);
  
  const [logo, setLogo] = useState('profilepic.jpeg'); 
  const [name, setName] = useState();
  const [league, setLeague] = useState();
  const [level, setLevel] = useState();
  const [location, setLocation] = useState();
  const [lookingForGames, setLookingForGames] = useState();
  const [season, setSeason] = useState();
  const [website, setWebsite] = useState();
  const [facebook, setFacebook] = useState();
  const [instagram, setInstagram] = useState();
  const [twitter, setTwitter] = useState();
  const [linkedin, setLinkedin] = useState();
  const [about, setAbout] = useState();
  const [salaryNegotiable, setSalaryNegotiable] = useState();
  const [car, setCar] = useState();
  const [phone, setPhone] = useState();
  const [returnFlight, setReturnFlight] = useState();
  const [dailyMeal, setDailyMeal] = useState();
  const [insurance, setInsurance] = useState();
  const [gym, setGym] = useState();
  const [accommodation, setAccommodation] = useState();
  const [salaryUpper, setSalaryUpper] = useState();
  const [salaryLower, setSalaryLower] = useState();
  const [otherBenefits, setOtherBenefits] = useState();

  const [players, setPlayers] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [managers, setManagers] = useState([]);
  
  const [awards, setAwards] = useState([]);
  const [videoList, setVideoList] = useState([]);

  const [reelsCount, setReelsCount] = useState(0);
  const [highlightsCount, setHighlightsCount] = useState(0);

  const summaryRef = useRef();
  const newsRef = useRef();
  const mediaRef = useRef();
  const SECTIONS = [{name:"SUMMARY", link:"#summary", ref:summaryRef},{name:"MEDIA", link:"#media", ref:mediaRef},{name:"NEWS", link:"#news", ref:newsRef}]

  const handleAnchorClick = (anchor) =>{
    anchor.current.scrollIntoView({ behavior: 'smooth' });
  }

  const getTeamInfo = async (team_id) => {
    getTeam(team_id).then(response => {
      setLogo(response.data.team.logo);
      setName(response.data.team.name);
      setLeague(response.data.team.league);
      setLevel(response.data.team.level);
      setLocation(response.data.team.region + ", " + response.data.team.country_code);
      setLookingForGames(response.data.team.looking_for_games);
      setSeason(response.data.team.season_period);
      setWebsite(response.data.team.website);
      setFacebook(response.data.team.facebook);
      setInstagram(response.data.team.instagram);
      setTwitter(response.data.team.twitter);
      setLinkedin(response.data.team.linkedin);
      setVideoList(response.data.videos);
      setAbout(response.data.team.about);
      setSalaryNegotiable(response.data.team.salary_is_negotiable);
      setCar(response.data.team.car);
      setPhone(response.data.team.phone);
      setReturnFlight(response.data.team.return_flight);
      setDailyMeal(response.data.team.daily_meal);
      setInsurance(response.data.team.insurance);
      setGym(response.data.team.gym);
      setAccommodation(response.data.team.accommodation);
      setSalaryLower(response.data.team.salary_range_lower_limit);
      setSalaryUpper(response.data.team.salary_range_upper_limit);
      setOtherBenefits(response.data.team.other_benefits);
      setPlayers(response.data.players);
      setCoaches(response.data.coaches);
      setManagers(response.data.managers);
      setAwards(response.data.awards);
    }).catch(e => {
      console.log(e);
    });
  };

  const goToProfile = (slug) => {
    window.location.href = `/profile/user_profile?user=${slug}`;
  };

  useEffect(() => {
    getTeamInfo(params.team);
    
  }, []);

  const isMobile = isWidthDown('sm', props.width);
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.topBanner}>
        <div>
          <div>
            <img src={(logo)?`${process.env.REACT_APP_API_ENDPOINT}/${logo}`:defaultTeamLogo} alt="user" />
          </div>
          <div>
            <span>{level}</span>
            <span>{name}</span>
            <span>{league}</span>
          </div>
        </div>
      </div>
      <div className={classes.summarySection}>
        {isMobile?
          <div className={classes.menuSelect}>
            <select className={classes.menuSelect} onChange={e => {
              let section = SECTIONS.find(o => o.link === e.target.value);
              handleAnchorClick(section.ref);
              return false;
            }}>
              <option value="">Go To</option>
              {
                SECTIONS.map(section => {
                  return <option key={section.link} value={section.link}>{section.name}</option>
                })
              }
            </select>
          </div>
          :
          <div className={classes.menu}>
            {
              SECTIONS.map(section => {
                return <span key={section.name} onClick={()=>handleAnchorClick(section.ref)}>{section.name}</span>
              })
            }
          </div>
        }
        
        <Typography ref={summaryRef} className={classes.title} name="summary" id="summary"><img src={summaryIcon} alt="summary icon"/>SUMMARY</Typography>
        <div className={classes.teamInfo}>
          <div>
            <Typography className={classes.subTitle}>TEAM DETAILS</Typography>
            <div><span>League:</span><span>{league}</span></div>
            <div><span>Level:</span><span>{level}</span></div>
            <div><span>Location:</span><span>{location}</span></div>
            <div><span>Looking for games:</span><span>{(lookingForGames)?"Yes":"No"}</span></div>
            <div><span>Season Period:</span><span>{season}</span></div>
            <div><span>Website:</span>
            {
                (website)?
                <span><a href={website} target="_blank" style={{color:colors.blue}}><span className="material-icons">language</span></a></span>
                :null
              }
            </div>
            <div><span>Social:</span>
              <span>
                {(facebook)?<a href={facebook}><img src={facebook_logo} alt="facebook" /></a>:null}
                {(twitter)?<a href={twitter}><img src={twitter_logo} alt="twitter" /></a>:null}
                {(linkedin)?<a href={linkedin}><img src={linkedin_logo} alt="linkedin" /></a>:null}
                {(instagram)?<a href={instagram}><img src={instagram_logo} alt="instagram" /></a>:null}
              </span>
            </div>
          </div>
          <div>
          <Typography className={classes.subTitle}>AWARDS</Typography>
            {
              (awards.length >= 1) ?
                <div>
                  <div className={classes.awardsAndExperience}>
                  
                  {
                    awards.map((award, index) => {
                      return (
                        <div key={index}>
                          <span>{award.league}</span>
                          <span>{award.name}</span>
                          <span>{award.year}</span>
                        </div>
                      )
                    })
                  }
                  
                  </div>
                </div>

              : <div className={classes.noData}>No awards</div>
            }

            <div className={classes.benefits}>
              <Typography className={classes.subTitle}>BENEFITS</Typography>
              <div>
                <div>
                  <div>
                    <span>Salary Range:</span>
                    {
                      (salaryNegotiable === 1) ?
                      <span>Negotiable</span>
                    :<span>{salaryUpper}-{salaryLower}</span>
                    }
                  </div>
                  <div><span>Car:</span><span>{(car === 1) ? "Yes":"No"}</span></div>
                  <div><span>Phone:</span><span>{(phone === 1) ? "Yes":"No"}</span></div>
                  <div><span>Return Flight:</span><span>{(returnFlight === 1) ? "Yes":"No"}</span></div>
                  <div><span>Daily Meal:</span><span>{(dailyMeal === 1) ? "Yes":"No"}</span></div>
                </div>
                <div>
                  <div><span>Gym membership:</span><span>{(gym === 1) ? "Yes":"No"}</span></div>
                  <div><span>Insurance:</span><span>{(insurance === 1) ? "Yes":"No"}</span></div>
                  <div><span>Accommodation:</span><span>{(accommodation === 1) ? "Yes":"No"}</span></div>
                  <div><span>Other Benefits:</span><span>{otherBenefits}</span></div>
                </div>
              </div>
              <div className={classes.additionalDetails}>
              <Typography className={classes.smallTitle}>Additional details</Typography>
                {about}
              </div>
            </div>
          </div>
        </div>
        <Typography className={classes.title}><img src={helmetIcon} alt="summary icon"/>TEAM</Typography>
        {
          (players.length >= 1) ?
            <>
              <Typography className={classes.subTitle}>ROSTER</Typography>
              <div className={classes.roster}>
                {
                  players.map((player, index) => {
                    return (
                      <div key={index} onClick={e=>goToProfile(player.slug)}>
                        <div>
                          <img src={(player.profile_picture)?`${process.env.REACT_APP_API_ENDPOINT}/${player.profile_picture}`:defaultPlayerLogo} />
                        </div>
                        <div>
                          <span>{player.first_name} {player.last_name}</span>
                          <span className={classes.dashedLine}></span>
                          <span>{player.playing_position}</span>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </>
            : null
        }

        {
          (coaches.length >= 1) ?
            <>
              <Typography className={classes.subTitle}>COACHES</Typography>
              <div className={classes.roster}>
                {
                  coaches.map((coach, index) => {
                    return (
                      <div key={index} onClick={e=>goToProfile(coach.slug)}>
                        <div>
                          <img src={(coach.profile_picture)?`${process.env.REACT_APP_API_ENDPOINT}/${coach.profile_picture}`:defaultPlayerLogo} alt="coach" />
                        </div>
                        <div>
                          <span>{coach.first_name} {coach.last_name}</span>
                          <span className={classes.dashedLine}></span>
                          <span>{coach.playing_position}</span>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </>
            : null
        }

        {
          (managers.length >= 1) ?
            <>
              <Typography className={classes.subTitle}>MANAGERS</Typography>
              <div className={classes.roster}>
                {
                  managers.map((manager, index) => {
                    return (
                      <div key={index} onClick={e=>goToProfile(manager.slug)}>
                        <div>
                          <img src={(manager.profile_picture)?`${process.env.REACT_APP_API_ENDPOINT}/${manager.profile_picture}`:defaultPlayerLogo} alt="manager" />
                        </div>
                        <div>
                          <span>{manager.first_name} {manager.last_name}</span>
                          <span className={classes.dashedLine}></span>
                          <span>{manager.position}</span>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </>
            : null
        }
        
        <Typography ref={mediaRef} className={classes.title} name="media" id="media"><img src={mediaIcon} alt="media icon" />MEDIA</Typography>
        <Typography className={classes.subTitle}>TEAM'S  REEL ({reelsCount})</Typography>
        {
          (videoList.length  > 0) ?
            <Videos videoList={videoList} type={1} getCount={e=>setReelsCount(e)}/>
            :<div className={classes.noMediaFound}>No media found</div>
        }
        <Typography className={classes.subTitle}>GAME HIGHLIGHTS ({highlightsCount})</Typography>
        {
          (videoList.length  > 0) ?
            <Videos videoList={videoList} type={2} getCount={e=>setHighlightsCount(e)}/>
            : <div className={classes.noMediaFound}>No media found</div>
        }
        <Typography ref={newsRef} className={classes.title} name="news" id="news"><img src={newsIcon} alt="news icon" />IN THE NEWS</Typography>
        {
          (name) ?
            <Posts search={name}/>
          :null
        }
      </div>
    </div>
  );
};

export default withWidth()(TeamProfile);
 
