import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./home";
import Dashboard from "./dashboard";
import Onboarding from "./onboarding";
import Profile from "./profile";
import Admin from "./admin";
import NotFound from "./notFound";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import { PATH_DASHBOARD, PATH_ONBOARDING, PATH_PROFILE, PATH_ADMIN, HOME_ROUTES } from "../constants/routes";
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import { verifyToken } from "../api/authentication";
import { colors } from  "../constants/theme";

const useStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    maxWidth: "2056px",
    margin: "0 auto",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    }
  },
}));

const App = () => {
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState();
  useEffect(() => {

    verifyToken().then(response => {
      if(!response){
        setIsLoggedIn(false);
        localStorage.clear();
        return;
      }

      if(response.data.status === "SUCCESS"){
        setIsLoggedIn(true);
        localStorage.setItem("loggedIn","true");
      }else{
        setIsLoggedIn(false);
        localStorage.clear();
      }
    }).catch(err => {
      setIsLoggedIn(false);
      localStorage.clear();
    });
  }, []);

  return (
    <Router>
      <div className={classes.app}>
      {
        (typeof isLoggedIn !== 'undefined') ?
        <Switch>
          <PublicRoute isLoggedIn={isLoggedIn} restricted={false} component={Home} path={'/'} exact />
          <PublicRoute isLoggedIn={isLoggedIn} restricted={true} component={Home} path={HOME_ROUTES.login} exact />
          <PublicRoute isLoggedIn={isLoggedIn} restricted={true} component={Home} path={HOME_ROUTES.forgotPassword} exact />
          <PublicRoute isLoggedIn={isLoggedIn} restricted={true} component={Home} path={HOME_ROUTES.resetPassword} exact />
          <PublicRoute isLoggedIn={isLoggedIn} restricted={false} component={Onboarding} path={PATH_ONBOARDING} />
          <PrivateRoute isLoggedIn={isLoggedIn} component={Dashboard} path={PATH_DASHBOARD} />
          <PrivateRoute isLoggedIn={isLoggedIn} component={Profile} path={PATH_PROFILE} />
          <PrivateRoute isLoggedIn={isLoggedIn} component={Admin} path={PATH_ADMIN} />
          
          {/*We need these routes so we can get the header and footer from the WP site*/}
          <Route exact path="/header"><Header /></Route>
          <Route exact path="/footer"><Footer /></Route>

          <Route component={NotFound}/>     
        </Switch>
        :null
      }
      </div>
    </Router>
  );
}

export default App;
