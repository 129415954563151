import API from "./api";

const getEducation = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getEducation?token=${token}`);
};

const removeUserEducation = async (education_id) => {
  let token = localStorage.getItem("token");
  let data = {education_id:education_id, token:token};
  return await API.post("/removeEducation", data);
};

const saveUserEducation = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/saveEducation", data);
};

const getSchools = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getSchools?token=${token}`);
};

export {
  getEducation,
  saveUserEducation,
  removeUserEducation,
  getSchools,
};