import API from "./api";

const getGames = async (data) => {
  let token = localStorage.getItem("token");
  return await API.get(`/getGames?token=${token}`);
};

const searchGames = async (data) => {
  let token = localStorage.getItem("token");
  return await API.get(`/searchGames?token=${token}&date=${data.date}&country=${data.country}`);
};

export {
  getGames,
  searchGames,
};