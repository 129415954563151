import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { spacing, colors } from  "../../../constants/theme";
import teamsIcon from "../../../assets/images/teams.svg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: props => (props.isMobile===1)?"65%":"21%",
    height: "100%",
    padding: "10px 15px",
    backgroundColor: "white",
    border: `1px solid ${colors.greyDark}`,
    borderRadius: "5px",
    "& > div":{
      display: "flex",
      fontSize: "12px",
      "& > span:last-child":{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "70%",
        textAlign: "right",
        marginRight: spacing.xSmall,
      },
      "& > img":{
        width: "45px",
        height: "40px",
        borderRadius: "5px",
        marginRight: spacing.xSmall,
      },
    },
    "& > div:nth-child(1)":{
      fontSize: "11px",
      color: colors.grey,
      whiteSpace: "nowrap",
      paddingBottom: "5px",
    },
    "& > div:nth-child(2)":{
      fontWeight: "bold",
      color: colors.blueGrey,
      width: "100%",
      "& > div":{
        "& > span:nth-child(1)":{color:colors.blueDeep},
        display:"flex",
        flexDirection: "column",
        width: "100%",
      },
    },
    "& > div:nth-child(3)":{
      fontWeight: "bold",
      color: colors.blueGrey,
      width: "100%",
      marginTop: spacing.small,
      "& > div":{
        "& > span:nth-child(1)":{color:colors.blueDeep},
        display:"flex",
        flexDirection: "column",
      },
    },
  },
  
  time: {
    display: "flex !important",
    flexDirection: "column",
    marginRight: '0px !important',
    "& > span": {
      display: "block",
    },
    "& > span:nth-child(1)": {
      color: colors.grey,
      fontSize: "11px",
    },
    "& > span:nth-child(2)": {
      fontSize: "16px",
    },
    "& > span:nth-child(3)": {
      color: colors.grey,
      fontSize: "8px",
    },
  },
});

export const GameCard = ({...props}) => {
  const classes = useStyles(props);

  const getDaySuffix = (date) => {
    const d = date.getDate();
    const sfx = ["th","st","nd","rd"];
    const val = d%100;

    return (sfx[(val-20)%10] || sfx[val] || sfx[0]);
  };

  const formatDate = (date, timezone) => {
    const d = new Date(date);
    const mo = d.toLocaleString('default', { month: 'short',timeZone:timezone });
    const da = d.toISOString('default', { day: '2-digit' }).split('T')[0].slice(-2);
    
    return `${mo}. ${da}${getDaySuffix(d)}`;
  };

  const tConvert = (time) => {
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) {
      time = time.slice (1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join ('');
  };

  return (
    <div className={classes.container}>
      <div>
        {
          (!props.futureGame) ?
          <>
            <span>{formatDate(props.gameDate)}</span>
            <span>Score</span>
          </> : null
        }
      </div>
      <div>
        <img src={(props.homeTeamLogo)?props.homeTeamLogo:teamsIcon} alt="home team"/>
        <div>
          <span>{props.homeTeam}</span>
          <span>{props.homeTeamMascot}</span>
        </div>
        
        <span>{(!props.futureGame)?props.homeTeamScore:null}</span>
      </div>
      <div>
        <img src={(props.awayTeamLogo)?props.awayTeamLogo:teamsIcon} alt="away team"/>
        <div>
          <span>{props.awayTeam}</span>
          <span>{props.awayTeamMascot}</span>
        </div>
        {
          (props.futureGame) ?
            <span className={classes.time}>
              <span>{formatDate(props.gameDate,props.timezone)}</span>
              <span>{tConvert(props.gameTime.slice(0,-3))}</span>
              <span>{props.timezone}</span>
            </span>
            :
            <span>{props.awayTeamScore}</span>
        }
      </div>
    </div>
  );
};