import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';

import { spacing, colors, components } from  "../constants/theme";
import { USER_TYPE } from "../constants/constants";
import searchIcon from  "../assets/images/search_icon.svg";
import searchIconGrey from  "../assets/images/search_icon_grey.png";
import { getCountries, getProfileImage } from "../api/users";
import defaultPlayerLogo from "../assets/images/player.png";
import primaryButton from "../assets/images/primary_button.png";
import primaryButtonHover from "../assets/images/primary_button_hover.png";
import menuPlayer from "../assets/images/menuPlayer.png";
import { ONBOARDING_ROUTES, DASHBOARD_ROUTES, PROFILE_ROUTES } from  "../constants/routes";
import newsIcon from "../assets/images/news.svg";
import gamesIcon from "../assets/images/games.svg";
import countriesIcon from "../assets/images/countries.svg";
import directoryIcon from "../assets/images/teams.svg";
import liveIcon from "../assets/images/live.svg";
import afiMobile from "../assets/images/afi_color_mobile_white.png";
import afiMobileColor from "../assets/images/afi_color_mobile.png";

JavascriptTimeAgo.addLocale(en);

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  header: {
    height: "80px",
    minHeight: "80px",
    backgroundColor: props => (props.transparent===1)?"transparent":colors.greyLight,
    display: "flex",
    flexDirection: "row-reverse",
    "& .nav-label": {
      display: "inline-block",
      paddingTop: "8px",
      paddingBottom: "8px",
      width: "100%",
      overflow: "visible",
      whiteSpace: "nowrap",
      paddingLeft: "16px",
    },
    "& .nav-login": {
      paddingLeft: "20px"
    },
    "& #country": {
      minWidth: "104px",
    },
    "& a:hover span": {
      background: "linear-gradient(90deg, #FFFB00 0%, rgba(255,253,0,0.31) 80%)"
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      maxWidth: "1600px",
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: "20px",
      position: "absolute",
      padding: "inherit",
      "& > a": {
        display: "flex",
        alignItems: "center",
        height: "100%",
        color: props => (props.transparent===1)?'white':colors.blue,
        textDecoration: "none",
        fontSize: "12px",
        fontWeight: "bold",
        margin: "0px 20px",
        "&:visited,&:link": {
          color: props => (props.transparent===1)?'white':colors.blue,
        },
      },
      "& > #directory:hover > #directoryMenu":{
        display: "block",
      },
      "& > #country:hover ~ #countriesMenu":{
        display: "flex",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mobileHeader: {
    display: "none",
    width: "100%",
    height: "80px",
    minHeight: "80px",
    backgroundColor: props => (props.transparent===1)?"transparent":colors.greyLight,
    justifyContent: "space-around",
    zIndex: 9998,
    "& > a":{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      overflow: "hidden",
    },
    "& > span":{
      display: "flex",
      alignItems: "center",
      "& > a":{
        textDecoration: "none",
        color: props => (props.transparent===1)?"white":colors.blue,
        fontSize: "12px",
        fontWeight: "bold",
        "& > openMenu":{
          color: `${colors.blue} !important`,
        },
      },
    },
    "& > #profileMobile": {
      height: "30px",
      width: "30px",
      backgroundColor: colors.greyLight,
      borderRadius: "50%",
      marginTop: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: props => (props.transparent===1)?"transparent":"white",
    },
  },

  mobileLogo: {
    height: "80px",
    width: "150px",
    backgroundImage: props => (props.transparent===1)?`url(${afiMobile})`:`url(${afiMobileColor})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      position: "absolute",
      left: "15px",
    },
  },

  mobileLogoColor: {
    backgroundImage: `url(${afiMobileColor}) !important`,
  },

  mobileMenuIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    margin: "0 15px",
    "& > div":{
      width: "30px",
      height: "4px",
      backgroundColor: props => (props.transparent===1 && !props.mobileMenu)?"white":colors.blue,
      margin: "2px 0",
      transition: "0.4s",
    },
    "&.close":{
      "& > div":{
        width: "24px",
        backgroundColor: colors.blue,
      },
      "& > div:nth-child(1)": {
        webkitTransform: "rotate(-45deg) translate(-4px, 3px)",
        transform: "rotate(-45deg) translate(-4px, 3px)",
      },
      "& > div:nth-child(2)": {opacity: 0},
      "& > div:nth-child(3)": {
        webkitTransform: "rotate(45deg) translate(-8px, -8px)",
        transform: "rotate(45deg) translate(-8px, -8px)",
      },
    },
  },

  mobileMenu: {
    display: "none",
    width: "100%",
    height: "auto",
    flexDirection: "column",
    backgroundColor: "white",
    "& > div":{
      display: "flex",
      flexDirection: "column",
      width: "90%",
      margin: "0 auto",
      backgroundColor: "white",
    },
    "& > a":{
      padding: "10px 0 10px 20px",
      textDecoration: "none",
      color: colors.blue,
      fontWeight: "bold",
    },
    "& > ul":{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      width: "90%",
      margin: "0 auto",
      marginTop: spacing.small,
      padding: 0,
      "& > li":{
        listStyle: "none",
        margin: 0,
	      padding: "10px 0 10px 0px",
	      listStyle: "none",
	      backgroundRepeat: "no-repeat",
	      backgroundPosition: "left center",
	      backgroundSize: "25px",
        "& > a":{
          textDecoration: "none",
          color: colors.blue,
          fontWeight: "bold",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      zIndex: 9999,
    },
  },

  mobileSearch: {
    position: "relative",
    display: "inline-block",
    margin: "0 auto",
    "& > span": {
      position: "absolute",
      left: "auto",
      right: "10px",
      top: "10px",
    },
    "& > input":{
      backgroundColor: "white",
      height: "35px",
      width: "100%",
      color: colors.grey,
      border: "none",
      "&::placeholder":{
        paddingLeft: spacing.xSmall,
        color: colors.grey,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      paddingLeft: spacing.xSmall,
    },
  },

  subMenu: {
    "&:hover":{
      display: "block",
    },
    display: "none",
    position: "absolute",
    backgroundColor: "white",
    height: "150px",
    width: "auto",
    flexGrow: 1,
    padding: "20px",
    borderRadius: "10px",
    top: "80px",
    boxShadow: "0px 0px 15px 5px rgba(171,177,192,0.5)",
    "& > div:nth-child(1)":{
      fontSize: "20px",
      fontWeight: "bold",
      color: colors.blueGrey,
      marginTop: spacing.xSmall,
    },
    "& > div":{
      display: "flex",
      flexWrap: "wrap",
      marginTop: spacing.medium,
      width: "auto",
      fontSize: "13px",
      fontWeight: "bold",
      color: colors.blue,
      justifyContent: "space-between",
      "& > a": {
        color: colors.blue,
        textDecoration: "none",
        marginBottom: "10px",
        "&:visited,&:link": {
          color:colors.blue,
        },
      },
    },
  },

  menuSubtitle: {
    ...components.commonSubtitle,
    marginTop: spacing.xSmall,
    height: "fit-content",
  },

  headerRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    right: 0,
    background: props => (props.transparent===1)?"transparent":"linear-gradient(100deg, transparent 15%, #E5E5E9 10%, transparent)",
    height: "80px",
    marginLeft: "auto",
    paddingLeft: "5%",
    "& span:nth-child(1)":{
      cursor: "pointer",
    },
    "& a":{
      marginRight: spacing.medium,
      textDecoration: "none",
      fontSize: "13px",
      fontWeight: "bold",
      color: props => (props.transparent===1)?'white':colors.blue,
      "&:visited,&:link": {
        color: props => (props.transparent===1)?'white':colors.blue,
      },
      "& > img":{
        backgroundColor: colors.greyLight,
      },
    },
    "& > #profile": {
      height: "40px",
      width: "40px",
      backgroundColor: colors.greyLight,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      marginRight: "20px",
    },
    "& > #profile:hover ~ #profileMenu":{
      display: "flex !important",
    },
    "& > #register":{
      "& > a":{
      color: props => (props.transparent===1)?`${colors.blueDark} !important`:`${colors.blue} !important`,
      },
      "& > a:hover":{
        color: `${colors.blue} !important`,
      },
    },
    "& > #register:hover":{
      "& > a":{
        color: `${colors.blue} !important`,
      },
    },
    "& > #register:hover ~ #registerMenu":{
      display: "flex",
    },
  },

  countriesMenu: {
    zIndex: "9999",
    "&:hover":{
      display: "flex !important",
    },
    left: "20% !important",
    height: "auto !important",
    padding: "0px !important",
    "& > div:nth-child(1)":{
      width: "40%",
      height: "248px",
      maxHeight: "248px",
      padding: "20px",
      "& > div:nth-child(1)":{
        fontSize: "20px",
        fontWeight: "bold",
        color: colors.blueGrey,
      },
      "& > div:nth-child(2)":{
        display: "flex",
        flexDirection: "column",
        paddingTop: spacing.medium,
        "& > a":{
          paddingBottom: spacing.small,
          textDecoration: "none",
          fontSize: "14px",
          "&:visited,&:link": {
            color:colors.blue,
          },
        },
      },
    },
    "& > div:nth-child(2)":{
      width: "50%",
      backgroundColor: colors.greyLight,
      padding: "28px 20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: 0,
      "& > div:nth-child(2)":{
        display: "flex",
        flexDirection: "column",
        paddingTop: spacing.medium,
        height: "248px",
        maxHeight: "248px",
        overflowY: "scroll",
        "& > a":{
          paddingBottom: spacing.small,
          textDecoration: "none",
          fontSize: "14px",
          "&:visited,&:link": {
            color:colors.blue,
          },
        },
      },
    },
  },

  directoryMenu: {
    zIndex: "9999",
    position: "absolute",
    height: "auto",
    "& > div":{
      flexDirection: "column",
    },
  },

  registerMenu: {
    "&:hover":{
      display: "flex",
    },
    width: "1024px",
    height: "370px",
    right: "5%",
    position: "fixed",
    padding: "0px !important",
    "& > div:nth-child(1)":{
      fontSize: "12px",
      "& > div > span":{
        display: "block",
        color: "white",
      },
      "& > div:nth-child(1)":{
        color: "white",
        fontSize: "20px",
      },
      "& > div:nth-child(2)":{
        color: "white",
        display: "flex",
        fontSize: "12px",
        "& > div:nth-child(1)":{
          marginRight: "40px",
        },
        "& >  div":{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          "& a": {
            fontSize: "12px",
            position: "absolute",
            width: "100%",
            height: "100%",
          },
          "& span":{
            fontWeight: "normal",
            marginTop: "20px",
            lineHeight: 1.5,
          },
        },
      },
      "& > div:nth-child(3)":{
        color: "white",
        display: "flex",
        fontSize: "12px",
        "& > div:nth-child(1)":{
          marginRight: "40px",
        },
        "& >  div":{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          "& a": {
            fontSize: "12px",
            position: "absolute",
            width: "100%",
            height: "100%",
          },
          "& span":{
            fontWeight: "normal",
            marginTop: "20px",
            lineHeight: 1.5,
          },
        },
      },
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
      justifyContent: "flex-start",
      padding: "15px",
      backgroundColor: colors.blueDeep,
      color: "white",
      width: "70%",
      marginTop: "0px !important",
    },
    "& > div:nth-child(2)":{
      fontSize: "12px",
      padding: "15px",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "75px",
      cursor: "pointer",
      background: `url(${menuPlayer}) no-repeat`,
      backgroundSize: "45%",
      backgroundPosition: "bottom right",
      "& > a": {
        fontSize: "12px",
        position: "absolute",
        width: "100%",
        height: "100%",
      },
      "& span":{
        color: colors.blueGrey,
        marginTop: "20px",
        fontWeight: "normal",
        lineHeight: 1.5,
      },
    },
  },

  profileMenu: {
    "&:hover": {
      display: "flex !important",
    },
    width: "140px !important",
    height: "auto !important",
    top: "60px !important",
    "& > div":{
      display: "flex !important",
      flexDirection: "column",
      alignSelf: "flex-start",
      "& > a":{
        paddingBottom: spacing.small,
        marginBottom: 0,
        textDecoration: "none",
        fontSize: "14px",
        "&:visited,&:link": {
          color:colors.blue,
        },
      },
    },
  },

  search: {
    position: "relative",
    marginLeft:"auto",
    display: "flex",
    width: "260px",
    "& > span": {
      position: "absolute",
      right: "10px",
      top: "7px",
    },
    "& > input":{
      backgroundColor: "white",
      border: `1px solid ${colors.greyDark}`,
      height: "35px",
      width: "100%",
      minWidth: "130px",
      maxWidth: "260px",
      borderRadius: "2px",
      boxShadow: "inset 0 0 1px 0px #888",
      color: colors.grey,
      "&::placeholder":{
        paddingLeft: spacing.xSmall,
        color: colors.grey,
      },
      paddingLeft: spacing.xSmall,
    },
  },

  profileImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    borderRadius: "50%",
  },

  button: {
    "@media (max-width: 1120px) and (min-width: 769px)":{
      display: "none"
    },
    width: "200px",
    height: "45px",
    color: colors.blueDark,
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    margin: "0px auto",
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& a': {
      display: "inline-block",
      verticalAlign: "middle",
      fontSize: "13px",
      textDecoration: "none",
      paddingTop: "16px",
      marginRight: "0",
    },
  },
  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
  },
  mobileSubMenu: {
    borderBottom: "1px dashed " + colors.orange,
    marginBottom: "20px !important",
  },

  logo: {
    cursor: "pointer",
    backgroundImage: props => (props.transparent===1)?`url(${afiMobile})`:`url(${afiMobileColor})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    height: "80px",
    width: "150px",
    minWidth: "150px",
    marginLeft: "20px",
  },

  searchResults: {
    width: "98.5%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: 1,
    marginTop: "39px",
    border: `2px solid ${colors.grey}`,
    "& > div": {
      height: "60px",
      display: "flex",
      cursor: "pointer",
      zIndex: 9999,
      "& > div": {
        width: "100%",
        padding: "10px",
        display: "grid",
        flexDirection: "column",
        "& > span:nth-child(1)": {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          fontSize: "12px",
        },
        "& > span:nth-child(2)": {
          fontSize: "11px",
          color: colors.blueGrey,
        },
      },
    },
    "& >div:nth-child(odd)":{
      backgroundColor: "white",
    },
    "& >div:nth-child(even)":{
      backgroundColor: colors.greyLight,
    },
    "& >div:hover":{
      backgroundColor: colors.grey,
    },
  },

  upleticSearch: {
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    height: "auto !important",
    "& > span": {
      cursor: "pointer",
      textDecoration: "none",
      fontSize: "12px",
      fontWeight: "bold",
      color: colors.blueGrey,
      padding: "15px 10px",
    },
  },

  openMenu:{
    color: `${colors.blue} !important`,
  },
}));

export const Header = ({...props}) => {
  const classes = useStyles(props);

  const [countries, setCountries] = useState([]);
  const [continents, setContinents] = useState([]);
  const [countriesByContinent, setCountriesByContinent] = useState([]);
  const [profileImage, setProfileImage] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState();

  const [mobileMenu, setMobileMenu] = useState(false);
  const [closeButton, setCloseButton] = useState('');
  const [menuPage, setMenuPage] = useState("top");

  const [search, setSearch] = useState();
  const [posts, setPosts] = useState([]);

  const [userSlug, setUserSlug] = useState();

  const getPosts = async (s) => {
    const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      }
    };

    let url = `${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/posts?_fields=author,id,date,type,title,link,featured_media&per_page=4&search=${s}`;

    let response = await fetch(url, settings);

    if (response.ok) {

      let wpPosts = await response.json();

      for(let i=0; i<wpPosts.length; i++){
        wpPosts[i].image_url = await getFeaturedImage(wpPosts[i].featured_media);
      }
      setPosts(wpPosts);

    } else {
      console.log("HTTP-Error: " + response.status);
    }

  };

  const getFeaturedImage = async (image_id) => {
    const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      }
    };

    let url = `${process.env.REACT_APP_BLOG_URL}/wp-json/wp/v2/media/${image_id}?_fields=guid`;
    let response = await fetch(url, settings);

    if (response.ok) {
      let image = await response.json();
      return image.guid.rendered;
    } else {
      return 'profile.jpeg';
    }
  };

  const handleSearch = (e) => {
    if(e.key === 'Enter'){
      window.parent.location = `${process.env.REACT_APP_BLOG_URL}/?s=${e.target.value}`;
      return;
    }
    setSearch(e.target.value);
    getPosts(e.target.value);
  };

  const toggleMobileMenu = () => {
    resetMenu('top')
    if(mobileMenu){
      setCloseButton('');
      setMobileMenu(false);
    }else{
      setCloseButton("close");
      setMobileMenu(true);
    }
  };

  const showCountries = (continent_name) => {
    let newCountriesByContinent = countries.filter(item => item.continent_name === continent_name);
    setCountriesByContinent(newCountriesByContinent);
  };

  const logout = () => {
    window.localStorage.clear();
    cookies.remove('token',{path: "/", domain: ".americanfootballinternational.com"});
    cookies.remove('loggedIn',{path: "/", domain: ".americanfootballinternational.com"});
    window.parent.location = "/";
  };

  const resetMenu = (menu) => {
    if(menuPage === 'continents' && countriesByContinent.length > 0){
      setMenuPage('continents');
    }else{
      setMenuPage(menu);
    }
    setCountriesByContinent([]);
  };

  const goToProfile = () => {
    if(userType == USER_TYPE.professional_player
      || userType == USER_TYPE.prospect_player
      || userType == USER_TYPE.professional_coach
      || userType == USER_TYPE.college_coach){
        window.parent.location = `/profile/user_profile?user=${userSlug}`;
      }
  };

  useEffect(() => {
    getCountries().then(response => {
      setCountries(response.data.countries);

      let countryContinents=[...new Map(response.data.countries.map(item => [item['continent_name'], item])).values()]
      
      setContinents(countryContinents);
    });

    let token = window.localStorage.getItem("token");
    if(!token)token=cookies.get("token");

    try{
      let decoded = jwt_decode(token);
      setUserType(decoded.type);
    }catch(error){
      setUserType(null);
    }

    if(window.localStorage.getItem("loggedIn") === "true" || cookies.get("loggedIn") === "true"){
      setLoggedIn(true);
    }

    getProfileImage().then(response => {
      setProfileImage(response.data.image);
    });

    if(window.localStorage.getItem("userSlug")){
      setUserSlug(window.localStorage.getItem("userSlug"));
    }else{
      setUserSlug(cookies.get("userSlug"));
    }

  },[]);

  return (
    <>
    <div className={classes.header}>
      <div>
        <div className={classes.logo} onClick={e => window.parent.location="/"}></div>
        <a href={process.env.REACT_APP_BLOG_URL} target="_parent"><span className="nav-label">NEWS</span></a>
        <a href={'https://afi.tv/'} target="_parent"><span className="nav-label">LIVE</span></a>
        <a href={DASHBOARD_ROUTES.games} target="_parent"><span className="nav-label">GAMES</span></a>
        <a href={'#'} id="country"><span className="nav-label">COUNTRY INFO</span></a>
        <a href={'#'} id="directory">
          <span className="nav-label">DIRECTORY</span>
          <div className={classes.directoryMenu + ' ' + classes.subMenu} id="directoryMenu">
            <div className={classes.menuSubtitle}>DIRECTORY</div>
            <div>
              <a href={DASHBOARD_ROUTES.searchPlayers} target="_parent">PLAYERS</a>
              <a href={DASHBOARD_ROUTES.searchCoaches} target="_parent">COACHES</a>
              <a href={DASHBOARD_ROUTES.searchTeams} target="_parent">TEAMS</a>
            </div>
          </div>
        </a>
        <div className={classes.search}>
          <span><img src={searchIcon} /></span>
          <input placeholder="Search" onBlur={e => setPosts([])} onKeyUp={e => handleSearch(e)}/>
          {
            (posts.length > 0 && search.length > 0) ?
            <div className={classes.searchResults}>
              {
                posts.map((post, key) => {
                return (
                  <div onMouseDown={e => window.parent.location = post.link}>
                    <div>
                      <span>{post.title.rendered}</span>
                      <span><ReactTimeAgo date={new Date(post.date)}/></span>
                    </div>
                  </div>
                )
                })
              }
            <span className={classes.upleticSearch}>
              <span onMouseDown={e => window.parent.location = `${DASHBOARD_ROUTES.searchPlayers}?search=${search}`} target="_parent">{`search for "${search}" in players directory`}</span>
              <span onMouseDown={e => window.parent.location = `${DASHBOARD_ROUTES.searchCoaches}?search=${search}`} target="_parent">{`search for "${search}" in coaches directory`}</span>
              <span onMouseDown={e => window.parent.location = `${DASHBOARD_ROUTES.searchTeams}?search=${search}`} target="_parent">{`search for "${search}" in teams directory`}</span>
            </span>
            </div>
            : null
          }
        </div>
        <div className={classes.headerRight}>
          {
            (loggedIn) ?
            <>
              <span onClick={e => goToProfile()} id="profile">
                <img src={(profileImage)?`${process.env.REACT_APP_API_ENDPOINT}/${profileImage}`:defaultPlayerLogo} className={classes.profileImage} />
              </span>
              <div className={classes.profileMenu + ' ' + classes.subMenu} id="profileMenu">
                <div>
                  <a href={PROFILE_ROUTES.basicInformation} target="_parent">Edit Profile</a>
                  <a href={PROFILE_ROUTES.changePassword} target="_parent">Change Password</a>
                  <a href="#" onClick={logout}>Logout</a>
                </div>
              </div>
            </>
            :
            <>
            <div className={classes.button} id="register">
              <a href={ONBOARDING_ROUTES.createAccount} target="_parent">SIGN UP</a>
            </div>
            
            <a href="/login" target="_parent" className="nav-login"><span className="nav-label">LOGIN</span></a>
            </>
          }
        </div>
        
        <div className={classes.countriesMenu + ' ' + classes.subMenu} id="countriesMenu">
          <div>
            <div className={classes.menuSubtitle}>CONTINENTS</div>
              <div>
                {
                  continents.map((continent,key) => {
                    return <a key={key} href={`${process.env.REACT_APP_BLOG_URL}/category/${continent.continent_slug}`} target="_parent" onMouseEnter={e => showCountries(continent.continent_name)}>{continent.continent_name}</a>
                  })
                }
              </div>
          </div>
          <div>
            <div className={classes.menuSubtitle}>COUNTRIES</div>
            <div>
            {
              countriesByContinent.map((country,key) => {
                return <a key={key} href={`${process.env.REACT_APP_BLOG_URL}/category/${country.continent_slug}/${country.country_slug}`} target="_parent">{country.country_name}</a>
              })
            }
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={classes.mobileHeader}>
    <div className={(mobileMenu)?classes.mobileLogo +' '+ classes.mobileLogoColor:classes.mobileLogo} onClick={e => window.parent.location="/"}></div>
      {
        (loggedIn) ?
        <a href={'#'} id="profileMobile"
        onClick={e => {
          setCloseButton("close");
          setMobileMenu(true);
          resetMenu('profile');
        }}>
          <img src={(profileImage)?`${process.env.REACT_APP_API_ENDPOINT}/${profileImage}`:defaultPlayerLogo} className={classes.profileImage} />
        </a>
        : <span><a href="/login" className={(mobileMenu)?classes.openMenu:null} target="_parent">LOGIN</a></span>
      }
      <div className={classes.mobileMenuIcon + ' ' + closeButton} onClick={e => toggleMobileMenu()}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    {
      (mobileMenu) ?
      <div className={classes.mobileMenu}>
      {
      (menuPage === "top") ?
        <>
          <div className={classes.mobileSearch}>
            <span><img src={searchIconGrey} /></span>
            <input placeholder="Search" onBlur={e => setPosts([])}  onKeyUp={e => handleSearch(e)}/>
            {
            (posts.length > 0 && search.length > 0) ?
              <div className={classes.searchResults}>
              {
                posts.map((post, key) => {
                return (
                  <div onMouseDown={e => window.parent.location = post.link}>
                    <div>
                      <span>{post.title.rendered}</span>
                      <span><ReactTimeAgo date={new Date(post.date)}/></span>
                    </div>
                  </div>
                )
                })
              }
              <span className={classes.upleticSearch}>
                <span onMouseDown={e => window.parent.location = `${DASHBOARD_ROUTES.searchPlayers}?search=${search}`} target="_parent">{`search for "${search}" in players directory`}</span>
                <span onMouseDown={e => window.parent.location = `${DASHBOARD_ROUTES.searchCoaches}?search=${search}`} target="_parent">{`search for "${search}" in coaches directory`}</span>
                <span onMouseDown={e => window.parent.location = `${DASHBOARD_ROUTES.searchTeams}?search=${search}`} target="_parent">{`search for "${search}" in teams directory`}</span>
              </span>
            </div>
          : null
          }
          </div>
          <div className={classes.dashedLine}></div>
          <ul>
            <li style={{paddingLeft:spacing.xLarge,backgroundImage: `url(${newsIcon})`}}><a href={process.env.REACT_APP_BLOG_URL} target="_parent">NEWS</a></li>
            <li style={{paddingLeft:spacing.xLarge,backgroundImage: `url(${liveIcon})`}}><a href={'https://afi.tv/'} target="_parent">LIVE</a></li>
            <li style={{paddingLeft:spacing.xLarge,backgroundImage: `url(${gamesIcon})`}}><a href={'/dashboard/games'} target="_parent">GAMES</a></li>
            <li style={{paddingLeft:spacing.xLarge,backgroundImage: `url(${countriesIcon})`}}><a href={'#'} onClick={e => resetMenu('continents')}>COUNTRY INFO</a></li>
            <li style={{paddingLeft:spacing.xLarge,backgroundImage: `url(${directoryIcon})`}}><a href={'#'} onClick={e => resetMenu('directory') }>DIRECTORY</a></li>
            <li className={classes.dashedLine}></li>
          </ul>
        </>
      : (menuPage === "continents") ?
        <>
          <a href={'#'} onClick={e => resetMenu('top')}>{`< Back`}</a>
          <div className={classes.dashedLine}></div>
          <ul className={classes.mobileSubMenu}>
            {
              (countriesByContinent.length > 0) ?
              <>
              {
                countriesByContinent.map((country,key) => {
                  return <li key={key}><a onClick={e => window.parent.location = `${process.env.REACT_APP_BLOG_URL}/category/${country.continent_slug}/${country.country_slug}`}>{country.country_name}</a></li>
                })
              }
              </>
              :
              <>
              {
                continents.map((continent,key) => {
                  return <li key={key}><a href={'#'} onClick={e => showCountries(continent.continent_name)}>{continent.continent_name}</a></li>
                })
              }
              </>
            }

          </ul>
        </>
      : (menuPage === "directory") ?
        <>
          <a href={'#'} onClick={e => resetMenu('top')}>{`< Back`}</a>
          <div className={classes.dashedLine}></div>
          <ul className={classes.mobileSubMenu}>
            <li><a href={DASHBOARD_ROUTES.searchPlayers} target="_parent">PLAYERS</a></li>
            <li><a href={DASHBOARD_ROUTES.searchCoaches} target="_parent">COACHES</a></li>
            <li><a href={DASHBOARD_ROUTES.searchTeams} target="_parent">TEAMS</a></li>
          </ul>
        </>
      : (menuPage === "profile") ?
        <>
          <a href={'#'} onClick={e => resetMenu('top')}>{`< Back`}</a>
          <div className={classes.dashedLine}></div>
          <ul>
            {
              (userType == USER_TYPE.professional_player || userType == USER_TYPE.prospect_player || userType == USER_TYPE.professional_coach || userType == USER_TYPE.college_coach)?
                <li><a href={`/profile/user_profile?user=${userSlug}`} target="_parent">My Profile</a></li>: null
            }
            <li><a href={PROFILE_ROUTES.basicInformation} target="_parent">Edit Profile</a></li>
            <li><a href={PROFILE_ROUTES.changePassword} target="_parent">Change Password</a></li>
            <li className={classes.dashedLine}></li>
            <li><a onClick={logout}>Logout</a></li>
          </ul>
        </>
        :null
      }
      </div>
      : null
    }
    </>
  );
};
