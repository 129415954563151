import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  getEducation,
  removeUserEducation,
  saveUserEducation,
  getSchools,} from "../../../api/education";
import {
  getAwards,
  removeAward,
  saveAward,}  from "../../../api/awards";
import { getLeagues }  from "../../../api/teams";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import { YEARS } from  "../../../constants/constants";
import Menu from "../components/menu";
import downArrow from "../../../assets/images/down_arrow.png";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    padding: "10px 20px",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: `${spacing.medium}px !important`,
  },

  form: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    },
  },

  formInputs: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    marginLeft: "20px",
  },

  inputsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    marginBottom: spacing.xSmall,
    "& > span":{
      fontSize: "14px",
      alignSelf: "center",
      color: colors.blueGrey,
    },
    "& input:nth-child(1)": {
      marginRight: spacing.medium,
    },
  },

  input: {
    backgroundColor: "white",
    height: "35px",
    width: "250px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "60%",
    },
  },

  select: {
    margin: "0",
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% center",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  label: {
    color: colors.blueDeep,
    fontSize: "16px",
    marginTop: spacing.medium,
    width: "100%",
  },

  inputError: {
    border: "1px solid red",
  },

  addButtons: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "10px",
    "& > span":{
      cursor: "pointer",
      display: "block",
      color: colors.blue,
      fontWeight: "bold",
      marginRight: "10px",
      paddingTop: "10px",
    }
  },

  addButton: {
    padding: "10px 15px",
    width: "150px",
    borderRadius: "25px",
    textAlign: "center",
    fontSize: "14px",
    color: colors.blueDark,
    cursor: "pointer",
    backgroundColor: colors.greyDark,
    "& span": {
      color: colors.blueDark,
      fontSize: "24px",
      float: "left",
      lineHeight: "0.6",
    },
  },

  awardsEducationSection:{
    display: "flex",
    flexDirection: "column",
    "& div":{
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      borderRadius: "10px",
      padding: "10px",
      marginBottom: spacing.xSmall,
      justifyContent: "space-between",
      fontSize: "14px",
      border: `1px solid ${colors.greyDark}`,
      webkitBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    mozBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    boxShadow: `0 8px 6px -6px ${colors.grey}`,
      "& > span:nth-child(1),span:nth-child(2)":{
        width: "30%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& > span:nth-child(2),span:nth-child(3)":{
        fontSize: "12px",
      },
      "& > span:nth-child(4)":{
        fontWeight: "bold",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
      padding: "0px"
    },
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

  addAwardEducationInputSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.greyDark,
    fontSize: "14px",
    color: colors.blueGrey,
    borderRadius: "10px",
    padding: "0px",
    paddingBottom: "15px",
    "& > div:nth-child(1)": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      backgroundColor: "white",
      padding: "10px",
      fontSize: "14px",
      color: colors.blueGrey,
      marginBottom: spacing.medium,
      webkitBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    mozBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    boxShadow: `0 8px 6px -6px ${colors.grey}`,
    },
    "& select": {
      marginBottom: "5px",
      width: "130px",
      [theme.breakpoints.down("xs")]: {
        float: "right",
        marginRight: "0px !important",
      },
    },
    "& select:nth-child(1)": {
      marginRight: spacing.medium,
    },
    "& input": {
      marginBottom: "5px",
    },
    "& label": {
      marginTop: "15px",
      paddingBottom: "3px",
      width: "100px",
      marginRight: "210px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "10px",
      },
    },
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  button: {
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    color: colors.blueDark,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
  },
  
}));

const EducationAndAwards = () => {
  const classes = useStyles();
  const history = useHistory();

  const [educationList, setEducationList] = useState([]);
  const [awardsList, setAwardsList] = useState([]);
  const [educationInputSection, setEducationInputSection] = useState(false);
  const [awardInputSection, setAwardInputSection] = useState(false);
  const [degree, setDegree] = useState();
  const [school, setSchool] = useState();
  const [award, setAward] = useState();
  const [degreeToYear, setDegreeToYear] = useState();
  const [degreeFromYear, setDegreeFromYear] = useState();
  const [awardYear, setAwardYear] = useState();
  const [league, setLeague] = useState();
  const [leagueId, setLeagueId] = useState();
  const [leagues, setLeagues] = useState([]);
  const [schools, setSchools] = useState([]);
  
  const [schoolError, setSchoolError] = useState(false);
  const [degreeError, setDegreeError] = useState(false);
  const [degreeYearError, setDegreeYearError] = useState(false);
  const [awardError, setAwardError] = useState(false);
  const [awardYearError, setAwardYearError] = useState(false);
  const [leagueError, setLeagueError] = useState(false);
  
  const getEducationList = () => {
    getEducation().then((response) => {
      setEducationList(response.data.education);
    }).catch(e => {
      console.log(e);
    });
  };

  const getAwardsList = () => {
    getAwards().then((response) => {
      setAwardsList(response.data.awards);
    }).catch(e => {
      console.log(e);
    });
  };

  const openEducationInputSection = () => {
    setEducationInputSection(true);
  };

  const closeEducationInputSection = () => {
    setEducationInputSection(false);
  };

  const openAwardsInputSection = () => {
    setAwardInputSection(true);
  };

  const closeAwardsInputSection = () => {
    setAwardInputSection(false);
  };

  const removeEducation = (eduaction_id) => {
    removeUserEducation(eduaction_id).then((response) => {
      getEducationList();
    }).catch(e => {
      console.log(e);
    });
  };

  const removeUserAward = (award_id) => {
    removeAward(award_id).then((response) => {
      getAwardsList();
    }).catch(e => {
      console.log(e);
    });
  };

  const saveUserAward = () => {
    let hasError = false;

    if(!award){
      setAwardError(true);
      hasError = true;
    }

    if(!league){
      setLeagueError(true);
      hasError = true;
    }

    if(!awardYear){
      setAwardYearError(true);
      hasError = true;
    }

    if(hasError) return;

    let userAward = {
      name: award,
      year: awardYear,
      league: leagueId,
    };

    saveAward(userAward).then((response) => {
      getAwardsList();
      closeAwardsInputSection();
    }).catch(e => {
      console.log(e);
    });
  };

  const saveEducation = () => {
    let hasError = false;
    
    if(!school){
      setSchoolError(true);
      hasError = true;
    }

    if(!degree){
      setDegreeError(true);
      hasError = true;
    }

    if(!degreeFromYear || !degreeToYear || (degreeFromYear > degreeToYear)){
      setDegreeYearError(true);
      hasError=true;
    }

    if(hasError) return;

    let userEducation = {
      school: school,
      degree: degree,
      from: degreeFromYear,
      to: degreeToYear,
    };

    saveUserEducation(userEducation).then((response) => {
      getEducationList();
      closeEducationInputSection();
    }).catch(e => {
      console.log(e);
    });
  };

  useEffect(() => {
    getEducationList();
    getAwardsList();

    getLeagues().then((response) => {
      setLeagues(response.data.leagues)
    }).catch(e => {
      console.log(e);
    });

    getSchools().then((response) => {
      setSchools(response.data.schools)
    }).catch(e => {
      console.log(e);
    });

  }, [history]);

  return (
      <div className={classes.content}>
        <Menu />
        <div className={classes.profileContainer}>
          <form className={classes.form} noValidate autoComplete="off">
            <Typography className={classes.subTitle}>EDUCATION</Typography>
            <div className={classes.inputsWrapper}>
              <span>List your past education</span>
              <div className={classes.addButton} onClick={openEducationInputSection}>
                  <span>+</span>
                  ADD
                </div>
            </div>
          </form>
          {
            (educationList.length > 0) ?
            <div className={classes.awardsEducationSection}>
              {
                educationList.map(education => {
                return <div><span>{education.school_name}</span><span>{education.degree_name}</span><span>{education.from_year + '-' + education.to_year}</span><span className={classes.siteLink} onClick={() => removeEducation(education.id)}>Remove</span></div>;
                })
              }
            </div>
            :null
          }

          {
            (educationInputSection) ?
              <div className={classes.addAwardEducationInputSection}>
                <div>Add Education</div>
                <form className={classes.form} noValidate autoComplete="off">
                  <FormControl className={classes.formInputs}>
                    <label>School Name</label>
                    <Autocomplete
                      freeSolo
                      className={(!schoolError)?classes.input:classes.input + " " + classes.inputError}
                      onChange={(event, newValue) => {
                        if(newValue){
                          setSchool(newValue.school_name);
                        }
                      }}
                      options={schools}
                      getOptionLabel={(option) => option.school_name}
                      value={school}
                      style={{padding: "4px 5px",marginBottom:spacing.xSmall}}
                      renderInput={(params) => <TextField  {...params} />}
                    />
                    
                  </FormControl>
                  <FormControl className={classes.formInputs}>
                    <label>Degree</label>
                    <input type="text" className={(!degreeError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setDegree(e.target.value)} />
                  </FormControl>
                  <FormControl className={classes.formInputs}>
                    <label>Years</label>
                    <div>
                    <select type="text" className={(!degreeYearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} onChange={e => setDegreeFromYear(e.target.value)}>
                      <option value="">From</option>
                      {
                        YEARS.map(year => {
                          return <option value={year}>{year}</option>;
                        })
                      }
                    </select>
                    <select type="text" className={(!degreeYearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} onChange={e => setDegreeToYear(e.target.value)}>
                      <option value="">To</option>
                      {
                        YEARS.map(year => {
                          return <option value={year}>{year}</option>;
                        })
                      }
                    </select>
                    </div>
                  </FormControl>
                </form>
                <div className={classes.addButtons}>
                  <div className={classes.button} onClick={saveEducation}>
                    <span>Add Education</span>
                  </div>
                  <span onClick={closeEducationInputSection}>Cancel</span>
                </div>
              </div>
              :null
          }
          <div className={classes.dashedLine}></div>
          <form className={classes.form} noValidate autoComplete="off">
            <Typography className={classes.subTitle}>AWARDS</Typography>
            <div className={classes.inputsWrapper}>
              <span>List your awards</span>
              <div className={classes.addButton} onClick={openAwardsInputSection}>
                <span>+</span>
                ADD
              </div>
            </div>
          </form>

          {
            (awardsList.length > 0) ?
            <div className={classes.awardsEducationSection}>
              {
                awardsList.map(award => {
                return <div><span>{award.league}</span><span>{award.name}</span><span>{award.year}</span><span className={classes.siteLink} onClick={() => removeUserAward(award.id)}>Remove</span></div>;
                })
              }
            </div>
            :null
          }

            {
              (awardInputSection) ?
                <div className={classes.addAwardEducationInputSection}>
                  <div>Add Awards</div>
                  <form className={classes.form} noValidate autoComplete="off">
                    <FormControl className={classes.formInputs}>
                      <label>Award Name</label>
                      <input type="text" className={(!awardError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setAward(e.target.value)} />
                    </FormControl>
                    <FormControl className={classes.formInputs}>
                      <label>League</label>
                      <Autocomplete
                      freeSolo
                      className={(!leagueError)?classes.input:classes.input + " " + classes.inputError}
                      onChange={(event, newValue) => {
                        if(newValue){
                          setLeague(newValue.full_name);
                          setLeagueId(newValue.id);
                        }
                      }}
                      options={leagues}
                      getOptionLabel={(option) => option.full_name}
                      value={league}
                      style={{padding: "4px 5px",marginBottom:spacing.xSmall}}
                      renderInput={(params) => <TextField  {...params} />}
                    />
                    </FormControl>
                    <FormControl className={classes.formInputs}>
                      <label>Year</label>
                      <select type="text" className={(!awardYearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setAwardYear(e.target.value)}>
                        <option value=""></option>
                        {
                          YEARS.map(year => {
                            return <option value={year}>{year}</option>;
                          })
                        }
                      </select>
                    </FormControl>
                  </form>
                  <div className={classes.addButtons}>
                    <div className={classes.button} onClick={saveUserAward}>
                      <span>Add Award</span>
                    </div>
                    <span onClick={closeAwardsInputSection}>Cancel</span>
                  </div>
                </div>
                :null
            }
        </div>
      </div>
  );
}

export default EducationAndAwards;