import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import jwt_decode from "jwt-decode";

import { spacing, colors } from  "../../../constants/theme";
import { PROFILE_ROUTES } from  "../../../constants/routes";
import { USER_TYPE } from "../../../constants/constants";
import downArrow from "../../../assets/images/down_arrow.png";

const useStyles = makeStyles((theme) => ({
  menu: {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    marginTop: spacing.xSmall,
    "& a": {
      fontSize: "12px",
      fontWeight: "bold",
      textDecoration: "none",
      paddingBottom: spacing.small,
      color: colors.blue,
      "&:hover":{
        textDecoration: "underline",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "90%",
      margin: "15px auto",
    },
  },

  menuSelect: {
    width: "100%",
    margin: "0 auto",
    "& > select": {
      backgroundColor: "white",
      height: "35px",
      borderRadius: "2px",
      boxShadow: "inset 0 0 1px 0px #888",
      color: colors.grey,
      "&::placeholder":{
        color: colors.grey,
        paddingLeft: spacing.xSmall,
      },
      paddingLeft: spacing.xSmall,
      margin: "0",
      "-webkitBoxSizing": "border-box",
      "-moz-box-sizing": "border-box",
      "box-sizing": "border-box",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      backgroundColor: "white",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "98% 50%",
      paddingLeft: spacing.xSmall,
      color: colors.grey,
      fontSize: "16px",
    },
  },
}));

const Menu = (props) => {
  const classes = useStyles();

  let decoded = jwt_decode(localStorage.getItem("token"));

  let userType = parseInt(decoded.type);

  const url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  const isMobile = isWidthDown('sm', props.width);

  return (
    <div className={classes.menu}>
      {isMobile?
      <div className={classes.menuSelect}>
      <select className={classes.menuSelect} onChange={e => {
        window.location.href = e.target.value;       
      }}>
        <option value="">Go To</option>
        
          <option value={PROFILE_ROUTES.basicInformation}>BASIC INFO</option>
          {
            (userType === USER_TYPE.professional_player || userType === USER_TYPE.prospect_player) ?
            <>
              <option value={PROFILE_ROUTES.recruitmentInformation}>RECRUITMENT INFO</option>
              <option value={PROFILE_ROUTES.educationAndAwards}>EDUCATION AND AWARDS</option>
            </> : null
          }
          {
            (userType === USER_TYPE.professional_coach || userType === USER_TYPE.college_coach) ?
            <option value={PROFILE_ROUTES.coachInformation}>COACH INFO</option> : null
          }
          {
            (userType === USER_TYPE.professional_team_manager || userType === USER_TYPE.college_team_manager) ?
              <option value={PROFILE_ROUTES.editTeam}>MANAGEMENT & TEAM PROFILE</option> : null
          }
          <option value={PROFILE_ROUTES.reels}>REELS AND GAME HIGHLIGHTS</option>
        
      </select>
    </div>
      :
      <>
      <a href={PROFILE_ROUTES.basicInformation} style={(url === 'basic_information')?{textDecoration:'underline'}:{}}>BASIC INFO</a>
      {
        (userType === USER_TYPE.professional_player || userType === USER_TYPE.prospect_player) ?
        <>
          <a href={PROFILE_ROUTES.recruitmentInformation} style={(url === 'recruitment_information')?{textDecoration:'underline'}:{}}>RECRUITMENT INFO</a>
          <a href={PROFILE_ROUTES.educationAndAwards} style={(url === 'education_awards')?{textDecoration:'underline'}:{}}>EDUCATION AND AWARDS</a>
        </> : null
      }
      
      {
        (userType === USER_TYPE.professional_coach || userType === USER_TYPE.college_coach) ?
        <>
          <a href={PROFILE_ROUTES.coachInformation} style={(url === 'coach_information')?{textDecoration:'underline'}:{}}>COACH INFO</a>
        </> : null
      }

      {
        (userType === USER_TYPE.professional_team_manager || userType === USER_TYPE.college_team_manager) ?
          <a href={PROFILE_ROUTES.editTeam} style={(url === 'edit_team')?{textDecoration:'underline'}:{}}>MANAGEMENT & TEAM PROFILE</a> : null
      }
      
      {
      (userType != USER_TYPE.admin) ?
        <a href={PROFILE_ROUTES.reels} style={(url === 'reels')?{textDecoration:'underline'}:{}}>REELS AND GAME HIGHLIGHTS</a>:null}
      </>}
    </div>
  );
}

export default withWidth()(Menu);