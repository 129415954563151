import API from "./api";

const saveVideo = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/saveVideo", data);
};

const getVideos = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getVideos?token=${token}`);
};

const getVideosBySlug = async (slug) => {
  let token = localStorage.getItem("token");
  return await API.get(`/getVideosBySlug?token=${token}&slug=${slug}`);
};

const removeVideo = async (video_id) => {
  let token = localStorage.getItem("token");
  let data = {video_id:video_id, token:token};
  return await API.post("/removeVideo", data);
};

export {
  saveVideo,
  getVideos,
  getVideosBySlug,
  removeVideo,
};