import API from "./api";

const signup = async (data) => {
  return await API.post("/signup", data);
};

const login = async (data) => {
  return await API.post("/login", data);
};

const verifyToken = async () => {
  let token = localStorage.getItem("token");
  if (!token) return false;
  return await API.post("/verifyToken", {token});
};

const activateUserAccount = async (token) => {
  return await API.post("/activateUserAccount", {token});
};

const resetPassword = async (data) => {
  return await API.post("/resetPassword", data);
};

const updatePassword = async (data) => {
  return await API.post("/updatePassword", data);
};

const changePassword = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/changePassword", data);
};

export {
  signup,
  login,
  verifyToken,
  activateUserAccount,
  resetPassword,
  updatePassword,
  changePassword
};