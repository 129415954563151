import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Geonames from 'geonames.js';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import jwt_decode from "jwt-decode";
import 'material-icons/iconfont/material-icons.scss';

import { getUserInfo, updateProfile } from "../../../api/users";
import { ONBOARDING_ROUTES, PROFILE_ROUTES } from  "../../../constants/routes";
import step1 from "../../../assets/images/step_1.png";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import downArrow from "../../../assets/images/down_arrow.png";
import twitter_logo from "../../../assets/images/twitter_logo.png";
import facebook_logo from "../../../assets/images/facebook_logo.png";
import instagram_logo from "../../../assets/images/instagram_logo.svg";
import linkedin_logo from "../../../assets/images/linkedin_logo.png";
import { toBase64, checkExtension } from "../../../utils/helpers";
import { LANGUAGES, INPUT_MAX_LENGTH, USER_TYPE } from  "../../../constants/constants";

const geonames = new Geonames({
  username: 'alwaysaugust',
  lan: 'en',
  encoding: 'JSON'
});

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    marginTop: spacing.large,
  },

  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  step: {
    width: "100px",
    margin: "0 auto",
    marginBottom: spacing.medium,
  },

  title: {
    fontSize: "30px",
    fontWeight: "bold",
    color: colors.blueDark,
    margin: "0 auto",
    marginBottom: spacing.large,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    }
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: `${spacing.small}px !important`,
    marginTop: spacing.xSmall,
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    padding: "20px 20px 30px 20px",
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  button: {
    margin: "0 auto",
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
      color: colors.blueDark,
      width: "100%",
    },
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  form: {
    "& > *": {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      "& > *": {
        margin: 0,
      },
      margin: "0 auto",
      width: "100%",
    },
  },

  socialForm: {
    marginBottom: spacing.small,
    "& div":{
      display: "inline-flex !important",
      flexDirection: "column !important",
    },
    width: "100%",
    '& > *': {
      margin: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        margin: "0px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      margin: "20px auto",
    }
  },


  inputsWrapper: {
    display: "inline-block",
    overflow: "auto",
    marginTop: 0,
    "& input:nth-child(1)": {
      marginRight: spacing.medium,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    "& .MuiInput-underline:before":{
      content: "none",
    },
    "& .MuiInput-underline:after":{
      content: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& .MuiFormControl-root": {
        width: "100%",
        marginBottom: spacing.small,
      },
    },
  },

  input: {
    backgroundColor: "white",
    height: "35px",
    width: "250px",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "auto",
    },
  },

  select: {
    margin: "0",
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  dateInput: {
    "& > div:nth-child(1)": {
      boxShadow: "inset 0 0 2px 0px #888",
      paddingLeft: "0px !important",
      "& > .MuiInput-formControl": {
        height: "100%",
      },
    },
    "& input": {
      padding: 0,
      paddingLeft: spacing.xSmall,
      fontSize: "13px",
      marginRight: "0px !important",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "98% 50%",
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    width: "100%",
    marginTop: spacing.medium,
    marginBottom: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.xSmall,
    },
  },

  uploadPic: {
    display: "block",
    fontSize: "12px",
    border: "1px dashed",
    borderColor: colors.orange,
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    "& span": {
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "14px",
    }
  },

  fileInput: {
    width: "0.1px",
	  height: "0.1px",
	  opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: "1",
  },

  inputError: {
    border: "1px solid red",
  },

  socialLogo: {
    color: colors.blueDeep,
    fontSize: "14px",
    lineHeight: 4,
    "& img":{
      width: "30px",
      marginTop: "5px",
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        marginRight: spacing.small,
      },
    },
    "& span":{
      fontSize: "30px",
      [theme.breakpoints.down("xs")]: {
        marginRight: spacing.small,
        
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px !important",
      lineHeight: 1,
      marginTop: "0px",
    },
  },

  errorMessage: {
    color: colors.errorMessage,
    marginBottom: 0,
  },

  updateError: {
    width: "auto",
    backgroundColor: colors.errorMessage,
    color: "white",
    textAlign: "left",
    padding: "15px",
  },

  uploadWrapper: {
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

}));

let countries = [];

const ProfileSetup = () => {
  const classes = useStyles();
  const history = useHistory();

  let decoded = jwt_decode(localStorage.getItem("token"));

  let userType = parseInt(decoded.type);

  const [countriesList, setCountriesList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthdate, setBirthdate] = useState(new Date());
  const [gender, setGender] = useState('');
  const [language, setLanguage] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [picName, setPicName] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [website, setWebsite] = useState('');
  const [loadingRegions, setLoadingRegions] = useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [updateError, setUpdateError] = useState('');

  const handleCountryChange = (event) => {
    setLoadingRegions(true);
    setCountry(event.target.value);
    getRegions(event.target[event.target.selectedIndex].id).then(regions => {
      setRegionsList(regions.geonames);
      setLoadingRegions(false);
    }).catch(e=>{
      setRegionsList([]);
      setLoadingRegions(false);
    });
  }

  const getRegions = async (id) => {
    return await geonames.children({geonameId: id});
  }

  const getCountries = async () => {
    if(countries.length === 0){
      countries = await geonames.countryInfo({});
    }
    return countries;
  };

  const handleUploadPic = async (event) => {
    let file = event.target.files[0];
    let pic;
    try{
      pic =  await toBase64(file);
    }catch(e){
      setImageError("Only jpg and png allowed");
      return;
    }

    if(!checkExtension(file.name)){
      setImageError("Only jpg and png allowed");
      return;
    }

    if(file.size > 500000){
      setImageError("Image too big");
      return;
    }

    setProfilePic(pic);
    setPicName(file.name);
  };

  useEffect(() => {
    if(localStorage.getItem("loggedIn") === 'false'){
      history.push("/");
    }

    getUserInfo().then((response) => {

      getCountries().then(countries => {
        setCountriesList(countries.geonames);
      });

      setFirstName(response.data.user.first_name);
      setLastName(response.data.user.last_name);
    });

  }, [history]);

  const updateUser = () => {
    let hasError=false;

    if(!firstName || firstName.length > INPUT_MAX_LENGTH){
      setFirstNameError(true);
      hasError=true;
    } else { setFirstNameError(false); }

    if(!lastName || lastName.length > INPUT_MAX_LENGTH){
      setLastNameError(true);
      hasError=true;
    } else { setLastNameError(false); }
    
    if(!birthdate){
      setBirthdateError(true);
      hasError=true;
    } else { setBirthdateError(false); }

    if(!gender){
      setGenderError(true);
      hasError=true;
    } else { setGenderError(false); }

    if(!language){
      setLanguageError(true);
      hasError=true;
    } else { setLanguageError(false); }

    if(!country){
      setCountryError(true);
      hasError=true;
    } else { setCountryError(false); }


    if(hasError) return;

    let data = {
      firstName: firstName,
      lastName: lastName,
      birthdate: moment(birthdate).format("YYYY-MM-DD"),
      gender: gender,
      language: language,
      country: country,
      region: region,
      profilePicture: profilePic,
      instagram: instagram,
      twitter: twitter,
      facebook: facebook,
      linkedin: linkedin,
      website: website,
    };

    updateProfile(data).then((response) => {

      if(response.data.status === "SUCCESS"){
        if(userType === USER_TYPE.professional_player || userType === USER_TYPE.prospect_player){
          history.push(ONBOARDING_ROUTES.recruitmentInformation);
        }else if(userType === USER_TYPE.professional_coach || userType === USER_TYPE.college_coach){
          history.push(ONBOARDING_ROUTES.coachInformation);
        }else if(userType === USER_TYPE.professional_team_manager || userType === USER_TYPE.college_team_manager){
          history.push(ONBOARDING_ROUTES.managerInformation);
        }else if(userType == USER_TYPE.fan){
          window.location.href = PROFILE_ROUTES.basicInformation;
        }
      }else{
        setUpdateError(response.data.error);
      }
    }).catch(e => {
      console.log(e);
    });
  };

  return (
    <>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <img src={step1} className={classes.step} alt="step 1"/>
          <Typography className={classes.title}>SETUP YOUR PROFILE</Typography>
        </div>
        <div className={classes.profileContainer}>
          <div className={classes.buttons}>
            <a href="/" className={classes.cancelButton}>Cancel</a>
            <div className={classes.button} onClick={updateUser}>
                <span>SAVE & CONTINUE</span>
            </div>
          </div>
          <div className={classes.dashedLine}></div>
          <Typography className={classes.subTitle}>PROFILE</Typography>
          {(updateError != '')?<div className={classes.updateError}>{updateError}</div>:null}
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Name</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                  <input type="text" placeholder="First" value={firstName} className={(!firstNameError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setFirstName(e.target.value)} />
              </FormControl>
              <FormControl>
                <input type="text" placeholder="Last" value={lastName} className={(!lastNameError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setLastName(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your birthdate?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl className={classes.dateInput}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker disableFuture="true" invalidLabel="" invalidDateMessage="" format="yyyy-MM-DD" openTo="year" placeholder="1988-03-12" value={birthdate} className={(!birthdateError)?classes.input + " " + classes.dateInput:classes.input + " " + classes.dateInput + " " + classes.inputError} onChange={date => setBirthdate(date)} />
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your gender?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <select className={(!genderError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} value={gender} onChange={e => setGender(e.target.value)}>
                  <option value=""></option>
                  <option value="m">Male</option>
                  <option value="f">Female</option>
                </select>
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What language do you speak?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <select className={(!languageError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} value={language} onChange={e => setLanguage(e.target.value)}>
                  <option value=""></option>
                  {
                    LANGUAGES.map(language => {
                      return <option value={language.value}>{language.label}</option>
                    })
                  }
                </select>
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>What is your location?</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl style={{marginRight:spacing.large}}>
                <select className={(!countryError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} value={country} onChange={e => handleCountryChange(e)}>
                  {
                    (country) ?
                      <option value={country}>{country}</option>
                      :<option value=""></option>
                  }
                  {
                    (countriesList) ?
                      countriesList.map(country => {
                        return <option id={country.geonameId} value={country.countryName}>{country.countryName}</option>
                      })
                    :null
                  }
                </select>
              </FormControl>
              <FormControl>
              {
                  (!loadingRegions) ?
                    <select className={classes.input + " " + classes.select} value={region} onChange={e => setRegion(e.target.value)}>
                      {
                        (region) ?
                          <option value={region}>{region}</option>
                          :<option value=""></option>
                      }
                      {
                        regionsList.map(region => {
                          return <option value={region.name}>{region.name}</option>
                        })
                      }
                    </select>
                    : <CircularProgress />
              }
              </FormControl>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Upload profile pic (max file size 500KB. Ideal ratio 190x195)</span>
              {(imageError)?<div className={classes.errorMessage}>{imageError}</div>:null}
            </FormControl>
            <div className={classes.inputsWrapper + ' ' + classes.uploadWrapper}>
              <label className={classes.uploadPic}>
                <input type="file" className={classes.fileInput} onChange={e => handleUploadPic(e)}/>
                <span>Upload</span>
                <br/>
                {picName}
              </label>
            </div>
          </form>
          <div className={classes.dashedLine}></div>
          <Typography className={classes.subTitle}>SOCIAL MEDIA</Typography>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
              <img src={twitter_logo} alt="twitter logo"/>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                  <input type="text" placeholder="https://twitter.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} onChange={e => setTwitter(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
              <img src={instagram_logo} alt="instagram logo"/>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://instagram.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} onChange={e => setInstagram(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
          <FormControl className={classes.socialLogo}>
            <img src={facebook_logo} alt="facebook logo"/>
          </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://facebook.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} onChange={e => setFacebook(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
          <FormControl className={classes.socialLogo}>
              <img src={linkedin_logo} alt="linkedin logo"/>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="https://linkedin.com/username" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} onChange={e => setLinkedin(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <form className={classes.socialForm} noValidate autoComplete="off">
            <FormControl className={classes.socialLogo}>
            <span className="material-icons">language</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <FormControl>
                <input type="text" placeholder="Website" maxLength={INPUT_MAX_LENGTH*2} className={classes.input} onChange={e => setWebsite(e.target.value)} />
              </FormControl>
            </div>
          </form>
          <div className={classes.dashedLine}></div>
          <div className={classes.buttons}>
            <a href="/" className={classes.cancelButton}>Cancel</a>
            <div className={classes.button} onClick={updateUser}>
              <span>SAVE & CONTINUE</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSetup;