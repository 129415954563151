import API from "./api";

const getAwards = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getAwards?token=${token}`);
};

const removeAward = async (award_id) => {
  let token = localStorage.getItem("token");
  let data = {award_id:award_id, token:token};
  return await API.post("/removeAward", data);
};

const saveAward = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/saveAward", data);
};

export {
  getAwards,
  removeAward,
  saveAward,
};