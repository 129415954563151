import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import jwt_decode from "jwt-decode";

import { signup } from "../../../api/authentication";
import { ONBOARDING_ROUTES } from  "../../../constants/routes";
import { validateEmail, hasUpperCase} from "../../../utils/helpers";
import onboardingIcon from "../../../assets/images/onboarding_icon.svg";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import { INPUT_MAX_LENGTH, USER_TYPE } from "../../../constants/constants";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import { PlayerCard } from "../components/playerCard";
import managerType from "../../../assets/images/manager_type.png";
import coachType from "../../../assets/images/coach_type.png";
import playerType from "../../../assets/images/player_type.png";
import fanType from "../../../assets/images/fan_type.png";
import footballPlayer from "../../../assets/images/football_player.png";

const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    marginTop: spacing.large,
    padding: "10px",
  },

  titleWrapper: {
    display: "block",
    width: "100%",
  },

  icon: {
    width: "40px",
    marginRight: "20px",
  },

  title: {
    display: "inline-block",
    fontSize: "30px",
    color: colors.blueDark,
    marginBottom: spacing.small,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    }
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: spacing.medium,
  },

  createAccountWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  socialLoginContainer: {
    width: "28%",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    textAlign: "center",
    paddingTop: spacing.xLarge,
    "& img": {
      width: "85%",
      marginBottom: spacing.small,
      [theme.breakpoints.down("xs")]: {
        width: "70%",
      }
    },
    "& a": {
      display: "block",
      textDecoration: "none",
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: spacing.xxxLarge,
      paddingBottom: "15px",
    },
  },

  createAccountContainer: {
    padding: "20px",
    width: "100%",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    "& label": {
      paddingBottom: "5px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0px",
      marginTop: spacing.medium,
    },
  },

  form: {
    "& > *": {
      margin: theme.spacing(2),
    },
    "& span":{
      paddingTop: "5px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    }
  },

  input: {
    ...components.commonInput,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },

  terms: {
    fontSize: "13px",
  },

  button: {
    margin: "0 auto",
    width: "350px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    color: colors.blueDark,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    }
  },

  inputError: {
    border: "1px solid red",
  },

  signupError: {
    width: "50%",
    marginLeft: "15px",
    backgroundColor: colors.errorMessage,
    color: "white",
    textAlign: "left",
    padding: "15px",
  },

  passwordErrorSection: {
    display: "flex",
    flexDirection: "column",
    color: colors.errorMessage,
  },

  goBack: {
    position: "absolute",
    marginTop: "5px",
    fontWeight: 700,
    fontSize: '15px',
    color: colors.blue,
    textDecoration: 'none',
    cursor: "pointer",
  },
  
  leftArrow: components.commonLeftArrow,
  
}));

const CreateAccount = () => {
  const classes = useStyles();

  let params = queryString.parse(window.location.search);

  const [page, setPage] = useState(params.type ? params.type:'selectAccountType');
  const [userType, setUserType] = useState(params.userType ? params.userType:null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const [upperCaseError, setUpperCaseError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const goBack = () => {
    setPage('selectAccountType');
    setUserType(null);
  };

  const submit = () => {

    let hasError=false;
    setConfirmPasswordError(false);
    setPasswordLengthError(false);
    setUpperCaseError(false);
    
    if(!firstName || firstName.length > INPUT_MAX_LENGTH){
      setFirstNameError(true);
      hasError=true;
    }

    if(!lastName || lastName.length > INPUT_MAX_LENGTH){
      setLastNameError(true);
      hasError=true;
    }

    if(!validateEmail(email) || email.length > INPUT_MAX_LENGTH){
      setEmailError(true);
      hasError=true;
    }

    if(!hasUpperCase(password)){
      setUpperCaseError(true);
      setPasswordError(true);
      hasError=true;
    }

    if(password.length < 8 || password.length > INPUT_MAX_LENGTH){
      setPasswordLengthError(true);
      setPasswordError(true);
      hasError=true;
    }

    if(!password || password !== confirmPassword){
      setConfirmPasswordError(true);
      setPasswordError(true);
      hasError=true;
    }

    if(hasError) return;

    let data = {
      type: userType,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    };

    signup(data).then((response) => {
      if(response.data.status === "ERROR"){
        setSignupError(response.data.error);
        return;
      }
      let decoded = jwt_decode(response.data.token);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userSlug", decoded.slug);
      window.location.href = ONBOARDING_ROUTES.profileSetup;

    }).catch(e => {
      console.log(e);
    });

  };

  return (
    <>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <img src={onboardingIcon} className={classes.icon} alt="user icon" />
          <Typography className={classes.title}>CREATE AN ACCOUNT</Typography>
        </div>
        {
          (page === 'selectAccountType' && !userType) ?
          <>
            <Typography className={classes.subTitle}>WHAT TYPE OF TEAMMATE ARE YOU?</Typography>
            <div className={classes.cardsWrapper}>
              <PlayerCard
                image={playerType}
                title="I AM A PLAYER"
                description="Ready to go global? Show us what you got. Your game is worldwide, go find your next opportunity!"
                customClickEvent={e=>setPage('selectPlayer')}/>
              <PlayerCard
                image={coachType}
                title="I AM A COACH"
                description="For those where teaching, inspiring, and leading teams to victory with X's and O's is the true playing field. Coaches are born, not made."
                customClickEvent={e=>setPage('selectCoach')}/>
            </div>
            <div className={classes.cardsWrapper}>
              <PlayerCard
                image={managerType}
                title="I AM A TEAM MANAGER"
                description="For team presidents, general managers, even the assistant water carriers. You manage the structure that empowers a successful team."
                customClickEvent={e=>setPage('selectManager')}/>
              <PlayerCard
                image={fanType}
                title="I AM THE ULTIMATE FAN"
                description="Want access to the world of American football in the palm of your hand? Look no further, we built this for you, the Ultimate Fan!"
                customClickEvent={e=>setUserType(USER_TYPE.fan)}/>
            </div>
          </>
          : (page === 'selectPlayer' && !userType) ?
          <>
            <Typography className={classes.subTitle}>WHAT TYPE OF PLAYER ARE YOU?</Typography>
            <div className={classes.cardsWrapper}>
              <PlayerCard
                image={footballPlayer}
                title="PROFESSIONAL PLAYER"
                description="You are a true PRO! You are looking for paid opportunities from professional organizations."
                customClickEvent={e=>setUserType(USER_TYPE.professional_player)}/>
              <PlayerCard
                image={footballPlayer}
                title="PROSPECT PLAYER"
                description="You are looking for a placement or scholarship with a program. This is for non-professionals ONLY!"
                customClickEvent={e=>setUserType(USER_TYPE.prospect_player)}/>
            </div>
          </>
          : (page === 'selectCoach' && !userType) ?
          <>
            <Typography className={classes.subTitle}>WHAT TYPE OF COACH ARE YOU?</Typography>
            <div className={classes.cardsWrapper}>
              <PlayerCard
                image={footballPlayer}
                title="PROFESSIONAL TEAM"
                description="You are a coach for a professional team. Your team is not associated with a university or school system."
                customClickEvent={e=>setUserType(USER_TYPE.professional_coach)}/>
              <PlayerCard
                image={footballPlayer}
                title="SCHOOL TEAM"
                description="You are a coach for a school team. Athletes on your team are unpaid and not professionals."
                customClickEvent={e=>setUserType(USER_TYPE.college_coach)}/>
            </div>
          </>
          : (page === 'selectManager' && !userType) ?
          <>
            <Typography className={classes.subTitle}>WHAT TYPE OF MANAGER ARE YOU?</Typography>
            <div className={classes.cardsWrapper}>
              <PlayerCard
                image={footballPlayer}
                title="PROFESSIONAL TEAM"
                description="You are a manager for a professional team. Your team is not associated with a university or school system."
                link="/onboarding/create_account"
                customClickEvent={e=>setUserType(USER_TYPE.professional_team_manager)}/>
              <PlayerCard
                image={footballPlayer}
                title="SCHOOL TEAM"
                description="You are a manager for a school team. Athletes on your team are unpaid and not professionals."
                link="/onboarding/create_account"
                customClickEvent={e=>setUserType(USER_TYPE.college_team_manager)}/>
            </div>
          </>
          : (userType) ?
          <div className={classes.createAccountWrapper}>
            <div className={classes.createAccountContainer}>
            {(signupError)?<div className={classes.signupError}>{signupError}</div>:null}
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl>
                  <label>First Name</label>
                  <input type="text" className={(!firstNameError)?classes.input:classes.input + " " + classes.inputError} name="firstName" onFocus={e => setFirstNameError(false)} onChange={e => setFirstName(e.target.value)} />
                </FormControl>
                <FormControl>
                  <label>Last Name</label>
                  <input type="text" className={(!lastNameError)?classes.input:classes.input + " " + classes.inputError} name="lastName" onFocus={e => setLastNameError(false)} onChange={e => setLastName(e.target.value)}  />
                </FormControl>
                <FormControl>
                  <label>Email</label>
                  <input type="text" className={(!emailError)?classes.input:classes.input + " " + classes.inputError} name="email" onFocus={e => setEmailError(false)} onChange={e => setEmail(e.target.value)}  />
                </FormControl>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl>
                  <label>Password</label>
                  <input type="password" className={(!passwordError)?classes.input:classes.input + " " + classes.inputError} name="password" onFocus={e => setPasswordError(false)} onChange={e => setPassword(e.target.value)} />
                  <span className={classes.passwordErrorSection}>
                    <span>{(passwordLengthError) ? "Password must be between 8 and 50 characters" : null}</span>
                    <span>{(upperCaseError) ? "Password needs at least one uppercase character" : null}</span>
                    <span>{(confirmPasswordError) ? "Passwords don't match" : null}</span>
                  </span>
                  <span>
                    - 8 characters minimum
                    <br/>
                    - at least 1 uppercase letter
                  </span>
                </FormControl>
                <FormControl>
                  <label>Confirm password</label>
                  <input type="password" className={(!passwordError)?classes.input:classes.input + " " + classes.inputError} name="confirmPassword" onFocus={e => setPasswordError(false)} onChange={e => setConfirmPassword(e.target.value)} />
                </FormControl>
                <div className={classes.terms}>
                By signing up, I agree to American Football International Inc. <a href={`${process.env.REACT_APP_BLOG_URL}/terms-and-conditions`}>Terms of Service</a> & <a href={`${process.env.REACT_APP_BLOG_URL}/privacy-policy`}>Privacy Policy</a>
                </div>
                <div className={classes.button} onClick={submit}>
                  <span>SUBMIT ACCOUNT FOR VERIFICATION</span>
                </div>
              </form>
            </div>
          </div>
          :null
          }
        <span className={classes.leftArrow}>{'<'}</span>
        {
        (page === 'selectAccountType' && !userType) ?
        <a href="/"
        className={classes.goBack}>
        Back</a>
        :
        <span
        onClick={e=>goBack()}
        className={classes.goBack}>
        Back</span>
        }
      </div>
    </>
  );
}

export default CreateAccount;