import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { fonts, colors, spacing, components } from "../../../constants/theme";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import { resetPassword } from "../../../api/authentication";
import { validateEmail } from "../../../utils/helpers";
import { INPUT_MAX_LENGTH } from "../../../constants/constants";
import { Header } from "../../../components/header";
import userIcon from "../../../assets/images/onboarding_icon.svg";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: colors.greyLight,
    display: "block",
    width: "50%",
    margin: "0 auto",
    outline: 0,
    position: 'relative',
    padding: '20px 40px 80px 40px',
    borderRadius: 10,
    backgroundSize: '100% auto',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      maxHeight: '90%',
      backgroundImage: 'none',
      width: "90%",
    },
  },
  
  title: {
    ...components.commonTitle,
    width: "55%",
    margin: "0 auto",
    marginTop: spacing.xLarge,
    marginBottom: spacing.medium,
    display: "block",
    "& > img":{
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: 40,
    marginTop: 32,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  form: {
    paddingTop: 20,
  },
  formControl: {
    width: '100%',
    marginBottom: '2em',
    backgroundColor: "white",
    '& .MuiOutlinedInput-input': {
      padding: '14px 14px',
      color: colors.blueGrey,
      fontSize: '14px',
      lineHeight: 1.42,
      fontFamily: fonts.bodyFontFamily,
      fontWeight: fonts.bodyFontWeight,
      [theme.breakpoints.down('xs')]: {
        fontSize: "16px",
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.bluePale,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.bluePale,
      top: 0,
      '& legend': {
        display: 'none !important',
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.bluePale,
        borderWidth: 1,
      }
    },
    '& .MuiInputLabel-root': {
      top: '-22px',
      fontFamily: fonts.subHeadingFontFamily,
      fontWeight: fonts.subHeadingFontWeight,
      fontSize: '14px',
      lineHeight: 1.43,
      color: colors.blueGrey,
      transform: 'none',
      '&.shrink': {
        transform: 'none'
      }
    }
  },

  inputError: {
    border: "1px solid red",
  },

  forgotPassword: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: 1.46,
    color: colors.blue,
    textDecoration: 'none',
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      lineHeight: 2,
    },
  },

  hr: {
    border: 'none',
    height: 1,
    marginTop: 28,
    marginBottom: 32,
    backgroundImage: 'linear-gradient(to right, #DEDEDE 33%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '3px 1px',
    backgroundRepeat: 'repeat-x',
  },

  button: {
    backgroundColor: "transparent",
    boxShadow: "none",
    marginTop: "auto",
    width: "330px",
    height: "45px",
    lineHeight: "45px",
    color: colors.blueDark,
    fontWeight: fonts.headingFontWeight,
    backgroundImage: `url(${primaryButton})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: "center",
    cursor: "pointer",
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
    },
    '&:hover': {
      backgroundImage: `url(${primaryButtonHover})`,
      backgroundColor: "transparent",
      boxShadow: "none",
      '& span': {
        color: colors.blue
      }
    },
  },

  greyButton: {
    backgroundImage: "unset",
    backgroundColor: colors.greyDark,
    cursor: "default",
  },

  leftArrow: components.commonLeftArrow,

  successMessage: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: colors.successMessage,
    color: "white",
    textAlign: "left",
    padding: "15px",
  },

  goBack: {
    margin: "20px 22%",
    paddingBottom: "40px",
    [theme.breakpoints.down('xs')]: {
      margin: "20px 5%",
      alignItems: "end",
      maxWidth: "85%",
    },
  },

}));

const ForgotPassword = () => {
  const classes = useStyles();

  const [email, setEmail] = useState();

  const [emailError, setEmailError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = () => {
    if(emailSent) return;
    
    let hasError=false;

    if(!validateEmail(email) || email.length > INPUT_MAX_LENGTH){
      setEmailError(true);
      hasError=true;
    }

    if(hasError) return;

    let data = {
      email: email,
    };
    
    resetPassword(data).then((response) => {
      if(response.data.status === "ERROR"){
        setSuccessMessage("ERROR: Something went wrong");
        return;
      }
      setSuccessMessage("Please check your email");
      setEmailSent(true);
      
    }).catch(e => {
      setSuccessMessage("ERROR: Something went wrong");
    });
  };

  return (
      <>
        <Header />
        <Typography className={classes.title} id="media"><img src={userIcon} alt="media icon" />Forgot password</Typography>
        <div className={classes.container}>
          <Grid container spacing={0}>
            {(successMessage != '')?<div className={classes.successMessage}>{successMessage}</div>:null}
            <Grid item sm={12}>
              <h6 className={classes.subTitle}>Enter your email below and we'll send you the next steps to reset your password</h6>
              <form noValidate className={classes.form}>
                <TextField id="email" label="Email" type="email" variant="outlined" className={(!emailError)?classes.formControl:classes.formControl + " " + classes.inputError} onChange={e => setEmail(e.target.value)} onKeyDown={e => handleKeyDown(e)}/>
                <hr className={classes.hr} />
                <Grid container justify="center">
                  <Button variant="contained" color="primary" className={(emailSent) ? classes.button + ' ' + classes.greyButton : classes.button} onClick={onSubmit}>
                    Submit
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
        <Grid item xs={6} alignItems="center" container className={classes.goBack}>
            <span className={classes.leftArrow}> {'<'} </span><a href="/login" className={classes.forgotPassword}>Return to login page</a>
        </Grid>
      </>
  );
}

export default ForgotPassword;
