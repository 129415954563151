import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Geonames from 'geonames.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import 'material-icons/iconfont/material-icons.scss';

import {
  saveVideo,
  removeVideo,
  getVideos,
} from "../../../api/videos";
import { searchGames } from "../../../api/games";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import { INPUT_MAX_LENGTH } from  "../../../constants/constants";
import downArrow from "../../../assets/images/down_arrow.png";
import Menu from "../components/menu";
import { isValidHttpUrl } from "../../../utils/helpers";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";

const geonames = new Geonames({
  username: 'alwaysaugust',
  lan: 'en',
  encoding: 'JSON'
});

let countries = [];

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    padding: "0px 20px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0px",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    [theme.breakpoints.down("xs")]: {
      marginLeft: spacing.medium,
    },
  },

  subTitleDescription: {
    fontSize: "16px",
    color: colors.blueDeep,
  },

  embedUrlWrapper: {
    backgroundColor: colors.greyLight,
    padding: "0px 10px 25px 10px",
    marginBottom: "0px !important",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    "& >span": {
      display: "block",
      color: colors.errorMessage,
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      borderRadius: 0,
      margin: "0px auto !important",
    },
  },

  form: {
    '& > *': {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    }
  },

  inputsWrapper: {
    display: "inline-block",
    overflow: "auto",
    marginBottom: spacing.medium,
    "& input:nth-child(1)": {
      marginRight: spacing.medium,
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        paddingBottom: "5px",
      },
    },
    "& .MuiInput-underline:before":{
      content: "none",
    },
    "& .MuiInput-underline:after":{
      content: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 0,
      "& .MuiFormControl-root": {
        width: "100%",
        marginBottom: spacing.small,
      },
    },
  },

  input: {
    backgroundColor: "white",
    height: "35px",
    width: "65%",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },

  autoCompleteInput: {
    backgroundColor: "white",
    height: "35px",
    width: "65%",
    borderRadius: "2px",
    boxShadow: "inset 0 0 1px 0px #888",
    paddingLeft: spacing.xSmall,
    "& input": {
      marginBottom: "0px !important",
      "&::before":{
        content: '""',
        display: "none",
      },
    },
  },

  select: {
    margin: "0",
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% center",
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    marginTop: spacing.small,
    marginBottom: "0px",
    width: "90%",
  },

  inputError: {
    border: "1px solid red",
  },

  button: {
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: `${colors.blue} !important`,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      color: `${colors.blueDark} !important`,
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
      "&:hover": {
        color: `${colors.blue} !important`,
      },
    },
  },

  addButtons: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "10px",
    "& span":{
      cursor: "pointer",
      display: "block",
      color: colors.blue,
      marginRight: "10px",
      paddingTop: "12px",
    }
  },

  addButton: {
    display: "inline-block",
    color: colors.blue,
    padding: "10px",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: colors.greyLight,
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

  reelsInputSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.greyLight,
    padding: "0px 20px 25px 0px",
    marginTop: "0px",
    "& select, & input": {
      marginBottom: spacing.medium,
    },
    "& label": {
      marginBottom: "2px",
    },
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
    },
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      padding: 0,
      width: "100%",
    },
  },

  reelsSection: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    backgroundColor: colors.greyLight,
    "& div":{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      backgroundColor: "white",
      borderRadius: "10px",
      margin: "10px",
      border: `1px solid ${colors.greyDark}`,
      webkitBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    mozBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    boxShadow: `0 8px 6px -6px ${colors.grey}`,
      "& a":{
        textDecoration: "none",
        color: colors.blue,
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span":{
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span:nth-child(2)":{
        textAlign: "center",
      },
      "& span:last-child":{
        textAlign: "right",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 auto",
      padding: 0,
    },
  },

  dashedLine: {
    width: "auto",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
  },

  dateInput: {
    width: "auto !important",
    boxShadow: "inset 0 0 2px 0px #888",
    "& > div:nth-child(1)": {
      width: "100%",
      paddingLeft: "0px !important",
      "& > .MuiInput-formControl": {
        height: "100%",
      },
    },
    "& input": {
      padding: 0,
      paddingLeft: spacing.xSmall,
      fontSize: "13px",
      marginRight: "0px !important",
      marginBottom: 0,
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% center",
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
  },

  noGames: {
    backgroundColor: colors.errorMessage,
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    padding: "15px",
    marginTop: spacing.xSmall,
    width: "fit-content",
  },
  
}));

const Reels = () => {
  const classes = useStyles();

  const [reels, setReels] = useState([]);
  const [reelTitle, setReelTitle] = useState();
  const [reelType, setReelType] = useState();
  const [reelsInputSection, setReelsInputSection] = useState(false);
  const [url, setUrl] = useState('');
  const [country, setCountry] = useState();
  const [countriesList, setCountriesList] = useState([]);
  const [gameDate, setGameDate] = useState();
  const [games, setGames] = useState([]);
  const [game, setGame] = useState(null);

  const [reelTitleError, setReelTitleError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [noGames, setNoGames] = useState(false);

  const openInputSection = () => {
    if(isValidHttpUrl(url)){
      setReelsInputSection(true);
      setUrlError(false);
    }else{
      setUrlError(true);
    }
  };

  const handleTypeChange = (value) => {
    setReelType(value);
  };
  
  const closeInputSection = () => {
    setReelsInputSection(false);
  };

  const saveUserReel = () => {
    let hasError = false;

    if(!isValidHttpUrl(url)) {
      setUrlError(true);
      hasError = true;
    }

    if(!reelTitle || reelTitle.length > INPUT_MAX_LENGTH) {
      setReelTitleError(true);
      hasError = true;
    }

    if(!reelType) {
      hasError = true;
    }

    if(reelType === '2' && game == null) {
      hasError = true;
    }

    if(hasError) return;

    let reel = {
      url: url,
      name: reelTitle,
      type: reelType,
      game: game,
    };

    saveVideo(reel).then((response) => {
      setUrl('');
      closeInputSection();
      getUserReels();
      setReelType();
    }).catch(e => {
      console.log(e);
    });

  };

  const getUserReels = () => {
    getVideos().then((response) => {
      setReels(response.data.videos);
    }).catch(e => {
      console.log(e);
    });
  }

  const removeUserReel = (reel_id) => {
    removeVideo(reel_id).then((response) => {
      getUserReels();
    }).catch(e => {
      console.log(e);
    });
  };

  const getCountries = async () => {
    if(countries.length === 0){
      countries = await geonames.countryInfo({});
    }
    return countries;
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleGameDateChange = (event) => {
    let date = moment(event).format("YYYY-MM-DD");
    setGameDate(date);
    getGames(date);
  };

  const getGames = (date) => {
    
    let data = {
      country: country,
      date: date,
    };

    searchGames(data).then(response => {
      if(response.data.games.length === 0) {
        setNoGames(true);
        setGames([]);
      }else{
        setNoGames(false);
        setGames(response.data.games);
      }
      
    });
  };

  useEffect(() => {
    getUserReels();
    getCountries().then(countries => {
      setCountriesList(countries.geonames);
    });
  }, []);

  return (
      <div className={classes.content}>
        <Menu />
        <div className={classes.profileContainer}>
          <div className={classes.form} noValidate autoComplete="off">
            <Typography className={classes.subTitle}>Share your game reels and highlights</Typography>
            <Typography className={classes.subTitleDescription}>Showcase your best plays and highlights on your profile</Typography>
            <div className={classes.embedUrlWrapper}>
              <FormControl className={classes.label}>
                <span>Embed URL</span>
              </FormControl>
              <input type="text" placeholder="https://www.youtube.com/watch?v=8xRz8ra9mdI" value={url} className={(!urlError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setUrl(e.target.value)} />
              <div className={classes.addButton} onClick={openInputSection}>Add</div>
              {(urlError)?<span>Please enter a valid Youtube or Vimeo url</span>:null}
            </div>

          {
            (reelsInputSection) ?
            <div className={classes.reelsInputSection}>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <label>Title</label>
                </FormControl>
                <input type="text" className={(!reelTitleError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setReelTitle(e.target.value)} />
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Player's reel or game highlight</span>
                  Put something here don't know what yet but soon we'll know I think I need to make this big
                </FormControl>
                <RadioGroup onChange={e => handleTypeChange(e.target.value)}>
                  <FormControlLabel value="1" control={<Radio color={colors.blueDark}/>} label="Reel" />
                  <FormControlLabel value="2" control={<Radio color={colors.blueDark}/>} label="Highlight" />
                </RadioGroup>
              </form>
              {
                (reelType === '2') ?
                <form className={classes.form} noValidate autoComplete="off">
                  <div className={classes.dashedLine}></div>
                  <Typography className={classes.subTitle}>Find the game related to the embedded highlight</Typography>
                  <FormControl style={{marginRight:spacing.large,width: "auto"}}>
                    <label>Country where the game took place</label>
                    <select className={classes.input + " " + classes.select} onChange={e => handleCountryChange(e)}>
                      <option value={''}></option>
                      {
                        (countriesList) ?
                          countriesList.map(country => {
                            return <option id={country.geonameId} value={country.countryCode}>{country.countryName}</option>
                          })
                        :null
                      }
                    </select>
                    {
                    (country) ?
                      <div className={classes.inputsWrapper}>
                        <FormControl className={classes.dateInput}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker disableFuture="true" invalidLabel="" invalidDateMessage="" format="yyyy-MM-DD" openTo="year" placeholder="Select date when game took place" className={classes.input} onChange={date => handleGameDateChange(date)} />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </div>
                      :null
                    }
                    {
                      (games.length > 0) ?
                      <>
                        <label>Game of highlight</label>
                        <Autocomplete
                          freeSolo
                          className={classes.autoCompleteInput}
                          onChange={(event, newValue) => {
                            if(newValue){
                              setGame(newValue.id);
                            }
                          }}
                          options={games}
                          getOptionLabel={(option) => option.gameName}
                          renderInput={(params) => <TextField  {...params} />}
                        />
                      </>
                      :(noGames) ?
                        <div className={classes.noGames}>No games on this date and country</div>
                      :null
                    }
                  </FormControl>
                  
                </form>
                :null
              }
              <div className={classes.addButtons}>
                <div className={classes.button} onClick={saveUserReel}>
                    <span>Save</span>
                </div>
                <span onClick={closeInputSection}>Cancel</span>
              </div>
            </div>
            : null
          }
          
          {
            (reels.length > 0) ?
              <div className={classes.reelsSection}>
                <Typography className={classes.subTitle}>List of reels</Typography>
              {
                reels.map((reel, index) => {
                  return (
                    <div key={index}>
                      <a href={reel.url} alt={reel.name} target="_blank">{reel.name}</a>
                      <span>{(reel.type === 1) ? "Player's reel" : "Game highglights"}</span>
                      <span className={classes.siteLink} onClick={() => removeUserReel(reel.id)}>Remove</span>
                    </div>
                  );
                })
              }
              </div>
            : null
          }
        </div>
        </div>
      </div>
  );
}

export default Reels;