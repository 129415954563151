import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import jsonp from 'jsonp';
import queryString from 'query-string';

import { fonts, spacing, colors, components } from  "../constants/theme";
import primaryButton from "../assets/images/primary_button.png";
import primaryButtonHover from "../assets/images/primary_button_hover.png";
import facebook_logo from "../assets/images/facebook_small.svg";
import twitter_logo from "../assets/images/twitter_small.svg";
import linkedin_logo from "../assets/images/linkedin_small.svg";
import instagram_logo from "../assets/images/instagram_small.svg";

const useStyles = makeStyles((theme) => ({

  footer: {
    width: "auto",
    backgroundColor: colors.greyLight,
    padding: "40px",
    "& > div:nth-child(1)": {
    display: "flex",
    justifyContent: "space-between",
    color: colors.blueGrey,
    lineHeight: 1.5,
    "& > div:nth-child(1)": {
      display:"flex",
      flexDirection: "column",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        textAlign: "center",
      },
      "& > span:nth-child(1)": {
        color: colors.blueDeep,
        fontSize: "16px",
        marginBottom: spacing.xSmall,
      },
      "& > span:nth-child(2)": {
        fontSize: "14px",
      },
      "& > span:nth-child(3)": {
        "& > a": {
          textDecoration: "none",
          "& > img": {
            marginRight: spacing.small,
          },
        },
        marginTop: spacing.medium,
      },
    },
    "& > div:nth-child(2)": {
      display:"flex",
      flexDirection: "column",
      width: "50%",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginTop: "20px",
      },
      "& > span:nth-child(1)": {
        color: colors.blueDeep,
        fontSize: "16px",
        marginBottom: spacing.xSmall,
        marginLeft: spacing.small,
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "normal",
    },
  },
  "& > div:nth-child(2)": {
    display: "flex",
    width: "100%",
    margin: "50px 0px 10px 0px",
    justifyContent: "space-between",
    "& div:nth-child(1)": {
      display: "flex",
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "15px",
      "& > a":{
        textDecoration: "none",
        color: colors.blue,
      },
      "& > span":{
        margin: "0px 10px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    "& div:nth-child(2)": {
      color: colors.blueGrey,
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        marginTop: spacing.medium,
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "normal",
    },
  },
  },

  form: {
    "& > *": {
      margin: theme.spacing(2),
    },
    "& span":{
      paddingTop: "5px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",

    }
  },

  button: {
    width: "250px",
    height: "40px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    marginTop: spacing.medium,
    '& span': {
      cursor: "pointer",
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: 3,
      fontSize: "12px",
      fontWeight: fonts.headingFontWeight,
      textDecoration: "none",
      color: colors.blueDark,
      width: "100%",
      "&:hover": {
        color: colors.blue,
      },
    },
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
    },
  },

  input: components.commonInput,

  inputError: {
    border: "1px solid red",
  },

  newsletterMessage: {
    fontSize: "12px",
    color: colors.blueGrey,
    cursor: "default !important",
    lineHeight: "1 !important",
    "&:hover": {
      color: colors.blueGrey,
    },
  },


}));

export const Footer = ({...props}) => {
  const classes = useStyles(props);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [newsletterMessage, setNewsletterMessage] = useState('');

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const subscribe = () => {

    if(email === '' || firstName === '' || lastName === ''){
      setFirstNameError(true);
      setLastNameError(true);
      setEmailError(true);
      return;
    } else{
      setFirstNameError(false);
      setLastNameError(false);
      setEmailError(false);
    }

    const formData = {
      EMAIL: email,
      FNAME: firstName,
      LNAME: lastName,
    };

    jsonp(`https://americanfootballinternational.us6.list-manage.com/subscribe/post-json?u=5a17c9a6eadfbc15f26bddc8a&amp;id=37995d0cb0&${queryString.stringify(formData)}`,
      { param: 'c'},
     (err, data) => {
      setNewsletterMessage('You are now subscribed!')
    });
  };

  return (
    <div className={classes.footer}>
        <div>
        <div>
          <span>About Us</span>
          <span>
            American Football International is the essential resource of international American football.
          </span>
          <span>
            <a href={'http://www.twitter.com/@AFIReview'} target="_parent"><img src={twitter_logo} alt="twitter"/></a>
            <a href={'http://www.facebook.com/AmericanFootballInternational'} target="_parent"><img src={facebook_logo} alt="facebook"/></a>
            <a href={'http://www.instagram.com/afi_review'} target="_parent"><img src={instagram_logo} alt="instagram"/></a>
            <a href={'http://www.linkedin.com/company/american-football-international'} target="_parent"><img src={linkedin_logo} alt="linkedin"/></a>
          </span>
        </div>
        <div>
          <span>Subscribe to our Newsletter</span>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl>
              <label>First Name</label>
              <input type="text" className={(!firstNameError)?classes.input:classes.input + " " + classes.inputError} name="firstName" onChange={e => setFirstName(e.target.value)} />
            </FormControl>
            <FormControl>
              <label>Last Name</label>
              <input type="text" className={(!lastNameError)?classes.input:classes.input + " " + classes.inputError} name="lastName" onChange={e => setLastName(e.target.value)}  />
            </FormControl>
            <FormControl>
              <label>Email</label>
              <input type="text" className={(!emailError)?classes.input:classes.input + " " + classes.inputError} name="email" onChange={e => setEmail(e.target.value)}  />
            </FormControl>
            <div className={classes.button} style={{display:'inline-block', marginTop: "30px"}}>
              <span onClick={subscribe}>SUBMIT</span>
              <span className={classes.newsletterMessage}>{newsletterMessage}</span>
            </div>
          </form>
        </div>
        </div>

        <div>
          <div>
            <a href={`${process.env.REACT_APP_BLOG_URL}/about-afi/`} target="_parent">About Us</a>
            <span>|</span>
            <a href={`${process.env.REACT_APP_BLOG_URL}/sitemap.xml`} target="_parent">Site Map</a>
            <span>|</span>
            <a href={`${process.env.REACT_APP_BLOG_URL}/terms-and-conditions/`} target="_parent">Terms of Services</a>
            <span>|</span>
            <a href={`${process.env.REACT_APP_BLOG_URL}/privacy-policy/`} target="_parent">Privacy Policy</a>
          </div>
          <div>&#169;American Football International LLC {new Date().getFullYear()}, All Rights Reserved.</div>
        </div>
      </div>
  );
};