import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Faq from "react-faq-component";
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';

import { fonts, spacing, colors, components } from  "../../../constants/theme";
import { ONBOARDING_ROUTES } from  "../../../constants/routes";
import { USER_TYPE } from  "../../../constants/constants";
import { Header } from  "../../../components/header";
import { Footer } from  "../../../components/footer";
import HeroBackground from  "../../../assets/images/home_hero.jpg";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import benefit1 from "../../../assets/images/benefit_1.svg";
import benefit2 from "../../../assets/images/benefit_2.svg";
import benefit3 from "../../../assets/images/benefit_3.svg";
import benefitsIcon from "../../../assets/images/benefits_icon.svg";
import greyBackground from "../../../assets/images/grey_angled_background.png";
import bottomImage from "../../../assets/images/home_bottom_image.jpg";
import coach from "../../../assets/images/coach.png";
import manager from "../../../assets/images/manager.png";
import agent from "../../../assets/images/agent.png";
import fan from "../../../assets/images/fan.png";
import patrickEsume from "../../../assets/images/patrickEsume.jpg";
import adamRita from "../../../assets/images/adamRita.png";
import jordanNeumann from "../../../assets/images/jordanNeumann.png";
import "../../../assets/css/home.scss";

const faqData = {
  rows: [
      {
          title: "What is AFI?",
          content: `American Football International (or AFI) is the world's leading resource for global American football news and information. And we are stepping up our game. Through Upletic, our database of athletes and coaches, we aim to provide our community with a deeper and all-inclusive experience.`,
      },
      {
          title: "Why should I register and create a profile?",
          content: "AFI is visited by of thousands of American football enthusiasts EVERY DAY. Chief among those are coaches, scouts, recruiters, league executives and most of all the FANS! Your profile is your opportunity to showcase yourself and be seen.",
      },
      {
          title: "Can you get me a scholarship or job in American football?",
          content: `YOU CAN get a scholarship, or grant, or job in American football. Although we do not place athletes or coaches, our audience consists of industry leaders, GLOBALLY! Whether your goal earn a position in a North American school, or pursue a career as a global sports star decision-makers are reading and searching AFI. `,
      },
  ],
};

const faqStyles = {
  rowTitleTextSize: "14px",
  rowTitleColor: colors.blueGrey,
  arrowColor: colors.blue,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    flexDirection: "column",
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "0 auto",
    },
  },

  hero: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    marginTop: "-81px",
    background: `url(${HeroBackground})`,
    backgroundRepeat: "no-repeat",
    height: "800px",
    width: "100%",
    marginBottom: spacing.xxxxLarge,
    [theme.breakpoints.up("xl")]: {
      backgroundSize: "cover",
      height: "1200px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "1024px",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("md")]: {
      height: "680px",
      backgroundSize: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      height: "400px",
      background: "none",
      backgroundColor: colors.blueDeep,
    },
  },

  heroContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "50px",
    marginTop: `-40px`,
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bold",
    color: "white",
    width: "690px",
    left: "40px",
    "& > div:nth-child(1)":{

    },
    "& > div:nth-child(2)":{
      width: "100%",
      borderTop: "1px dashed",
      borderColor: colors.orange,
      marginTop: spacing.xSmall,
      marginBottom: spacing.xSmall,
    },
    "& > div:nth-child(3)":{
      fontSize: "22px",
      letterSpacing: 1,
      [theme.breakpoints.down("lg")]: {
        fontSize: "17px",
      },
    },
    "& > div:nth-child(4)":{
      margin: "40px auto",
    },
    [theme.breakpoints.up("xl")]: {
      marginRight: "150px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "30px",
      width: "550px",
      marginRight: "10px",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "50px",
      marginTop: "-40px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      marginTop: "80px",
    }
  },

  button: {
    width: "250px !important",
    height: "40px",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    marginTop: spacing.medium,
    "& a": {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: 4,
      fontSize: "12px",
      fontWeight: fonts.headingFontWeight,
      textDecoration: "none",
      color: colors.blueDark,
      width: "100%",
      "&:hover": {
        color: colors.blue,
      },
    },
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
    },
  },

  about: {
    width: "50%",
    margin: "0 auto",
    textAlign: "center",
    fontSize: "13px",
    lineHeight: 1.5,
    color: colors.blueGrey,
    "& > div": {
      fontSize: "22px",
      fontWeight: 800,
      color: colors.blueDeep,
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    }
  },

  benefits: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "13px",
    margin: "0 auto",
    width: "85%",
    marginBottom: spacing.xxxxLarge,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& > div": {
      display: "flex",
      padding: "35px 15px",
      width: "28%",
      height: "230px",
      minHeight: "230px",
      border: `1px solid ${colors.greyLight}`,
      borderRadius: "10px",
      flexDirection: "column",
      lineHeight: 1.5,
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginTop: spacing.medium,
      },
      "& > div": {
        fontSize: "18px",
        color: colors.blueDeep,
        textAlign: "center",
        margin: `${spacing.large}px 0px ${spacing.small}px 0px`,
        width: "100%",
      },
      "& > img": {
        margin: "0 auto",
        width: "60px",
        height: "60px",
      },
    },
    "& > div:nth-child(3)":{
      "& > img": {
        width: "91px",
      },
    },
  },

  title: {
    ...components.commonTitle,
    marginTop: spacing.xxxLarge,
    marginBottom: spacing.xLarge,
    marginLeft: "100px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "30px",
      marginBottom: spacing.xSmall,
    },
    "& > img":{
      width: "40px",
      height: "40px",
      marginRight: "10px",
    },
  },

  carousel: {
    background: `url(${greyBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "auto",
    padding: "90px 0px",
    marginBottom: spacing.xxxxLarge,
    position: "relative",
    width: "100%",
    "& > div": {
      display: "-webkit-box",
      display: "flex",
      flexDirection:"column",
      width: "100%",
      "& > span": {
        display: "block",
        fontSize: "24px",
        fontWeight: 800,
        margin: "0 auto",
        marginBottom: spacing.xxLarge,
        color: colors.blueDeep,
        textAlign: "center",
        width: "90%",
      },
    },
  },

  secondAbout: {
    display: "flex",
    flexDirection: "row",
    background: `url(${bottomImage})`,
    backgroundRepeat: "no-repeat",
    height: "725px",
    width: "100%",
    marginBottom: spacing.xxxxLarge,
    [theme.breakpoints.up("xl")]: {
      backgroundSize: "cover",
      height: "1024px",
    },
    [theme.breakpoints.down("lg")]: {
      backgroundSize: "cover",
      height: "900px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundSize: "contain",
      height: "700px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      background: "none",
      backgroundColor: colors.blueDeep,
      padding: "50px 0px",
      backgroundSize: "cover",
    },
  },

  secondAboutContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "100px",
    textAlign: "left",
    fontSize: "24px",
    fontWeight: 800,
    color: "white",
    width: "575px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      fontSize: "18px",
      margin: "0 auto",
      padding: "20px",
      "& > div": {
        margin: "0 auto",
        marginTop: "20px",
      },
    },
  },

  typesOfAccounts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "center",
    margin: "0 auto",
    flexFlow: "wrap",
    width: "85%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
    "& > span": {
      display: "block",
      fontSize: "24px",
      fontWeight: 800,
      marginBottom: spacing.medium,
      color: colors.blueDeep,
      width: "100%",
    },
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "49%",
      height: "215px",
      border: `1px solid ${colors.bluePale}`,
      borderRadius: "10px",
      marginBottom: spacing.xSmall,
      marginTop: spacing.xSmall,
      color: colors.blueGrey,
      webkitBoxShadow: `-2px 5px 5px 1px ${colors.bluePale}`,
      boxShadow: `-2px 5px 5px 1px ${colors.bluePale}`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right",
      backgroundSize: "33%",
      [theme.breakpoints.down("sm")]: {
        backgroundImage: "unset !important",
      },
      "& > span": {
        fontSize: "18px",
        fontWeight: "bold",
        margin: "15px 10px 20px 20px",
      },
      "& > div": {
        width: "70%",
        fontSize: "14px",
        margin: "0px 20px",
        lineHeight: 1.5,
        [theme.breakpoints.down("md")]: {
          width: "80%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "90%",
        },
      },
      "& > a": {
        textDecoration: "none",
        padding: "10px 20px",
        textAlign: "center",
        backgroundColor: colors.greyLight,
        borderRadius: "20px",
        fontSize: "12px",
        width: "fit-content",
        margin: "20px",
        color: "#2c499c",
      },
      "&:nth-child(odd)": {
        marginLeft: spacing.xSmall,
      },
      "&:nth-child(even)": {
        marginRight: spacing.xSmall,
      },
      
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        margin: `0px 0px ${spacing.medium} 0px !important`,
        height: "initial",
        minHeight: "220px",
        "&:nth-child(odd)": {
          marginLeft: '0px!important',
        },
        "&:nth-child(even)": {
          marginRight: '0px!important',
        },
      },
    },
  },

  contactUs: {
    width: "85%",
    margin: "40px auto",
    backgroundColor: colors.greyLight,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: "10px",
    padding: "50px 0px",
    "& > div:nth-child(1)": {
      display: "flex",
      fontSize: "22px",
      color: colors.blueDeep,
    },
    "& > div:nth-child(2)": {
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: 0,
      "& > div:nth-child(1)": {
        fontSize: "18px",
        margin: "20px 0",
        width: "80%",
      }
    },
  },

  faq: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    marginBottom: "40px",
    width: "75%",
    "& > span": {
      display: "block",
      fontSize: "22px",
      fontWeight: 800,
      margin: "0 auto",
      marginBottom: spacing.medium,
      color: colors.blueDeep,
      textAlign: "center",
    },
  },

  footer: {
    width: "auto",
    backgroundColor: colors.greyLight,
    display: "flex",
    justifyContent: "space-between",
    padding: "40px",
    color: colors.blueGrey,
    lineHeight: 1.5,
    "& > div:nth-child(1)": {
      display:"flex",
      flexDirection: "column",
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        textAlign: "center",
      },
      "& > span:nth-child(1)": {
        color: colors.blueDeep,
        fontSize: "16px",
        marginBottom: spacing.xSmall,
      },
      "& > span:nth-child(2)": {
        fontSize: "14px",
      },
      "& > span:nth-child(3)": {
        marginTop: spacing.medium,
        "& > img": {
          marginRight: spacing.small,
        },
      },
    },
    "& > div:nth-child(2)": {
      display:"flex",
      flexDirection: "column",
      width: "50%",
      fontSize: "14px",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginTop: "20px",
      },
      "& > span:nth-child(1)": {
        color: colors.blueDeep,
        fontSize: "16px",
        marginBottom: spacing.xSmall,
        marginLeft: spacing.small,
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  form: {
    "& > *": {
      margin: theme.spacing(2),
    },
    "& span":{
      paddingTop: "5px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      
    }
  },

  input: components.commonInput,

  inputError: {
    border: "1px solid red",
  },

  dashedLine: {
    width: "65% !important",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    margin: `0px auto ${spacing.small}px auto`,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.small,
      marginBottom: spacing.small,
    },
  },

}));

const Home = (props) => {

  const classes = useStyles();
  const isMobile = isWidthDown('sm', props.width);

  return (
    <>
      <Header transparent={1}/>
      <div className={classes.container}>
        <div className={classes.hero}>
          <div className={classes.heroContent}>
            <div>THE ESSENTIAL RESOURCE</div>
            <div></div>
            <div>FOR INTERNATIONAL AMERICAN FOOTBALL</div>
            <div className={classes.button}>
              <a href={ONBOARDING_ROUTES.createAccount}>REGISTER</a>
            </div>
          </div>
        </div>
        <div className={classes.about}>
          <div>A SHOWCASE FOR THE GLOBAL ATHLETE</div>
          AFI powered by Upletic is the one-stop destination to international American football news for athletes, teams, coaches, agents, recruiters, schools and top international organizations.
        </div>
        <Typography className={classes.title} id="media"><img src={benefitsIcon} alt="media icon" />BENEFITS</Typography>
        <div className={classes.benefits}>
          <div>
            <img src={benefit1} alt="benefit 1" />
            <div>EXPAND YOUR NETWORK</div>
            Be listed among the world’s American football athletes. Network with every athlete, every team, in every country. Join our global database and authoritative sport's media destination for the American football market.
          </div>
          <div>
            <img src={benefit2} alt="benefit 2"/>
            <div>SHOWCASE YOURSELF</div>
            Optimize your profile with a bio, athletic CV, stats, highlights, game reel, featured images & videos, and more. In addition any of AFI's library of published content (200K articles and videos) featuring YOU is auto-populated into your profile!
          </div>
          <div>
            <img src={benefit3} alt="benefit 3"/>
            <div>ACCESS EXCLUSIVE CONTENT</div>
            Explore industry knowledge, relevant content, recruitment details, coaches, reports to make informed decisions.
          </div>
        </div>
        <div className={classes.carousel}>
          <div>
            <span>WHAT INDUSTRY LEADERS ARE SAYING</span>
            <Carousel
              showStatus={false}
              showThumbs={false}
              centerMode
              centerSlidePercentage={isMobile?100:90}
            > 
              <div>
                <div>
                "AFI is a top football source in Europe. Neutral, informative and most importantly not picking a side in a time where often times platforms like theirs are being used to feed different agendas!"
                </div>
                <div>
                  <img src={patrickEsume} />
                  <div>
                    <span>Patrick Esume</span>
                    <span>Commissioner of the European League of Football</span>
                    <span>European League of Football</span>
                  </div>
                </div>
              </div>

              <div>
                <div>
                    "AFI has done what was once considered impossible. They have brought the entire world of American football news to one platform. As a resource, AFI has done so much good for the game in countless nations across the globe."
                </div>
                <div>
                  <img src={jordanNeumann} />
                  <div>
                    <span>Jordan Neumann</span>
                    <span>Schwäbisch Hall Unicorns</span>
                    <span>Head Coach, German Football League</span>
                  </div>
                </div>
              </div>

              <div>
                <div>
                    "I recommend any player, coach, or fan of American football to join AFI. I love the articles on the players, coaches, and teams and they have all the latest stats, scores, stories, and streaming. They do a super job giving us football junkies the information that we need."
                </div>
                <div>
                  <img src={adamRita} />
                  <div>
                    <span>Adam Rita</span>
                    <span>Barcelona Dragons</span>
                    <span>Head Coach, European League of Football</span>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
          <div className={classes.dashedLine}></div>
          <div className={classes.button} style={{margin: `${spacing.medium}px auto 0px auto`}}>
            <a href={ONBOARDING_ROUTES.createAccount}>REGISTER</a>
          </div>
        </div>
        
        <div className={classes.typesOfAccounts}>
        <span>OTHER TYPE OF ACCOUNTS</span>
        <div style={{backgroundImage: `url(${coach})`}}>
          <span>COACH</span>
          <div>For those where teaching, inspiring, and leading teams to victory with X's and O's is the true playing field. Coaches are born, not made.</div>
          <a href={`${ONBOARDING_ROUTES.createAccount}?type=selectCoach`}>REGISTER AS A COACH</a>
        </div>
        <div style={{backgroundImage: `url(${manager})`}}>
          <span>TEAM MANAGER</span>
          <div>For assistant water carriers to general managers in charge. You manage your team.</div>
          <a href={`${ONBOARDING_ROUTES.createAccount}?type=selectManager`}>REGISTER AS A TEAM MANAGER</a>
        </div>
        <div style={{backgroundImage: `url(${fan})`}}>
          <span>THE ULTIMATE FAN</span>
          <div>Want access to the world of American football in the palm of your hand? Look no further, we built this for you, the Ultimate Fan!</div>
          <a href={`${ONBOARDING_ROUTES.createAccount}?userType=${USER_TYPE.fan}`}>REGISTER AS A FAN</a>
        </div>
        <div style={{backgroundImage: `url(${agent})`}}>
          <span>RECRUITER, AGENT AND SCOUT</span>
          <div>Looking to boost your roster or place some diamond-in-the-rough talent? This is for you.</div>
          <a style={{color:colors.grey}}>REGISTRATION COMING LATE 2021</a>
        </div>
      </div>
      <div className={classes.contactUs}>
        <div>
          NOT SURE WHAT YOU'RE LOOKING FOR? <br/> SCHEDULE A DEMO TODAY!
        </div>
        <div className={classes.button}>
            <a href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>CONTACT US</a>
        </div>
      </div>
      <div className={classes.secondAbout}>
        <div className={classes.secondAboutContent}>
          AFI IS THE WORLD's HOME FOR THE LATEST AMERICAN FOOTBALL NEWS!
          <div className={classes.button}>
            <a href={process.env.REACT_APP_BLOG_URL}>READ THE LATEST NEWS</a>
          </div>
        </div>
      </div>
      <div className={classes.faq}>
        <span>FREQUENT ASKED QUESTIONS</span>
        <Faq data={faqData} styles={faqStyles} />
      </div>
      <Footer />
    </div>
    </>
  )

  };

  export default withWidth()(Home);
