import React, {useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import history from "../history";

import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

import CreateAccount from "./pages/createAccount";
import ProfileSetup from "./pages/profileSetup";
import RecruitmentInformation from "./pages/recruitmentInformation";
import CoachInformation from "./pages/coachInformation";
import ManagerInformation from "./pages/managerInformation";
import { ONBOARDING_ROUTES, PATH_ONBOARDING } from  "../../constants/routes";
import ScrollToTop from "../../utils/scrollToTop";
import ActivateAccount from "./pages/activateAccount";
import { colors } from  "../../constants/theme";
import { Header } from  "../../components/header";

ReactGA.initialize(process.env.REACT_APP_GA);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    width: "80%",
    maxWidth: "900px",
    margin: "0 auto",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    }
  },
}));

const Onboarding = () => {
  const classes = useStyles();

  useEffect(() =>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <>
      <Header />
        <Router history={history}>
          <div className={classes.container}>
            <ScrollToTop/>
            <Switch>
              <Route exact path={ONBOARDING_ROUTES.createAccount}>
                <CreateAccount />
              </Route>
              <Route exact path={ONBOARDING_ROUTES.profileSetup}>
                <ProfileSetup />
              </Route>
              <Route exact path={ONBOARDING_ROUTES.recruitmentInformation}>
                <RecruitmentInformation />
              </Route>
              <Route exact path={ONBOARDING_ROUTES.coachInformation}>
                <CoachInformation />
              </Route>
              <Route exact path={ONBOARDING_ROUTES.managerInformation}>
                <ManagerInformation />
              </Route>
              <Route exact path={ONBOARDING_ROUTES.activateAccount}>
                <ActivateAccount/>
              </Route>
            </Switch>
          </div>
        </Router>

        <Feedback
          projectId={process.env.REACT_APP_FEEDER}
          email={true}
          primaryColor={colors.yellow}
          textColor={colors.blueDark}
          hoverBorderColor={colors.yellow}
        />
    </>
  );
}

export default Onboarding;