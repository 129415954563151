import API from "./api";

const getTeams = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getTeams?token=${token}`);
};

const saveTeamApplication = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/saveTeamApplication", data);
};

const getTeamManagerApplication = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getTeamManagerApplication?token=${token}`);
};

const removeTeamApplication = async (team_manager_id) => {
  let token = localStorage.getItem("token");
  let data = {team_manager_id:team_manager_id, token:token};
  return await API.post("/removeTeamApplication", data);
};

const approveTeamManager = async (application_id) => {
  let token = localStorage.getItem("token");
  let data = {application_id:application_id, token:token};
  return await API.post("/approveTeamManager", data);
};

const submitTeamApplication = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/submitTeamApplication", data);
};

const approveTeam = async (application_id) => {
  let token = localStorage.getItem("token");
  let data = {application_id:application_id, token:token};
  return await API.post("/approveTeam", data);
};

const getLeagues = async () => {
  let token = localStorage.getItem("token");
  return await API.get(`/getLeagues?token=${token}`);
};

const getTeamStatus = async (application_id) => {
  let token = localStorage.getItem("token");
  return await API.get(`/getTeamStatus?token=${token}&application_id=${application_id}`);
};

const updateTeamApplication = async (data) => {
  let token = localStorage.getItem("token");
  data.token = token;
  return await API.post("/updateTeamApplication", data);
};

const getTeamsSearch = async (data) => {
  let token = localStorage.getItem("token");
  return await API.get(`/getTeamsSearch?token=${token}&search=${data.search}&country=${data.country}&level=${data.level}&maxResults=${data.maxResults}&offset=${data.offset}`);
};

const getTeam = async (team_id) => {
  let token = localStorage.getItem("token");
  return await API.get(`/getTeam?token=${token}&team_id=${team_id}`);
};

export {
  getTeams,
  saveTeamApplication,
  getTeamManagerApplication,
  removeTeamApplication,
  approveTeamManager,
  submitTeamApplication,
  approveTeam,
  getLeagues,
  getTeamStatus,
  updateTeamApplication,
  getTeamsSearch,
  getTeam,
};