import React, {useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga';
import history from "../history";

import Feedback from "feeder-react-feedback";
import "feeder-react-feedback/dist/feeder-react-feedback.css";

import BasicInformation from "./pages/basicInformation";
import RecruitmentInformation from "./pages/recruitmentInformation";
import CoachInformation from "./pages/coachInformation";
import EducationAndAwards from "./pages/educationAndAwards";
import Reels from "./pages/reels";
import EditTeam from "./pages/editTeam";
import UserProfile from "./pages/userProfile";
import TeamProfile from "./pages/teamProfile";
import ChangePassword from './pages/changePassword';
import { PROFILE_ROUTES } from  "../../constants/routes";
import { spacing } from  "../../constants/theme";
import ScrollToTop from "../../utils/scrollToTop";
import { Header } from  "../../components/header";
import { colors } from  "../../constants/theme";

ReactGA.initialize(process.env.REACT_APP_GA);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
    maxWidth: "1024px",
    margin: "0 auto 0 auto",
  },
}));

const Profile = () => {
  const classes = useStyles();

  useEffect(() =>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <>
      <Header />
        <Router history={history}>
          <div className={classes.container}>
            <ScrollToTop/>
            <Switch>
              <Route exact path={PROFILE_ROUTES.basicInformation}>
                <BasicInformation />
              </Route>
              <Route exact path={PROFILE_ROUTES.recruitmentInformation}>
                <RecruitmentInformation />
              </Route>
              <Route exact path={PROFILE_ROUTES.coachInformation}>
                <CoachInformation />
              </Route>
              <Route exact path={PROFILE_ROUTES.educationAndAwards}>
                <EducationAndAwards />
              </Route>
              <Route exact path={PROFILE_ROUTES.reels}>
                <Reels />
              </Route>
              <Route exact path={PROFILE_ROUTES.editTeam}>
                <EditTeam />
              </Route>
              <Route exact path={PROFILE_ROUTES.userProfile}>
                <UserProfile />
              </Route>
              <Route exact path={PROFILE_ROUTES.teamProfile}>
                <TeamProfile />
              </Route>
              <Route exact path={PROFILE_ROUTES.changePassword}>
                <ChangePassword />
              </Route>
              <Route exact path="/">
                <BasicInformation />
              </Route>
            </Switch>
          </div>
        </Router>

        <Feedback
          projectId={process.env.REACT_APP_FEEDER}
          email={true}
          primaryColor={colors.yellow}
          textColor={colors.blueDark}
          hoverBorderColor={colors.yellow}
        />
    </>
  );
}

export default Profile;