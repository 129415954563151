export const PATH_DASHBOARD = "/dashboard";
export const PATH_ONBOARDING = "/onboarding";
export const PATH_PROFILE = "/profile";
export const PATH_ADMIN = "/admin";

export const HOME_ROUTES = {
  login: `/login`,
  forgotPassword: `/forgot_password`,
  resetPassword: `/reset_password`,
};

export const ONBOARDING_ROUTES = {
  createAccount: `${PATH_ONBOARDING}/create_account`,
  profileSetup: `${PATH_ONBOARDING}/profile_setup`,
  recruitmentInformation: `${PATH_ONBOARDING}/recruitment_info`,
  coachInformation: `${PATH_ONBOARDING}/coach_info`,
  managerInformation: `${PATH_ONBOARDING}/manager_info`,
  activateAccount: `${PATH_ONBOARDING}/activate_account`,
};

export const PROFILE_ROUTES = {
  basicInformation: `${PATH_PROFILE}/basic_information`,
  recruitmentInformation: `${PATH_PROFILE}/recruitment_information`,
  coachInformation: `${PATH_PROFILE}/coach_information`,
  educationAndAwards: `${PATH_PROFILE}/education_awards`,
  reels: `${PATH_PROFILE}/reels`,
  editTeam: `${PATH_PROFILE}/edit_team`,
  userProfile: `${PATH_PROFILE}/user_profile`,
  teamProfile: `${PATH_PROFILE}/team_profile`,
  changePassword: `${PATH_PROFILE}/change_password`
};

export const ADMIN_ROUTES = {
  approveManager: `${PATH_ADMIN}/approve_manager`,
};

export const DASHBOARD_ROUTES = {
  searchPlayers: `${PATH_DASHBOARD}/search_players`,
  searchCoaches: `${PATH_DASHBOARD}/search_coaches`,
  searchTeams: `${PATH_DASHBOARD}/search_teams`,
  games: `${PATH_DASHBOARD}/games`,
};