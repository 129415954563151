import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Geonames from 'geonames.js';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'material-icons/iconfont/material-icons.scss';

import { sendApprovalEmail } from "../../../api/users";
import {
  getTeams,
  saveTeamApplication,
  getTeamManagerApplication,
  removeTeamApplication,
  getLeagues,
  submitTeamApplication,
} from "../../../api/teams";
import { MANAGING_POSITIONS, LEVELS, MONTHS } from  "../../../constants/constants";
import step2 from "../../../assets/images/step_2.png";
import { spacing, colors, components } from  "../../../constants/theme";
import downArrow from "../../../assets/images/down_arrow.png";
import upArrow from "../../../assets/images/up_arrow.png";
import twitter_logo from "../../../assets/images/twitter_logo.png";
import facebook_logo from "../../../assets/images/facebook_logo.png";
import instagram_logo from "../../../assets/images/instagram_logo.svg";
import linkedin_logo from "../../../assets/images/linkedin_logo.png";
import { toBase64, checkExtension } from "../../../utils/helpers";

const geonames = new Geonames({
  username: 'alwaysaugust',
  lan: 'en',
  encoding: 'JSON'
});

const CustomSwitch = withStyles(components.commonCustomSwitch)(Switch);

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    marginTop: spacing.large,
    overflowX: "hidden",
  },

  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  step: {
    width: "100px",
    margin: "0 auto",
    marginBottom: spacing.medium,
  },

  title: {
    ...components.commonTitle,
    margin: "0 auto",
    marginBottom: spacing.large,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    }
  },

  subTitle: {
    ...components.commonSubtitle,
    marginTop: spacing.xLarge,
    paddingLeft: spacing.small,
    [theme.breakpoints.down("xs")]: {
      fontSize: "26px",
    }
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    padding: "20px 20px 30px 20px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      padding: "10px"
    }
  },

  newTeamContainer: {
    display: "flex",
    width: "80%",
    "& label":{
      fontSize: "12px",
      marginBottom: "5px",
    },
    "& input":{
      width: "100%",
    },
    "& div:nth-child(2)": {
      marginLeft: spacing.medium,
    },
    "& div:nth-child(3)": {
      marginLeft: spacing.medium,
    },
    "& textarea": {
      width: "100%",
      height: "70px",
      resize: "none",
      paddingLeft: spacing.xSmall,
      "&::placeholder":{
        paddingLeft: "0px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  form: {
    '& > *': {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    }
  },

  inputsWrapper: {
    marginTop: "10px",
    display: "inline-block",
    "& input:nth-child(2)": {
      marginLeft: spacing.medium,
    },
    
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      marginLeft: "0px",
      float: "none",
    }
  },

  input: {
    backgroundColor: "white",
    height: "35px",
    width: "250px",
    borderRadius: "2px",
    paddingLeft: spacing.xSmall,
    boxShadow: "inset 0 0 1px 0px #888",
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },

  autoCompleteInput: {
    backgroundColor: "white",
    width: "250px",
    height: "35px",
  },

  buttonsInput: components.commonButtonInput,

  select: {
    margin: "0",
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  buttonsInputWrapper: {
    display: "inline-block",
    position: "relative",
    "& input":{
      backgroundColor:"white",
    },
    "& div": {
      display: "block",
      position: "absolute",
      width: "20px",
      height: "10px",
      paddingLeft: "5px",
      textAlign: "center",
      color: "white",
      lineHeight: "10px",
      cursor: "pointer",
    },
    "& #weightUp, #heightUp":{
      right: "0px",
      top: "5px",
      background: `url(${upArrow})`,
      backgroundRepeat: "no-repeat",
    },
    "& #weightDown, #heightDown": {
      right: "0px",
      top: "20px",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
    }
  },

  label: {
    marginLeft: "0px",
    fontSize: "13px",
  },

  addButton: {
    padding: "10px 15px",
    width: "150px",
    borderRadius: "25px",
    textAlign: "center",
    fontSize: "14px",
    color: colors.blueDark,
    cursor: "pointer",
    backgroundColor: colors.greyDark,
    "& span": {
      color: colors.blue,
      fontSize: "24px",
      float: "left",
      lineHeight: "0.6",
    },
  },

  addTeamSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.greyDark,
    "& select": {
      marginBottom: "5px",
    },
    "& label": {
      marginTop: "15px",
      paddingBottom: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  addTeamSectionButtons: {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "10px",
    "& span":{
      cursor: "pointer",
      display: "block",
      color: colors.blue,
      marginRight: "10px",
      paddingTop: "10px",
    }
  },

  addTeamButton: {
    color: colors.blue,
    padding: "10px",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: colors.greyLight,
  },

  inputError: {
    border: "1px solid red",
  },

  teamsSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.greyDark,
    "& div":{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      justifyContent: "space-between",
    },
    
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
    }
  },

  selectedTeamSection: {
    display: "flex",
    backgroundColor: "white",
    padding: "10px",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

  submitNewTeamPopupWrapper: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    backgroundColor: "transparent",
  },

  submitNewTeamPopup: {
    display: "flex",
    position: "relative",
    margin: "0 auto",
    top: "25%",
    flexDirection: "column",
    width: "80%",
    height: "auto",
    backgroundColor: colors.greyDark,
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "2px 3px 5px #999",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  closeButton: {
    display: "flex",
    flexDirection: "row-reverse",
    cursor: "pointer",
    float: "right",
    fontSize: "18px",
    color: colors.blueDark,
  },

  socialLogo: {
    marginTop: "8px",
    color: colors.blueDeep,
    fontSize: "14px",
    lineHeight: 4,
    "& img":{
      width: "30px",
    },
    "& span":{
      width: "30px",
    },
  },

  uploadPic: {
    display: "block",
    fontSize: "12px",
    border: "1px dashed",
    borderColor: colors.orange,
    padding: "20px",
    textAlign: "center",
    width: "100%",
    cursor: "pointer",
    "& span": {
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "14px",
    }
  },

  fileInput: {
    width: "0.1px",
	  height: "0.1px",
	  opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: "1",
  },
  
}));

let countries = [];

const ManagerInformation = () => {
  const classes = useStyles();
  const history = useHistory();

  const [countriesList, setCountriesList] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedTeamName, setSelectedTeamName] = useState();
  const [selectedTeamLocation, setSelectedTeamLocation] = useState();
  const [selectedTeamDivision, setSelectedTeamDivision] = useState();
  const [managingPosition, setManagingPosition] = useState();
  const [submitNewTeam, setSubmitNewTeam] = useState(false);
  
  const [lookingForGames, setLookingForGames] = useState(false);
  const [twitter, setTwitter] = useState();
  const [instagram, setInstagram] = useState();
  const [facebook, setFacebook] = useState();
  const [linkedin, setLinkedin] = useState();
  const [website, setWebsite] = useState();
  const [picName, setPicName] = useState();
  const [teamPic, setTeamPic] = useState();
  const [newTeamName, setNewTeamName] = useState();
  const [newTeamCountry, setNewTeamCountry] = useState();
  const [newTeamRegion, setNewTeamRegion] = useState();
  const [newTeamLeague, setNewTeamLeague] = useState();
  const [newTeamLevel, setNewTeamLevel] = useState();
  const [newTeamRole, setNewTeamRole] = useState();
  const [newTeamGender, setNewTeamGender] = useState();
  const [newTeamSeasonFrom, setNewTeamSeasonFrom] = useState();
  const [newTeamSeasonTo, setNewTeamSeasonTo] = useState();
  const [salaryFrom, setSalaryFrom] = useState();
  const [salaryTo, setSalaryTo] = useState();
  const [salaryNegotiable, setSalaryNegotiable] = useState(false);
  const [newTeamCar, setNewTeamCar] = useState(0);
  const [newTeamPhone, setNewTeamPhone] = useState(0);
  const [newTeamReturnFlight, setNewTeamReturnFlight] = useState(0);
  const [newTeamMeal, setNewTeamMeal] = useState(0);
  const [newTeamGym, setNewTeamGym] = useState(0);
  const [newTeamInsurance, setNewTeamInsurance] = useState(0);
  const [newTeamAccommodation, setNewTeamAccommodation] = useState(0);
  const [newTeamOtherBenefits, setNewTeamOtherBenefits] = useState(0);
  const [newTeamReference, setNewTeamReference] = useState();
  const [newTeamAbout, setNewTeamAbout] = useState();

  const [addTeamSection, setAddTeamSection] = useState(false);
  const [team, setTeam] = useState();

  const [selectedTeamError, setSelectedTeamError] = useState(false);
  const [managingPositionError, setManagingPositionError] = useState(false);
  const [newTeamError, setNewTeamError] = useState();
  const [newTeamLeagueError, setNewTeamLeagueError] = useState();

  const getCountries = async () => {
    if(countries.length === 0){
      countries = await geonames.countryInfo({});
    }
    return countries;
  };

  const getRegions = async (id) => {
    return await geonames.children({geonameId: id});
  };

  const handleCountryChange = (event) => {
    setNewTeamCountry(event.target.value);
    getRegions(event.target[event.target.selectedIndex].id).then(regions => {
      setRegionsList(regions.geonames);
    });
  };

  const openAddTeamSection = () => {
    setAddTeamSection(true);
  }

  const closeAddTeamSection = () => {
    setAddTeamSection(false);
  }

  const sendTeamApplication = () => {
    let hasError=false;
    
    if(!selectedTeam){
      setSelectedTeamError(true);
      hasError=true;
    }

    if(!managingPosition){
      setManagingPositionError(true);
      hasError=true;
    }

    if(hasError) return;

    let teamApplication = {
      team: selectedTeam,
      position: managingPosition,
    };

    saveTeamApplication(teamApplication).then((response) => {
      if(response.data.status === "ERROR"){
        alert(response.data.message);
      }
      closeAddTeamSection(false);
      getApplication();
      
    }).catch(e => {
      console.log(e);
    });

  }

  const removeUserTeamApplication = (id) => {
    removeTeamApplication(id).then((response) => {
      window.location.reload()
    }).catch(e => {
      console.log(e);
    });
    
  }

  const getUserTeams = () => {
    getTeams().then((response) => {
      setTeamList(response.data.teams);
    }).catch(e => {
      console.log(e);
    });
  }

  const resendInvitation = (application_id) => {
    sendApprovalEmail(application_id).then((response) => {
      if(response.data.status === "SUCCESS") alert("Email sent!")
    }).catch(e => {
      console.log(e);
    });
  };

  const getApplication = () => {
    getTeamManagerApplication().then((response) => {
      if(response.data.status === "ERROR"){
        console.log(response.data.message);
      }
      
      setTeam(response.data.team[0]);
    }).catch(e => {
      console.log(e);
    });
  };

  const toggleLookingForGames = (event, newValue) => {
    setLookingForGames(newValue);
  }

  const toggleSalaryNegotiable = (event, newValue) => {
    setSalaryNegotiable(newValue);
  }

  const handleUploadPic = async (event) => {
    let file = event.target.files[0];
    let pic =  await toBase64(file);

    if(!checkExtension(file.name)){
      alert("Only jpg and png allowed");
      return;
    }
    
    if(file.size > 500000){
      alert("Image too big");
      return;
    }

    setTeamPic(pic);
    setPicName(file.name);
  };

  const submitTeam = () => {
    let hasError = false;

    if(!newTeamName){
      setNewTeamError(true);
      hasError = true;
    }

    if(!newTeamLeague){
      setNewTeamLeagueError(true);
      hasError = true;
    }

    if(hasError) return;

    let newTeamData = {
      name: newTeamName,
      country: newTeamCountry,
      region: newTeamRegion,
      league: newTeamLeague,
      level: newTeamLevel,
      position: newTeamRole,
      gender: newTeamGender,
      seasonFrom: newTeamSeasonFrom,
      seasonTo: newTeamSeasonTo,
      lookingForGames: lookingForGames,
      car: newTeamCar,
      phone: newTeamPhone,
      returnFlight: newTeamReturnFlight,
      meal: newTeamMeal,
      gym: newTeamGym,
      insurance: newTeamInsurance,
      accommodation: newTeamAccommodation,
      otherBenefits: newTeamOtherBenefits,
      twitter: twitter,
      instagram: instagram,
      facebook:facebook,
      linkedin: linkedin,
      website: website,
      logo: teamPic,
      reference: newTeamReference,
      about: newTeamAbout,
      expectedSalaryUpper: salaryFrom,
      expectedSalaryLower: salaryTo,
      salaryNegotiable: salaryNegotiable,
    };

    submitTeamApplication(newTeamData).then((response) => {
      if(response.data.status === "ERROR"){
        alert(response.data.message);
      }else{
        alert("Team application submitted");
      }

      getApplication();
      setSubmitNewTeam(false);

    },[getApplication]).catch(e => {
      console.log(e);
    });
  };

  useEffect(() => {
    if(!localStorage.getItem("loggedIn")){
      history.push("/");
    }

    getUserTeams();
    getApplication();

    getCountries().then(countries => {
      setCountriesList(countries.geonames);
    });

    getLeagues().then((response) => {
      setLeaguesList(response.data.leagues)
    }).catch(e => {
      console.log(e);
    });

  }, [history]);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <img src={step2} className={classes.step} alt="step 2"/>
          <Typography className={classes.title}>SETUP YOUR MANAGEMENT INFORMATION</Typography>
        </div>
        <div className={classes.profileContainer}>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label}>
              <span>Join an existing team</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <div className={classes.addButton} onClick={openAddTeamSection}>
                <span>+</span>
                ADD
              </div>
            </div>
          </form>
          <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.label} >
              <span>Add a team</span>
            </FormControl>
            <div className={classes.inputsWrapper}>
              <div className={classes.addButton} onClick={e => setSubmitNewTeam(true)}>
                <span>+</span>
                ADD
              </div>
            </div>
          </form>
          </div>
          {
            (team) ?
            <div className={classes.teamsSection}>
                <div>
                  <span>{team.name}</span>
                  <span>{team.league}</span>
                  <span>{team.position}</span>
                  <span>{(team.status === 1) ? "Approved" : "Pending Approval"}</span>
                  {
                    (team.status === 0) ?
                      <>
                        <span className={classes.siteLink} onClick={() => resendInvitation(team.id)}>Resend Invitation</span>
                        <span className={classes.siteLink} onClick={() => removeUserTeamApplication(team.id)}>Remove</span>
                      </>
                    : null
                  }
                </div>
            </div>
            :null
          }

          {
            (addTeamSection) ?
              <div className={classes.addTeamSection}>
                <form className={classes.form} noValidate autoComplete="off">
                  <FormControl>
                    <label>Find Team</label>
                    <Autocomplete
                      freeSolo
                      className={(!selectedTeamError) ? classes.autoCompleteInput : classes.autoCompleteInput + " " + classes.inputError}
                      onChange={(event, newValue) => {
                        if(newValue){
                          setSelectedTeam(newValue.id);
                          setSelectedTeamName(newValue.name);
                          setSelectedTeamLocation(newValue.location);
                          setSelectedTeamDivision(newValue.division_name);
                        }
                      }}
                      options={teamList}
                      getOptionLabel={(option) => option.name}
                      value={selectedTeam}
                      renderInput={(params) => <TextField  {...params} />}
                    />
                  </FormControl>
                    
                  {
                    (selectedTeam) ?
                    <>
                      <div className={classes.selectedTeamSection}>
                        <span>{selectedTeamName}</span>
                        <span>{selectedTeamLocation}</span>
                        <span>{selectedTeamDivision}</span>
                        <span className={classes.siteLink} onClick={() => setSelectedTeam()}>Remove</span>
                      </div>
                      <FormControl>
                        <label>Position</label>
                        <select className={(!managingPositionError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setManagingPosition(e.target.value)}>
                          <option value=""></option>;
                          {
                            MANAGING_POSITIONS.map(position => {
                              return <option value={position}>{position}</option>;
                            })
                          }
                        </select>
                      </FormControl>
                    </>
                    : null
                  }
                </form>
                <div className={classes.addTeamSectionButtons}>
                  <span className={classes.addTeamButton} onClick={sendTeamApplication}>Submit for Confirmation</span>
                  <span onClick={closeAddTeamSection}>Cancel</span>
                </div>
              </div>
              :null
          }
      </div>
        {
          (submitNewTeam) ?
          <div className={classes.submitNewTeamPopupWrapper}>
            <div className={classes.submitNewTeamPopup}>
            <div className={classes.closeButton} onClick={e => setSubmitNewTeam(false)}>X</div>
            <div className={classes.content}>
              <div className={classes.titleWrapper}>
                <Typography className={classes.title}>Submit a new Team</Typography>
              </div>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer} style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>Team Name</label>
                    <input type="text" className={(!newTeamError) ? classes.input : classes.input + " " + classes.inputError } onChange={e => setNewTeamName(e.target.value)} />
                    </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}>
                  <FormControl>
                    <label>What is your team's country</label>
                    <select className={classes.input + " " +classes.select} onChange={e => handleCountryChange(e)}>
                      <option value=""></option>
                      {
                        (countriesList) ?
                          countriesList.map(country => {
                            return <option key={country.geonameId} id={country.geonameId} value={country.countryCode}>{country.countryName}</option>
                          })
                        :null
                      }
                    </select>
                  </FormControl>
                  <FormControl>
                    <label>What is your team's region</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamRegion(e.target.value)}>
                        <option value=""></option>
                        {
                          regionsList.map(region => {
                            return <option key={region.name} value={region.name}>{region.name}</option>
                          })
                        }
                      </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer} style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>League</label>
                      <select className={(!newTeamLeagueError) ? classes.input + " " +classes.select :classes.input + " " +classes.select +" " + classes.inputError } onChange={e => setNewTeamLeague(e.target.value)}>
                        <option value=""></option>
                        {
                          leaguesList.map(league => {
                          return <option key={league.id} value={league.id}>{league.full_name}</option>
                          })
                        }
                      </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer} style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>Team level</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamLevel(e.target.value)}>
                        <option value=""></option>
                        {
                          LEVELS.map(level => {
                            return <option key={level} value={level}>{level}</option>
                          })
                        }
                      </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer} style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>Your role in the team</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamRole(e.target.value)}>
                        <option value=""></option>
                        {
                        MANAGING_POSITIONS.map(position => {
                          return <option key={position} value={position}>{position}</option>;
                        })
                      }
                      </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer} style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>Team Gender</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamGender(e.target.value)}>
                        <option value=""></option>
                        <option value="m">Male</option>
                        <option value="f">Female</option>
                      </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}>
                  <FormControl>
                    <label>Season From</label>
                    <select className={classes.input + " " +classes.select} onChange={e => setNewTeamSeasonFrom(e.target.value)}>
                      <option value=""></option>
                      {
                        MONTHS.map(month => {
                          return <option key={month} value={month}>{month}</option>
                        })
                      }
                    </select>
                  </FormControl>
                  <FormControl>
                    <label>Season To</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamSeasonTo(e.target.value)}>
                        <option value=""></option>
                        {
                        MONTHS.map(month => {
                          return <option key={month} value={month}>{month}</option>
                        })
                      }
                      </select>
                  </FormControl>
                  <FormControl>
                    <label>Is the team looking for games?</label>
                    <FormControlLabel
                    control={<CustomSwitch checked={lookingForGames} value={lookingForGames} onChange={toggleLookingForGames} />}
                    label={(lookingForGames ? "Yes" : "No")}
                    />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
              <div className={classes.newTeamContainer}>
                <FormControl className={classes.label}>
                  <label>Salary from</label>
                  <input type="number"
                  className={classes.input}
                  placeholder="From (USD)"
                  value={salaryFrom}
                  maxLength="10"
                  onChange={e => {
                    let { value, maxLength } = e.target;
                    let prev = value.slice(0,-1);
                    value = (value.length > Number(maxLength)) ? prev : value;
                    setSalaryFrom(value)
                  }}
                  />
                  </FormControl>
                  <FormControl className={classes.label}>
                    <label>Salary to</label>
                    <input type="number"
                    className={classes.input}
                    placeholder="To (USD)"
                    value={salaryTo}
                    maxLength="10"
                    onChange={e => {
                      let { value, maxLength } = e.target;
                      let prev = value.slice(0,-1);
                      value = (value.length > Number(maxLength)) ? prev : value;
                      setSalaryTo(value)
                    }}
                    />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}>
                  <FormControl>
                    <label>Is salary negotiable?</label>
                    <FormControlLabel
                      control={<CustomSwitch checked={salaryNegotiable} value={salaryNegotiable} onChange={toggleSalaryNegotiable} />}
                      label={(salaryNegotiable ? "Yes" : "No")}
                    />
                  </FormControl>
                </div>
              </form>
              <Typography className={classes.subTitle}>Benefits offered</Typography>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}>
                  <FormControl>
                    <label>Car</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamCar(e.target.value)}>
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                  </FormControl>
                  <FormControl>
                    <label>Phone</label>
                      <select className={classes.input + " " +classes.select} onChange={e => setNewTeamPhone(e.target.value)}>
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                  </FormControl>
                  <FormControl>
                    <label>Return flight</label>
                    <select className={classes.input + " " +classes.select} onChange={e =>setNewTeamReturnFlight(e.target.value)}>
                      <option value=""></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}>
                  <FormControl>
                    <label>Daily meal</label>
                      <select className={classes.input + " " +classes.select} onChange={e =>setNewTeamMeal(e.target.value)}>
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                  </FormControl>
                  <FormControl>
                    <label>Gym membership</label>
                      <select className={classes.input + " " +classes.select} onChange={e =>setNewTeamGym(e.target.value)}>
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                  </FormControl>
                  <FormControl>
                    <label>Insurance</label>
                    <select className={classes.input + " " +classes.select} onChange={e =>setNewTeamInsurance(e.target.value)}>
                      <option value=""></option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}>
                  <FormControl>
                    <label>Accommodation</label>
                      <select className={classes.input + " " +classes.select} onChange={e =>setNewTeamAccommodation(e.target.value)}>
                        <option value=""></option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}  style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>Other benefits</label>
                    <input type="text" className={classes.input} placeholder="Dental plan, Medical Coverage" onChange={e =>setNewTeamOtherBenefits(e.target.value)} />
                  </FormControl>
                </div>
              </form>
              <Typography className={classes.subTitle}>Team's Social media</Typography>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.socialLogo}>
                  <img src={twitter_logo} alt="twitter logo"/>
                </FormControl>
                <div className={classes.inputsWrapper}>
                  <FormControl>
                      <input type="text" placeholder="https://twitter.com/username" className={classes.input} onChange={e => setTwitter(e.target.value)} />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.socialLogo}>
                  <img src={instagram_logo} alt="instagram logo"/>
                </FormControl>
                <div className={classes.inputsWrapper}>
                  <FormControl>
                      <input type="text" placeholder="https://instagram.com/username" className={classes.input} onChange={e => setInstagram(e.target.value)} />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.socialLogo}>
                  <img src={facebook_logo} alt="facebook logo"/>
                </FormControl>
                <div className={classes.inputsWrapper}>
                  <FormControl>
                      <input type="text" placeholder="https://facebook.com/username" className={classes.input} onChange={e => setFacebook(e.target.value)} />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.socialLogo}>
                  <img src={linkedin_logo} alt="linkedin logo"/>
                </FormControl>
                <div className={classes.inputsWrapper}>
                  <FormControl>
                    <input type="text" placeholder="https://linkedin.com/username" className={classes.input} onChange={e => setLinkedin(e.target.value)} />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.socialLogo}>
                <span className="material-icons">language</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                  <FormControl>
                      <input type="text" placeholder="Website" className={classes.input} onChange={e => setWebsite(e.target.value)} />
                  </FormControl>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <div className={classes.newTeamContainer}  style={{flexDirection: "column"}}>
                  <FormControl>
                    <label>Upload profile pic</label>
                    <div className={classes.inputsWrapper} style={{width:"55%",marginLeft:"0px"}}>
                      <label className={classes.uploadPic}>
                        <input type="file" className={classes.fileInput} onChange={e => handleUploadPic(e)}/>
                        <span>Upload</span>
                        <br/>
                        {picName}
                      </label>
                    </div>
                  </FormControl>
                </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <div className={classes.newTeamContainer}  style={{flexDirection: "column"}}>
                <FormControl>
                  <label>Team Reference</label>
                  <input type="text" className={classes.input} placeholder="URL"  onChange={e => setNewTeamReference(e.target.value)}/>
                </FormControl>
              </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <div className={classes.newTeamContainer}  style={{flexDirection: "column"}}>
                <FormControl>
                  <label>About</label>
                  <textarea rows="4" placeholder="Lorem ipsum..." className={classes.input} onChange={e => setNewTeamAbout(e.target.value)}>
                  </textarea>
                </FormControl>
              </div>
            </form>
            <div className={classes.addTeamSectionButtons}>
              <span className={classes.addTeamButton} onClick={submitTeam}>Submit for Approval</span>
              <span onClick={e => setSubmitNewTeam(false)}>Cancel</span>
            </div>
          </div>
          </div>
          </div>
          :null
        }
    </>
  );
}

export default ManagerInformation;