import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

import { approveTeamManager, approveTeam, getTeamStatus } from "../../../api/teams";
import { verifyToken } from "../../../api/authentication";
import { spacing, colors, components } from  "../../../constants/theme";
import { USER_TYPE } from  "../../../constants/constants";

const queryString = require("query-string");

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    marginTop: spacing.large,
    textAlign: "center"
  },

  titleWrapper: {
    display: "block",
    width: "100%",
    marginBottom: spacing.xLarge,
  },

  title: {
    ...components.commonTitle,
    display: "inline-block",
    marginBottom: spacing.large,
  },

}));

const ApproveManager = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [teamStatus, setTeamStatus] = useState(0);
  const [managerStatus, setManagerStatus] = useState(0);
  const [application, setApplication] = useState(false);

  //get application id from url
  let param = queryString.parse(location.search);

  const approveNewManager = () => {
    approveTeamManager(param.application_id).then((response) => {
      if(response.data.status === "SUCCESS"){
        setManagerStatus(1);
      }
      else if(response.data.status === "ERROR"){
        alert(response.data.error);
      }
    });
  };

  const approveNewTeam = () => {
    approveTeam(param.application_id).then((response) => {
      if(response.data.status === "SUCCESS"){
        setTeamStatus(1);
      }
      else if(response.data.status === "ERROR"){
        alert(response.data.error);
      }
    });
  };

  useEffect(() => {

    //verify token and call approveManager
    verifyToken().then((response) => {
      if(response.data.status === "ERROR" || response.data.user.type != USER_TYPE.admin) history.push("/");

      getTeamStatus(param.application_id).then((response) => {
        if(response.data.message === "APPLICATION_NOT_FOUND"){
          setApplication(false);
        }else{
          setApplication(true);
          setTeamStatus(response.data.message.team_status);
          setManagerStatus(response.data.message.manager_status);
        }
      }).catch(e => {
        console.log(e);
      });
    }).catch(e => {
      window.location.href = "/login?redirect=admin/approve_manager";
    });

  }, [history, param.application_id]);

  return (
    <>
      <div className={classes.content}>

      {
        (application) ?
        <>
          <div className={classes.titleWrapper}>
            {
              (managerStatus === 0) ?
                <span style={{cursor:'pointer', textDecoration:"underline"}} onClick={approveNewManager}>Click here to approve Manager</span>
              : <Typography className={classes.title}>Team manager approved</Typography>
            }
          </div>

          <div className={classes.titleWrapper}>
            {
              (teamStatus === 0) ?
                <span style={{cursor:'pointer', textDecoration:"underline"}} onClick={approveNewTeam}>Click here to approve Team</span>
              : <Typography className={classes.title}>Team is active</Typography>
            }
          </div>
        </>
        : <div className={classes.titleWrapper}>
        
            <Typography className={classes.title}>Application not found</Typography>
        
          </div>
      }

      </div>
    </>
  );
}

export default ApproveManager;