import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from 'react-responsive';
import Typography from '@material-ui/core/Typography';
import 'material-icons/iconfont/material-icons.scss';

import { getGames } from "../../../api/games";
import { spacing, colors, components } from  "../../../constants/theme";
import { GameCard } from "../components/gameCard";
import gamesIcon from "../../../assets/images/games.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    width:"100%",
    "& > h1":{
      marginTop: 0,
    },
  },

  gamesWrapper: {
    width: "auto",
    display: "block",
    backgroundColor: colors.greyLight,
    marginBottom: spacing.xxLarge,
    padding: "15px",
    borderRadius: "5px",
    "& > div":{
      marginBottom: spacing.xxLarge,
      "& > h2":{
        color: colors.blueDeep,
        marginLeft: "40px",
      },
    },
  },

  leagueGames: {
    display: "flex",
    flexDirection: "row-reverse",
    height: "180px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  arrowButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "20px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: colors.blue,
    color: "white",
    "&:focus":{
      outline: "none",
    },
  },

  arrowButtonGrey: {
    backgroundColor: colors.greyLight,
  },

  off: {
    backgroundColor: colors.grey,
  },

  title: {
    ...components.commonTitle,
    marginBottom: spacing.medium,
    marginTop: spacing.large,
    marginLeft: spacing.xSmall,
    width: "auto",
    display: "flex",
    "& > img":{
      marginRight: "10px",
      [theme.breakpoints.down("xs")]: {
        width: "28px",
        height: "28px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    }
  },

}));

const Games = () => {
  const classes = useStyles();

  const [leaguesData, setLeaguesData] = useState([]);
  const [gamesPerLeague, setGamesPerLeague] = useState(4);
  const [isMobile, setIsMobile] = useState(false);

  const arrowButtonHandler = (league_id, move) => {
    let gameIndex = leaguesData.findIndex(x => x.league_id === league_id);
    let tempLeaguesData = [...leaguesData];
    tempLeaguesData[gameIndex].showLeftButton = 1;
    tempLeaguesData[gameIndex].showRightButton = 1;

    if(((tempLeaguesData[gameIndex].index + (move*2)) > tempLeaguesData[gameIndex].data.length-gamesPerLeague)){
      tempLeaguesData[gameIndex].showLeftButton = 0;
    }
    if((tempLeaguesData[gameIndex].index + (move*2) < 0)){
      tempLeaguesData[gameIndex].showRightButton = 0;
    }

    //if(((tempLeaguesData[gameIndex].index + (move)) < tempLeaguesData[gameIndex].data.length-gamesPerLeague) && (tempLeaguesData[gameIndex].index + (move)) >= 0){
      tempLeaguesData[gameIndex].index = tempLeaguesData[gameIndex].index + (move);
    //}

    setLeaguesData(tempLeaguesData);
  };

  const processGames = async (games) => {
    let leagues = [];
    let currentLeague = 0;
    let index = -1;
    let showRightButton = 0;
    let showLeftButton = 1;

    for await (let game of games){
      if(currentLeague !== game.league_id){
        index++;
        leagues.push({league_id:game.league_id,league_name:game.full_name, data:[], index:0, showRightButton:showRightButton, showLeftButton:showLeftButton});
        currentLeague = game.league_id;
        leagues[index].data.push(game);
      }else{
        leagues[index].data.push(game);
      }
    }

    return leagues;
  };

  const indexGames = async (leagues) => {
    
    leagues.map((league,key) => {
      let currentDate;
      league.data.map((game,index) => {
        let newDate = new Date();
        let today = newDate.toISOString().split('T')[0];
        
        if(game.game_date >= today && game.game_date != currentDate){
          league.index = index;
          currentDate = game.game_date;
        }
        
        if(league.index > (league.data.length - gamesPerLeague)){
          league.showLeftButton = 0;
        }else{
          league.showLeftButton = 1;
        }
        if(league.index > 0){
          league.showRightButton = 1;
        }
        
      })
    })
    return leagues;
  };

  const resize = () => {
    if(window.innerWidth <= 767){
      setGamesPerLeague(1);
      setIsMobile(true);
    }else{
      setGamesPerLeague(4);
      setIsMobile(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let response = await getGames();
      processGames(response.data.games).then(async response => {
        let indexedGames = await indexGames(response);
        setLeaguesData(indexedGames);
      });
    }
    fetchData();
    window.addEventListener("resize", resize.bind(this));
    resize();
    
  },[]);

  return (
    <>
      <div className={classes.content}>
      <Typography className={classes.title} name="media" id="media"><img src={gamesIcon} alt="media icon" />GAMES</Typography>
        <div className={classes.gamesWrapper}>
          {
            leaguesData.map((league,key) => {
              return (
                <div key={key}>
                  <h2>{league.league_name}</h2>
                  <div className={classes.leagueGames}>
                    <span className={(league.showRightButton)?classes.arrowButton:classes.arrowButton+' '+classes.off} onClick={(league.showRightButton) ? e => arrowButtonHandler(league.league_id,-1):null}> {'>'} </span>
                    {
                      league.data.map((game,index) => {
                        if((index >= league.index) && (index < (league.index + gamesPerLeague))){
                          let timeNow = new Date(new Date().toLocaleString("en-US", {timeZone: game.timezone}));
                          let gameTime = new Date(`${new Date(game.game_date).toISOString().split('T')[0]} ${game.game_time}`);
                          return(
                            <GameCard
                              key={index}
                              isMobile={(isMobile)?1:0}
                              homeTeam={game.home_team_name}
                              awayTeam={game.away_team_name}
                              homeTeamMascot={game.home_team_mascot}
                              awayTeamMascot={game.away_team_mascot}
                              homeTeamScore={game.home_team_score}
                              awayTeamScore={game.away_team_score}
                              homeTeamLogo={game.home_team_logo_url}
                              awayTeamLogo={game.away_team_logo_url}
                              gameDate={new Date(game.game_date).toISOString().split('T')[0]}
                              gameTime={game.game_time}
                              timezone={game.timezone}
                              futureGame={(gameTime > timeNow)?true:false}
                          />
                          );
                        }
                      })
                    }
                    <span className={(league.showLeftButton)?classes.arrowButton:classes.arrowButton+' '+classes.off} onClick={(league.showLeftButton) ? e => arrowButtonHandler(league.league_id,1):null}> {'<'} </span>
                  </div>
                </div>
              );
            })
          }
        </div>
    </div>
    </>
  );
}

export default Games;