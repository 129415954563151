import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { getUsers, getCitizenship } from "../../../api/users";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import downArrow from "../../../assets/images/down_arrow.png";
import { LEVELS, COACHING_POSITIONS, USER_TYPE } from  "../../../constants/constants";
import { calculateAge } from "../../../utils/helpers";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import helmetIcon from "../../../assets/images/helmet.svg";
import defaultPlayerLogo from "../../../assets/images/player.png";
import filterIcon from "../../../assets/images/filter_icon.svg";
import searchIcon from  "../../../assets/images/search_icon.svg";

const queryString = require("query-string");

let countries = [];

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    width: "100%",
    marginTop: spacing.xxxxLarge,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: spacing.xxxLarge,
    },
  },

  searchContainer: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    paddingRight: spacing.small,
    "& label":{
      paddingBottom: spacing.xSmall,
      paddingTop: spacing.xSmall,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      paddingRight: 0,
    },
  },

  mainContainer: {
    display: "flex",
    height: "550px",
    width: "75%",
    flexDirection: "column",
    borderRadius: "10px",
    overflowY: "auto",
    padding: "15px",
    backgroundColor: colors.greyLight,
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      height: "450px",
      margin: "0 auto",
      padding: "10px",
      marginTop: spacing.medium,
      borderRadius: 0,
    }
  },

  title: {
    ...components.commonTitle,
    width: "auto",
    display: "block",
    position: "absolute",
    display: "flex",
    "& > img":{
      marginRight: "10px",
      [theme.breakpoints.down("xs")]: {
        width: "28px",
        height: "28px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    }
  },

  subTitle: {
    ...components.commonSubtitle,
    marginTop: spacing.small,
    marginBottom: spacing.small,
    [theme.breakpoints.down("xs")]: {
      fontSize: "26px",
    }
  },

  input: {
    ...components.commonInputWithPlaceholder,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  searchInput: {
    width: "100%",
    [theme.breakpoints.down('xs')]: {
      width: "80%",
    },
  },

  searchInputWrapper: {
    display: "flex",
    "& > div": {
      width: "100%",
      "& > input":{
        width: "-moz-available",
        width: "-webkit-fill-available",
        width: "fill-available",
      },
      "& > span": {
        position: "absolute",
        marginLeft: "-25px",
        marginTop: "10px",
      },
    },
    "& > img":{
      display: "none",
      marginLeft: spacing.small,
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        width: "20px",
        height: "20px",
      },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },

  select: {
    margin: "0",
    marginBottom: spacing.small,
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "98% 50%",
    paddingLeft: spacing.xSmall,
    color: colors.grey,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  searchButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    "& > span:nth-child(1)":{
      lineHeight: 4,
      color: colors.blue,
      fontWeight: "bold",
      fontSize: "12px",
      fontWeight: fonts.headingFontWeight,
      [theme.breakpoints.down('xs')]: {
        lineHeight: 2,
      },
    },
  },

  searchButton: {
    color: colors.blue,
    padding: "10px !important",
    textAlign: "center",
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "50%",
    fontSize: "14px !important",
    fontWeight: fonts.headingFontWeight,
    lineHeight: 2,
    color: `${colors.blueDark} !important`,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
  },

  coach: {
    display: "flex",
    flexDirection: "row",
    fontSize: "13px",
    justifyContent: "space-between",
    backgroundColor: "white",
    boxShadow: "0px 0px 8px 0px rgba(76,79,120,0.3)",
    borderRadius: "5px",
    marginBottom: spacing.xSmall,
    cursor: "pointer",
    textAlign: "center",
    alignItems: "center",
    height: "35px",
    minHeight: "35px",
    "& span":{
      width: "100%",
      color: colors.blueGrey,
      fontWeight: "bold",
      fontSize: "12px",
      textAlign: "left",
    },
    "& span:nth-child(2)":{
      display: "flex",
      alignItems: "center",
      height: "100%",
      color: colors.blueLight,
      fontSize: "14px",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "none",
      marginLeft: spacing.small,
      "&::before":{
        content: '""',
        display: "block",
        zIndex: 0,
        marginLeft: "-18px",
        width: "10px",
        height: "35px",
        backgroundColor: "rgb(255, 255, 255)",
        transform: "skew(-6deg)",
      },
    },
    "& > div:nth-child(1)": {
      display:"flex",
      flex: "0 20.5%",
      "& img":{
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        width: "35px",
        minWidth: "35px",
        height: "35px",
        minHeight: "35px",
      },
    },
    "& img":{
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      width: "35px",
      minWidth: "35px",
      height: "35px",
      minHeight: "35px",
    },
  },

  mobileCoach: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    justifyContent: "space-between",
    backgroundColor: "white",
    boxShadow: "0px 0px 8px 0px rgba(76,79,120,0.3)",
    borderRadius: "5px",
    marginBottom: spacing.xSmall,
    cursor: "pointer",
    height: "auto",
    padding: "10px",
    width: "90%",
    margin: "0 auto",
    "& div:nth-child(1)": {
      display: "flex",
      flexDirection: "row",
      "& img":{
        borderRadius: "50%",
        width: "35px",
        minWidth: "35px",
        height: "35px",
        minHeight: "35px",
        marginRight: spacing.xSmall,
      },
      "& span":{
        width: "100%",
        color: colors.grey,
        fontWeight: "bold",
        fontSize: "14px",
        "& span":{
          fontSize: "20px",
          color: colors.blue,
        },
      },
    },
    "& > span":{
      width: "270px",
      color: colors.blueGrey,
      fontWeight: "bold",
      fontSize: "16px",
      marginLeft: spacing.xxxLarge,
      textOverflow: "ellipsis",
      overflowX: "hidden",
      whiteSpace: "nowrap",
    },
  },

  coachesHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: spacing.xSmall,
    fontSize: "11px",
    fontWeight: "bold",
    color: colors.blueGrey,
    "& span": {
      width: "100%",
    },
    "& span:nth-child(1)":{
      marginLeft: "55px",
    },
  },

  noCoaches: {
    fontSize: "28px",
    color: colors.grey,
    margin: "0 auto",
  },

  loadMoreButton: {
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: colors.grey,
    cursor: "pointer",
    textAlign: "center",
    margin: "0 auto",
    "&:hover": {
      backgroundColor: colors.greyLight,
    },
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    [theme.breakpoints.down("xs")]: {
      marginTop: spacing.small,
      marginBottom: spacing.small,
    },
  },

}));

const SearchCoaches = (props) => {
  const classes = useStyles();
  const location = useLocation();

  let param = queryString.parse(location.search);

  const [locations, setLocations] = useState([]);
  const [search, setSearch] = useState((param.search) ? param.search:'');
  const [level, setLevel] = useState('');
  const [position, setPosition] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [coaches, setCoaches] = useState([]);
  const [paginationDone, setPaginationDone] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const coachSearch = (type='') => {

    let searchData;

    searchData = {
      search: search,
      level: level,
      position: position,
      citizenship: citizenship,
      types: encodeURIComponent(JSON.stringify([USER_TYPE.professional_coach,USER_TYPE.college_coach])),
      maxResults: 20,
      offset: 0,
    };

    if(type === 'pagination'){
      searchData.offset = coaches.length;
    }else{
      setPaginationDone(false);
    }

    let tempList = [];

    getUsers(searchData).then(response => {
      if(type === 'pagination'){
        tempList = coaches;
        let newList = tempList.concat(response.data.users);
        if(tempList.length >= newList.length) setPaginationDone(true);
        setCoaches(newList);
      }else{
        setCoaches(response.data.users);
      }
      
    }).catch(e => {
      console.log(e);
    });
  };

  const getLocationInfo = async () => {
    if(countries.length === 0){
      countries = await getCitizenship();
    }
    return countries.data.citizenship;
  };

  const clearFilters = () => {
    setSearch('');
    setLevel('');
    setPosition('');
    setCitizenship('');
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const goToProfile = (slug) => {
    window.location.href = `/profile/user_profile?user=${slug}`;
  }

  useEffect(() => {
    getLocationInfo().then(countries => {
      setLocations(countries);
    });
    
    coachSearch();
  }, []);

  const isMobile = isWidthDown('sm', props.width);
  
  return (
    <>
      <Typography className={classes.title}><img src={helmetIcon} alt="coaches" />COACHES DIRECTORY</Typography>
      <div className={classes.content}>
        <div className={classes.searchContainer}>
        {!isMobile ?
          <>
          <div className={classes.searchButtonsContainer}>
            <span onClick={e => clearFilters()}>Clear Filter</span>
            <span className={classes.searchButton} onClick={e => coachSearch()}>SEARCH</span>
          </div>
          <div className={classes.dashedLine}></div>
          </>
          : null
        }
          {!isMobile?<label>SEARCH BY NAME</label>:null}
          <div className={classes.searchInputWrapper}>
            <div>
              <input type="text" placeholder="Search by name..." value={search} className={classes.input +' '+ classes.searchInput} onChange={e => setSearch(e.target.value)} />
              <span><img src={searchIcon} /></span>
            </div>
            <img src={filterIcon} onClick={e=>toggleFilters()} />
          </div>
          <div className={classes.dashedLine}></div>
          {
            ((isMobile && showFilters) || !isMobile) ?
            <>
            <label>OTHER</label>
            <select className={classes.input + " " +classes.select} value={level} onChange={e => setLevel(e.target.value)}>
            <option value="">Any level</option>
            {
              LEVELS.map(level => {
                return <option key={level} value={level}>{level}</option>
              })
            }
            </select>
            <select className={classes.input + " " +classes.select} value={position} onChange={e => setPosition(e.target.value)}>
              <option value="">Any position</option>
              {
                COACHING_POSITIONS.map(position => {
                  return <option key={position} value={position}>{position}</option>
                })
              }
            </select>
            <select className={classes.input + " " +classes.select} value={citizenship} onChange={e => setCitizenship(e.target.value)}>
              <option value="">Any citizenship</option>
              {
                locations.map((location,key) => {
                  return <option key={key} id={location.id} value={location.name}>{location.name}</option>
                })
              }
            </select>
            </>
            : null
          }

          <div className={classes.searchButtonsContainer}>
          {((isMobile && showFilters) || !isMobile) ?<span onClick={e => clearFilters()} style={{lineHeight:4}}>Clear Filters</span>:null}
            <span className={classes.searchButton} onClick={e => coachSearch()}>SEARCH</span>
          </div>
          {isMobile?null:<div className={classes.dashedLine}></div>}
        </div>
        <div className={classes.mainContainer}>
          <Typography className={classes.subTitle}>RESULTS</Typography>
          {
              (coaches.length > 0 && !isMobile) ?
                <>
                <div className={classes.coachesHeader}>
                  <span>Name</span>
                  <span>Citizenship</span>
                  <span>Position</span>
                  <span>Team</span>
                </div>
                  {
                    coaches.map((coach,key) => {
                      return (
                        <div key={key} className={classes.coach} onClick={e=>goToProfile(coach.slug)}>
                          <div>
                            <img src={(coach.profile_picture)?`${process.env.REACT_APP_API_ENDPOINT}/${coach.profile_picture}`:defaultPlayerLogo} />
                          </div>
                          <span>{coach.first_name + " " + coach.last_name}</span>
                          <span>{coach.citizenship}</span>
                          <span>{coach.playing_position}</span>
                          <span>{coach.team}</span>
                        </div>
                      );
                    })
                  }
                  {
                    (coaches.length >= 10 && !paginationDone) ?
                    <div className={classes.loadMoreButton} onClick={e => coachSearch('pagination')}>Load More</div>
                    :null
                  }
                </>
              : (coaches.length > 0 && isMobile) ?
              <>
              {
                    coaches.map((coach,key) => {
                      return (
                        <div key={key} className={classes.mobileCoach} onClick={e=>goToProfile(coach.slug)}>
                          <div>
                            <img src={(coach.profile_picture)?`${process.env.REACT_APP_API_ENDPOINT}/${coach.profile_picture}`:defaultPlayerLogo} />
                            <span>
                              {coach?.team ?? "Free Agent"}<br/>
                              <span>{coach.first_name + " " + coach.last_name}</span>
                            </span>
                          </div>
                          <div className={classes.dashedLine}></div>
                          <span>Citizenship: {coach.citizenship}</span>
                          <span>Position: {coach.playing_position}asdfkl</span>
                          
                        </div>
                      );
                    })
                  }
              </>
               : <div className={classes.noCoaches}>No coaches found</div>
            }
        </div>
      </div>
    </>
  );
}

export default withWidth()(SearchCoaches);