const toFeet = (n) => {
  let realFeet = ((n*0.393700) / 12);
  let feet = Math.floor(realFeet);
  let inches = Math.round((realFeet - feet) * 12);
  return feet + "'" + inches + '"';
};

const toLbs = (n) => {
  return Math.ceil((n * 2.20462262185)) + "lbs";
};

export let HEIGHTS=[];
for(let h=150; h<=250; h++){
  let height = h + "cm - " + toFeet(h);
  HEIGHTS.push(height);
}

export let WEIGHTS=[];
for(let w=60; w<=250; w++){
  let weight = w + "kg - " + toLbs(w);
  WEIGHTS.push(weight);
}

export const POSITIONS = [
  "QB (Quarterback)",
  "RB (Running Back)",
  "FB (Full Back)",
  "WR (Wide Receiver)",
  "TE (Tight End)",
  "OT (Offensive Tackle)",
  "C (Center)",
  "DT (Defensive Tackle)",
  "DE (Defensive End)",
  "LB (Linebacker)",
  "CB (Cornerback)",
  "S (Safety)",
  "K (Kicker)",
  "P (Punter)",
  "LS (Long Snapper)",
  "Guard (G)",
];

export const LEVELS = [
  "Pro",
  "Semi-Pro",
  "Amateur",
  "College",
  "High School",
];

export const COACHING_POSITIONS = [
  "Head Coach",
  "Offensive Coordinator",
  "Defensive Coordinator",
  "Defensive Line Coach",
  "Offensive Line Coach",
  "Linebacker Coach",
  "Quarterback Coach",
  "Running Backs Coach",
  "Secondary Coach",
  "Special Teams Coach",
  "Tight Ends Coach",
  "Wide Receivers Coach",
];

export const MANAGING_POSITIONS = [
  "President",
  "Vice President",
  "General Manager",
  "Team Manager",
];

let currentYear = new Date().getFullYear();
export let YEARS=[];
for(let y=1970; y<=currentYear; y++) YEARS.push(y);

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LANGUAGES = [
  {value:"AF", label:"Afrikaans"},
  {value:"SQ", label:"Albanian"},
  {value:"AR", label:"Arabic"},
  {value:"HY", label:"Armenian"},
  {value:"EU", label:"Basque"},
  {value:"BN", label:"Bengali"},
  {value:"BG", label:"Bulgarian"},
  {value:"CA", label:"Catalan"},
  {value:"KM", label:"Cambodian"},
  {value:"ZH", label:"Chinese (Mandarin)"},
  {value:"HR", label:"Croatian"},
  {value:"CS", label:"Czech"},
  {value:"DA", label:"Danish"},
  {value:"NL", label:"Dutch"},
  {value:"EN", label:"English"},
  {value:"ET", label:"Estonian"},
  {value:"FJ", label:"Fiji"},
  {value:"FI", label:"Finnish"},
  {value:"FR", label:"French"},
  {value:"KA", label:"Georgian"},
  {value:"DE", label:"German"},
  {value:"EL", label:"Greek"},
  {value:"GU", label:"Gujarati"},
  {value:"HE", label:"Hebrew"},
  {value:"HI", label:"Hindi"},
  {value:"HU", label:"Hungarian"},
  {value:"IS", label:"Icelandic"},
  {value:"ID", label:"Indonesian"},
  {value:"GA", label:"Irish"},
  {value:"IT", label:"Italian"},
  {value:"JA", label:"Japanese"},
  {value:"JW", label:"Javanese"},
  {value:"KO", label:"Korean"},
  {value:"LA", label:"Latin"},
  {value:"LV", label:"Latvian"},
  {value:"LT", label:"Lithuanian"},
  {value:"MK", label:"Macedonian"},
  {value:"MS", label:"Malay"},
  {value:"ML", label:"Malayalam"},
  {value:"MT", label:"Maltese"},
  {value:"MI", label:"Maori"},
  {value:"MR", label:"Marathi"},
  {value:"MN", label:"Mongolian"},
  {value:"NE", label:"Nepali"},
  {value:"NO", label:"Norwegian"},
  {value:"FA", label:"Persian"},
  {value:"PL", label:"Polish"},
  {value:"PT", label:"Portuguese"},
  {value:"PA", label:"Punjabi"},
  {value:"QU", label:"Quechua"},
  {value:"RO", label:"Romanian"},
  {value:"RU", label:"Russian"},
  {value:"SM", label:"Samoan"},
  {value:"SR", label:"Serbian"},
  {value:"SK", label:"Slovak"},
  {value:"SL", label:"Slovenian"},
  {value:"ES", label:"Spanish"},
  {value:"SW", label:"Swahili"},
  {value:"SV", label:"Swedish "},
  {value:"TA", label:"Tamil"},
  {value:"TT", label:"Tatar"},
  {value:"TE", label:"Telugu"},
  {value:"TH", label:"Thai"},
  {value:"BO", label:"Tibetan"},
  {value:"TO", label:"Tonga"},
  {value:"TR", label:"Turkish"},
  {value:"UK", label:"Ukrainian"},
  {value:"UR", label:"Urdu"},
  {value:"UZ", label:"Uzbek"},
  {value:"VI", label:"Vietnamese"},
  {value:"CY", label:"Welsh"},
  {value:"XH", label:"Xhosa"},
];

export const INPUT_MAX_LENGTH = 50;

export const USER_TYPE = {
  professional_player: 1,
  prospect_player: 2,
  professional_coach: 3,
  college_coach: 4,
  professional_team_manager: 5,
  college_team_manager: 6,
  fan: 7,
  admin: 8,
};
