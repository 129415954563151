import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  savePlayingExperience,
  getPlayingExperiences,
  removePlayingExperience,
  updateRecruitmentInfo,
  getRecruitmentInfo,
  getCitizenship,
} from "../../../api/users";
import { getTeams } from "../../../api/teams";
import { fonts, spacing, colors, components } from  "../../../constants/theme";
import { HEIGHTS, WEIGHTS, POSITIONS, LEVELS, YEARS } from  "../../../constants/constants";
import primaryButton from "../../../assets/images/primary_button.png";
import primaryButtonHover from "../../../assets/images/primary_button_hover.png";
import downArrow from "../../../assets/images/down_arrow.png";
import upArrow from "../../../assets/images/up_arrow.png";
import Menu from "../components/menu";

const CustomSwitch = withStyles(components.commonCustomSwitch)(Switch);

const CustomSlider = withStyles((theme) => ({
  root: {
    width: "400px",
    marginLeft: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
      marginLeft: "10px",
    },
  },

  thumb: {
    height: "20px",
    width: "20px",
    backgroundColor: colors.blue,
    marginTop: "-7px",
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    color: colors.grey,
    height: "6px",
    borderRadius: "4px",
  },
  rail: {
    color: colors.grey,
    opacity: 1,
    height: "6px",
    borderRadius: "4px",
  },
}))(Slider);

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: spacing.large,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  subTitle: {
    ...components.commonSubtitle,
    marginBottom: "0px !important",
    marginTop: spacing.xSmall,
  },

  profileContainerWrapper:{
    display: "flex",
    flexDirection: "column",
    width: "70%",
    padding: "10px 20px",
    backgroundColor: colors.greyLight,
    borderRadius: "5px",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "95%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "95%",
    },
  },

  buttons: {
    display: "flex",
    marginLeft: "auto",
  },

  button: {
    margin: "0 auto",
    marginRight: spacing.xSmall,
    width: "200px",
    height: "45px",
    lineHeight: "45px",
    color: colors.blueDark,
    backgroundImage: `url(${primaryButton})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    textAlign: "center",
    cursor: "pointer",
    color: colors.blueDark,
    fontWeight: fonts.headingFontWeight,
    "&:hover": {
      backgroundImage: `url(${primaryButtonHover})`,
      color: colors.blue,
    },
    '& span': {
      display: "inline-block",
      verticalAlign: "middle",
      lineHeight: "normal",
      fontSize: "14px",
      fontWeight: fonts.headingFontWeight,
    },
  },

  cancelButton: {
    textDecoration: "none",
    fontSize: "13px",
    fontWeight: "bold",
    color: colors.blue,
    marginTop: spacing.small,
    marginRight: spacing.small,
    cursor: "pointer",
  },

  dashedLine: {
    width: "100%",
    borderTop: "1px dashed",
    borderColor: colors.orange,
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },

  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      width: "100%",
    },
  },

  inputsWrapper: {
    marginTop: "10px",
    width: "570px",
    display: "inline-block",
    overflow: "hidden",
    "& input:nth-child(2)": {
      marginLeft: spacing.medium,
    },
    "& textarea": {
      width: "90%",
      height: "70px",
      resize: "none",
      paddingLeft: spacing.xSmall,
      "&::placeholder":{
        paddingLeft: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0px",
      float: "none",
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
  },

  input: {
    ...components.commonInput,
    "&::placeholder":{
      paddingLeft: spacing.xSmall,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "100%",
    },
  },

  autoCompleteInput: {
    backgroundColor: "white",
    width: "250px",
    height: "35px",
  },

  buttonsInput: {
    ...components.commonButtonInput,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  select: {
    margin: "0",
    "-webkitBoxSizing": "border-box",
    "-moz-box-sizing": "border-box",
    "box-sizing": "border-box",
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    backgroundColor: "white",
    background: `url(${downArrow})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% center",
    paddingLeft: spacing.xSmall,
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    },
  },

  buttonsInputWrapper: {
    display: "inline-block",
    position: "relative",
    width: "fit-content",
    "& input":{
      backgroundColor:"white",
    },
    "& div": {
      display: "block",
      position: "absolute",
      width: "20px",
      height: "10px",
      paddingLeft: "5px",
      textAlign: "center",
      color: "white",
      lineHeight: "10px",
      cursor: "pointer",
    },
    "& #weightUp, #heightUp":{
      right: "0px",
      top: "5px",
      background: `url(${upArrow})`,
      backgroundRepeat: "no-repeat",
    },
    "& #weightDown, #heightDown": {
      right: "0px",
      top: "20px",
      background: `url(${downArrow})`,
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  label: {
    color: colors.blueDeep,
    fontSize: "14px",
    marginTop: spacing.medium,
    marginBottom: 0,
    width: "100%",
  },

  addButton: {
    padding: "10px 15px",
    width: "150px",
    borderRadius: "25px",
    textAlign: "center",
    fontSize: "14px",
    color: colors.blueDark,
    cursor: "pointer",
    backgroundColor: colors.greyDark,
    "& span": {
      color: colors.blue,
      fontSize: "24px",
      float: "left",
      lineHeight: "0.6",
    },
  },

  playingExperiencesSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: colors.greyDark,
    paddingBottom: spacing.medium,
    "& select": {
      marginBottom: "5px",
    },
    "& label": {
      marginTop: "15px",
      paddingBottom: "3px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto",
      padding: "10px"
    }
  },

  inputError: {
    border: "1px solid red",
  },

  teamsSection: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    backgroundColor: colors.greyLight,
    "& div":{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      backgroundColor: "white",
      borderRadius: "10px",
      margin: spacing.xSmall,
      border: `1px solid ${colors.greyDark}`,
      webkitBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    mozBoxShadow: `0 8px 6px -6px ${colors.grey}`,
	    boxShadow: `0 8px 6px -6px ${colors.grey}`,
      "& a":{
        textDecoration: "none",
        color: colors.blue,
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span":{
        width: "33%",
        textOverflow: "ellipsis",
        overflowX: "hidden",
        whiteSpace: "nowrap",
      },
      "& span:nth-child(2)":{
        textAlign: "center",
      },
      "& span:last-child":{
        textAlign: "right",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },

  siteLink: {
    color: colors.blue,
    cursor: "pointer",
    textDecoration: "none",
  },

  successMessage: {
    width: "50%",
    backgroundColor: colors.successMessage,
    color: "white",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  errorMessage: {
    width: "50%",
    backgroundColor: colors.errorMessage,
    color: "white",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  notesError: {
    width: "fit-content",
    backgroundColor: colors.errorMessage,
    color: "white",
    padding: "10px",
    marginBottom: spacing.small,
  },
  
}));

let countries = [];

const RecruitmentInformation = () => {
  const classes = useStyles();
  const history = useHistory();

  const [locations, setLocations] = useState([]);
  const [heightPosition, setHeightPosition] = useState(0);
  const [weightPosition, setWeightPosition] = useState(0);

  const [currentHeight, setCurrentHeight] = useState(HEIGHTS[heightPosition]);
  const [currentWeight, setCurrentWeight] = useState(WEIGHTS[weightPosition]);

  const [position, setPosition] = useState('');
  const [level, setLevel] = useState('');
  const [onTeam, setOnTeam] = useState(false);
  const [lookingForTeam, setLookingForTeam] = useState(false);
  const [passportReady, setPassportReady] = useState(false);
  const [citizenship, setCitizenship] = useState('');
  const [salaryFrom, setSalaryFrom] = useState('');
  const [salaryTo, setSalaryTo] = useState('');
  const [salaryNegotiable, setSalaryNegotiable] = useState(false);
  const [notes, setNotes] = useState('');
  const [playingExperiencesSection, setPlayingExperiencesSection] = useState(false);
  const [fromYear, setFromYear] = useState('');
  const [toYear, setToYear] = useState('');
  const [playingPosition, setPlayingPosition] = useState('');
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();

  const [teamError, setTeamError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [playingPositionError, setPlayingPositionError] = useState(false);
  const [selectedTeamError, setSelectedTeamError] = useState(false);
  const [notesError, setNotesError] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const updateRecruitment = () => {

    if(notes.length > 200){
      setNotesError("There's a 200 character limit on this field")
      return;
    }

    let recruitmentData = {
      height: currentHeight,
      weight: currentWeight,
      position: position,
      level: level,
      currentlyOnTeam: onTeam,
      lookingForTeam: lookingForTeam,
      citizenship: citizenship,
      passportReady: passportReady,
      expectedSalaryUpper: salaryFrom,
      expectedSalaryLower: salaryTo,
      salaryNegotiable: salaryNegotiable,
      notes: notes,
    };

    updateRecruitmentInfo(recruitmentData).then((response) => {
      window.scrollTo(0,0);
      if(response.data.status === "SUCCESS"){
        setErrorMessage();
        setSuccessMessage("Recruitment information updated");
      }else{
        setSuccessMessage();
        setErrorMessage(response.data.error);
      }
    }).catch(e => {
      console.log(e);
    });
  };

  const getAllTeams = () => {
    getTeams().then((response) => {
      setTeamList(response.data.teams);
    }).catch(e => {
      console.log(e);
    });
  }

  const saveTeam = () => {
    let hasError=false;
    
    if(!selectedTeam){
      setSelectedTeamError(true);
      hasError=true;
    }
    if(!fromYear || !toYear || (fromYear > toYear)){
      setYearError(true);
      hasError=true;
    }

    if(!playingPosition){
      setPlayingPositionError(true);
      hasError=true;
    }

    if(hasError) return;

    let playingExperience = {
      team: selectedTeam,
      from: fromYear,
      to: toYear,
      position: playingPosition,
      currentTeam: currentTeam,
    };

    savePlayingExperience(playingExperience).then((response) => {
      getPlayerTeams();
      closePlayingExperiences();
    }).catch(e => {
      console.log(e);
    });
  }

  const removeTeam = (team_id) => {
    removePlayingExperience(team_id).then((response) => {
      getPlayerTeams();
    }).catch(e => {
      console.log(e);
    });
  }

  const getPlayerTeams = () => {
    getPlayingExperiences().then((response) => {
      setTeams(response.data.playingExperiences);
    }).catch(e => {
      console.log(e);
    });
  }
  
  const toggleOnTeam = (event, newValue) => {
    setOnTeam(newValue);
  }

  const toggleCurrentTeam = (event, newValue) => {
    setCurrentTeam(newValue);
  }

  const toggleLookingForTeam = (event, newValue) => {
    setLookingForTeam(newValue);
  }

  const togglePassportReady = (event, newValue) => {
    setPassportReady(newValue);
  }

  const toggleSalaryNegotiable = (event, newValue) => {
    setSalaryNegotiable(newValue);
  }
  
  const heightUp = () => {
    if(heightPosition === (HEIGHTS.length-1)) return;
    setHeightPosition(heightPosition+1);
  }

  const heightDown = () => {
    if(heightPosition === 0) return;
    setHeightPosition(heightPosition-1);
  }

  const handleHeightSliderChange = (event, newValue) => {
    if(newValue !== heightPosition) {
      setHeightPosition(newValue);
    }
  }

  const weightUp = () => {
    if(weightPosition === (WEIGHTS.length-1)) return;
    setWeightPosition(weightPosition+1);
  }

  const weightDown = () => {
    if(weightPosition === 0) return;
    setWeightPosition(weightPosition-1);
  }

  const handleWeightSliderChange = (event, newValue) => {
    if(newValue !== weightPosition) {
      setWeightPosition(newValue);
    }
  }

  const getLocationInfo = async () => {
    if(countries.length === 0){
      countries = await getCitizenship();
    }
    return countries.data.citizenship;
  };

  const openPlayingExperiences = () => {
    setPlayingExperiencesSection(true);
  }

  const closePlayingExperiences = () => {
    setPlayingExperiencesSection(false);
  }

  useEffect(() => {
    setTimeout(() => setSuccessMessage(false), 5000);
  },[successMessage]);

  useEffect(() => {
    getRecruitmentInfo().then((response) => {
      let h = HEIGHTS.findIndex(n => n === response.data.user.height);
      let w = WEIGHTS.findIndex(n => n === response.data.user.weight);
      
      setCurrentHeight(HEIGHTS[h]);
      setHeightPosition(h);
      setCurrentWeight(WEIGHTS[w]);
      setWeightPosition(w);
      setPosition(response.data.user.playing_position);
      setLevel(response.data.user.playing_level);
      setOnTeam(response.data.user.currently_on_team);
      setLookingForTeam(response.data.user.looking_for_team);
      setCitizenship(response.data.user.citizenship);
      setPassportReady(response.data.user.passport_ready);
      setNotes(response.data.user.notes || '');
      setSalaryFrom(response.data.user.salary_range_lower_limit);
      setSalaryTo(response.data.user.salary_range_upper_limit);
      setSalaryNegotiable(response.data.user.salary_is_negotiable);
      
    }).catch(e => {
      console.log(e);
    });

    getLocationInfo().then(countries => {
      setLocations(countries);
    });

    getPlayerTeams();
    getAllTeams();
  }, [history]);

  useEffect(() => {
    setCurrentHeight(HEIGHTS[heightPosition]);
    setCurrentWeight(WEIGHTS[weightPosition]);

  }, [heightPosition, weightPosition]);

  return (
    <>
      <div className={classes.content}>
        <Menu />
        <div className={classes.profileContainerWrapper}>
          <div className={classes.profileContainer}>
          {(successMessage)?<div className={classes.successMessage}>{successMessage}</div>:null}
          {(errorMessage)?<div className={classes.errorMessage}>{errorMessage}</div>:null}
            <form className={classes.form} noValidate autoComplete="off">
              <Typography className={classes.subTitle}>RECRUITMENT INFORMATION</Typography>
              <FormControl className={classes.label}>
                <span>What's your height?</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <FormControl>
                  <div className={classes.buttonsInputWrapper}>
                    <input type="text" disabled value={currentHeight} className={classes.buttonsInput} />
                    <div id="heightUp" onClick={heightUp}></div>
                    <div id="heightDown" onClick={heightDown}></div>
                  </div>
                </FormControl>
                <FormControl>
                  <CustomSlider
                    onChange={handleHeightSliderChange}
                    value={heightPosition}
                    min={0}
                    step={1}
                    max={HEIGHTS.length -1}
                  aria-label="custom thumb label"/>
                </FormControl>
              </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <FormControl className={classes.label}>
                <span>What's your weight?</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <FormControl>
                  <div className={classes.buttonsInputWrapper}>
                    <input type="text" disabled value={currentWeight} className={classes.buttonsInput} />
                    <div id="weightUp" onClick={weightUp}></div>
                    <div id="weightDown" onClick={weightDown}></div>
                  </div>
                </FormControl>
                <FormControl>
                  <CustomSlider
                    onChange={handleWeightSliderChange}
                    value={weightPosition}
                    min={0}
                    step={1}
                    max={WEIGHTS.length -1}
                  aria-label="custom thumb label"/>
                </FormControl>
              </div>
            </form>
            <div className={classes.dashedLine}></div>
            <form className={classes.form} noValidate autoComplete="off">
              <Typography className={classes.subTitle}>EXPERIENCE</Typography>
              <FormControl className={classes.label}>
                <span>What position do you play?</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <select className={classes.input + " " + classes.select} onChange={e => setPosition(e.target.value)} value={position}>
                  <option value=""></option>;
                  {
                    POSITIONS.map(position => {
                      return <option value={position}>{position}</option>;
                    })
                  }
                </select>
              </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <FormControl className={classes.label}>
                <span>What is your playing level?</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <select className={classes.input + " " + classes.select} onChange={(e) => setLevel(e.target.value)} value={level}>
                  <option value=""></option>
                  {
                    LEVELS.map(level => {
                      return <option value={level}>{level}</option>;
                    })
                  }
                </select>
              </div>
            </form>
            <form className={classes.form} noValidate autoComplete="off">
              <FormControl className={classes.label}>
                <span>Past playing experiences</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
                <div className={classes.addButton} onClick={openPlayingExperiences}>
                  <span>+</span>
                  ADD
                </div>
              </div>
            </form>
          </div>
            {
              (teams.length > 0) ?
              <div className={classes.teamsSection}>
                {
                  teams.map(team => {
                  return <div><span>{team.team}</span><span>{team.from_year + '-' + team.to_year}</span><span>{team.position}</span><span className={classes.siteLink} onClick={() => removeTeam(team.id)}>Remove</span></div>;
                  })
                }
              </div>
              :null
            }

            {
              (playingExperiencesSection) ?
                <div className={classes.playingExperiencesSection}>
                  <form className={classes.form} noValidate autoComplete="off">
                    <FormControl>
                      <label>Team</label>
                      <Autocomplete
                        freeSolo
                        className={(!selectedTeamError) ? classes.autoCompleteInput : classes.autoCompleteInput + " " + classes.inputError}
                        onChange={(event, newValue) => {
                          if(newValue){
                            setSelectedTeam(newValue.id);
                          }
                        }}
                        options={teamList}
                        getOptionLabel={(option) => option.name}
                        value={selectedTeam}
                        renderInput={(params) => <TextField  {...params} />}
                      />
                      <label>Years</label>
                      <select type="text" className={(!yearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setFromYear(e.target.value)}>
                        <option value="">From</option>
                        {
                          YEARS.map(year => {
                            return <option value={year}>{year}</option>;
                          })
                        }
                      </select>
                      <select type="text" className={(!yearError)?classes.input + " " + classes.select:classes.input + " " + classes.select + " " + classes.inputError} style={{width:"150px"}} onChange={e => setToYear(e.target.value)}>
                        <option value="">To</option>
                        {
                          YEARS.map(year => {
                            return <option value={year}>{year}</option>;
                          })
                        }
                      </select>
                      <label>Position</label>
                      <select className={(!playingPositionError)?classes.input:classes.input + " " + classes.inputError} onChange={e => setPlayingPosition(e.target.value)}>
                        <option value=""></option>;
                        {
                          POSITIONS.map(position => {
                            return <option value={position}>{position}</option>;
                          })
                        }
                      </select>
                      <label>Current team?</label>
                      <FormControlLabel
                      control={<CustomSwitch checked={currentTeam} value={currentTeam} onChange={toggleCurrentTeam} />}
                      label={(currentTeam ? "Yes" : "No")}
                      />
                    </FormControl>
                  </form>
                  <div className={classes.buttons}>
                  <span onClick={closePlayingExperiences} className={classes.cancelButton}>Cancel</span>
                    <span className={classes.button} onClick={saveTeam}>Add Team</span>
                  </div>
                </div>
                :null
            }
            <div className={classes.profileContainer}>
            <div className={classes.dashedLine}></div>
              <form className={classes.form} noValidate autoComplete="off">
                <Typography className={classes.subTitle}>STATUS</Typography>
                <FormControl className={classes.label}>
                  <span>Are you currently on a team?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={onTeam} value={onTeam} onChange={toggleOnTeam} />}
                  label={(onTeam ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Are you looking for a team?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={lookingForTeam} value={lookingForTeam} onChange={toggleLookingForTeam} />}
                  label={(lookingForTeam ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>You are a citizen of which country?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <select className={classes.input + " " + classes.select} onChange={e => setCitizenship(e.target.value)} value={citizenship}>
                  <option value=""></option>
                  {
                    locations.map(location => {
                      return <option id={location.id} value={location.name}>{location.name}</option>
                    })
                  }
                  </select>
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Are you passport ready?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={passportReady} value={passportReady} onChange={togglePassportReady} />}
                  label={(passportReady ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>Is salary negotiable?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <FormControlLabel
                  control={<CustomSwitch checked={salaryNegotiable} value={salaryNegotiable} onChange={toggleSalaryNegotiable} />}
                  label={(salaryNegotiable ? "Yes" : "No")}
                />
                </div>
              </form>
              <form className={classes.form} noValidate autoComplete="off">
                <FormControl className={classes.label}>
                  <span>What is your expected salary?</span>
                </FormControl>
                <div className={classes.inputsWrapper}>
                <input type="number"
                  className={classes.input}
                  placeholder="From (USD)"
                  value={salaryFrom}
                  maxLength="9"
                  onChange={e => {
                    let { value, maxLength } = e.target;
                    let prev = value.slice(0,-1);
                    value = (value.length > Number(maxLength)) ? prev : value;
                    setSalaryFrom(value)
                  }}
                />
                </div>
                <div className={classes.inputsWrapper}>
                <input type="number"
                  className={classes.input}
                  placeholder="To (USD)"
                  value={salaryTo}
                  maxLength="9"
                  onChange={e => {
                    let { value, maxLength } = e.target;
                    let prev = value.slice(0,-1);
                    value = (value.length > Number(maxLength)) ? prev : value;
                    setSalaryTo(value)
                  }}
                />
                </div>
              </form>
            <div className={classes.dashedLine}></div>
            <form className={classes.form} noValidate autoComplete="off">
              <Typography className={classes.subTitle}>OTHER</Typography>
              <FormControl className={classes.label}>
                <span>Is there anything else?</span>
              </FormControl>
              <div className={classes.inputsWrapper}>
              {(notesError != '')?<div className={classes.notesError}>{notesError}</div>:null}
                <textarea rows="4" placeholder="Is there anything else?..." className={classes.input} onChange={e => setNotes(e.target.value)} value={notes}>
                </textarea>
              </div>
            </form>
            <div className={classes.dashedLine}></div>
            <div className={classes.buttons}>
              <a href="/" className={classes.cancelButton}>Cancel</a>
              <div className={classes.button} onClick={updateRecruitment}>
                  <span>SAVE</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruitmentInformation;