export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const checkExtension = (filename) => {
  if ( /\.(jpe?g|png)$/i.test(filename) === false ) return false;
  return true;
};

export const hasUpperCase = (word) => {
  let upperCase = false;
  for(let c = 0; c < word.length; c++){
    if((word.charAt(c) === word.charAt(c).toUpperCase()) && isNaN(word.charAt(c) * 1)){
      upperCase = true;
    }
  }
  return upperCase;
};

export const isValidHttpUrl = (string) => {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  if((url.host.match(/youtube\.com/) || url.host.match(/vimeo\.com/) || url.host.match(/youtu\.be/) || url.host.match(/hudl\.com/))
  && (url.protocol === "http:" || url.protocol === "https:")) {
    return true;
  } else {
    return false;
  }
};

export const calculateAge = (birthday) => {
  if(!birthday)return null;
  var ageDifMs = Date.now() - Date.parse(birthday);
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};